import React from "react";
import styles from "./MembershipCard.module.scss";
import { Typography } from "@mui/material";
import { useUser } from "hooks/useUser";
import {
  getMembershipStatus,
  getStatusColor,
  getUserType,
} from "utils/functions";
import dayjs from "dayjs";
import { UtilityButton } from "lib/components/Buttons/buttons";
import { useHistory } from "react-router-dom";

const MembershipCard = () => {
  const { user } = useUser();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.custom}></div>

      <div className={styles.memberName}>
        <Typography variant="h5">
          {user.firstName} {user.lastName}
        </Typography>
      </div>

      <div
        className={styles.memberStatus}
        style={{ color: getStatusColor(user.type) }}
      >
        {getUserType(user.type)}
      </div>

      {getMembershipStatus(user.membership, user.subscription) && (
        <>
          <div className={styles.membershipPeriod}>
            <Typography variant="profileData">
              {dayjs(user.subscription.createAt).format("DD-MM-YYYY")}
            </Typography>
          </div>
          <div className={styles.membershipPeriod}>
            <Typography variant="profileData">
              {dayjs(user.subscription.expires).format("DD-MM-YYYY")}
            </Typography>
          </div>
          <div className={styles.daysLeft}>
            <Typography variant="profileData">
              {user.membership.daysLeft} zile rămase
            </Typography>
          </div>
        </>
      )}

      {!getMembershipStatus(user.membership, user.subscription) && (
        <div className={styles.addPeriod}>
          <UtilityButton
            variant="contained"
            type="button"
            height="41px"
            onClick={() => history.push("/becomeMember")}
          >
            Cumpăra abonament
          </UtilityButton>
        </div>
      )}
    </div>
  );
};

export default MembershipCard;
