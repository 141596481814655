import React from "react";
import styles from "./TimelineList.module.scss";

const TimelineList = ({ id, title, children }) => {
  return (
    <div>
      <div className={styles.mainContainer}>
        <div>{title}</div>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </div>
  );
};

export default TimelineList;
