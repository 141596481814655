import React, { useCallback } from "react";
import styles from "./SeeParticipantsModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CustomDialog } from "lib";
import {
  InputLabel,
  MenuItem,
  ListSubheader,
  FormControl,
  Select,
} from "@mui/material";
import { ParticipantsAPI, UsersAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import { NavigationButton } from "lib/components/Buttons/buttons";
import { utils, writeFile } from "xlsx";
import dayjs from "dayjs";

const IndividualParticipant = ({
  name,
  particpantId,
  getEventParticipants = () => {},
  attended,
}) => {
  const deleteParticipant = () => {
    ParticipantsAPI.delete(particpantId).then((res) => {
      if (res.ok === true) {
        toast.success("Participant șters cu succes!");
        getEventParticipants();
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };
  return (
    <div
      className={`${styles.individualParticipant}  ${
        attended ? styles.userAttended : styles.userNotAttended
      }`}
    >
      <div>
        <h5>{name}</h5>
      </div>
      <div className={styles.deleteParticipant} onClick={deleteParticipant}>
        <CloseIcon />
      </div>
    </div>
  );
};

const SeeParticipantsModal = ({ open, setOpen, title, id, eventDate }) => {
  const [participants, setParticipants] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const getEventParticipants = () => {
    ParticipantsAPI.get(id).then((res) => setParticipants(res.data.content));
  };

  const getUsers = () => {
    UsersAPI.get().then((res) => setUsers(res.data.content));
  };

  const addParticipant = (userId) => {
    const objForAdding = {};
    objForAdding.eventId = id;
    objForAdding.userId = userId;
    objForAdding.status = "da";
    ParticipantsAPI.create(objForAdding).then((res) => {
      if (res.ok === true) {
        toast.success("Participantul a fost adăugat cu succes");
        getEventParticipants();
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };
  React.useEffect(() => {
    if (open === true) {
      getEventParticipants();
      getUsers();
    }
  }, [id, open]);

  // Create an object with keys as county IDs and values as arrays of members in each county
  const membersByCounty = {};
  users.forEach((member) => {
    const countyId = member.countyId;
    const countyName = member.county.name;

    if (!membersByCounty[countyId]) {
      membersByCounty[countyId] = {
        name: countyName,
        members: [],
      };
    }

    membersByCounty[countyId].members.push(member);
  });

  const countyItems = Object.keys(membersByCounty).map((countyId) => {
    const countyName = membersByCounty[countyId].name;

    return [
      <ListSubheader key={countyId}>{countyName}</ListSubheader>,
      ...membersByCounty[countyId].members.map((member) => (
        // Loop through each member in that county and create a MenuItem component for each member with its value as the member ID
        <MenuItem
          key={member.id}
          value={member.id}
          onClick={() => addParticipant(member.id)}
        >
          {member.firstName + " " + member.lastName}
        </MenuItem>
      )),
    ];
  });

  /* get state data and export to XLSX */
  const exportFile = useCallback(() => {
    const mappedParticipants = participants.map((participant) => ({
      Participant: participant.user.lastName + " " + participant.user.firstName,
      Participă: participant.isAttending === true ? "DA" : "NU",
      Prezent: participant.attended === true ? "DA" : "NU",
    }));
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(mappedParticipants);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFile(
      wb,
      `Tabel_Prezenta_${dayjs(eventDate).format("DD/MM/YYYY")}.xlsx`
    );
  }, [participants]);

  return (
    <CustomDialog open={open} setOpen={setOpen} title={title} width="600px">
      <div className={styles.mainContainer}>
        <div style={{ marginBottom: "16px" }}>
          <FormControl
            sx={{
              width: "100%",
              [`@media (min-width: 600px)`]: {
                width: "550px",
              },
            }}
          >
            <InputLabel htmlFor="grouped-select">Adauga membru</InputLabel>
            <Select defaultValue="" id="grouped-select" label="Adauga membru">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {countyItems}
            </Select>
          </FormControl>
        </div>
        <div className={styles.attendeesPresenceStyle}>
          <NavigationButton autoFocus color="blue" onClick={exportFile}>
            Descarcă fișă prezență
          </NavigationButton>
        </div>
        <div className={styles.scrollable}>
          {participants.length === 0 ? (
            <div className={styles.noMembers}>
              Nu există participanți înscriși!
            </div>
          ) : (
            participants.map((part) => (
              <IndividualParticipant
                key={part.id}
                name={part.user.firstName + " " + part.user.lastName}
                particpantId={part.id}
                getEventParticipants={getEventParticipants}
                attended={part.attended}
              />
            ))
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default SeeParticipantsModal;
