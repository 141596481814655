import React, { useState } from "react";
import {
  RemoveButton,
  EditButton,
  UtilityButton,
} from "lib/components/Buttons/buttons.jsx";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
// import UploadDocument from 'pages/Clients/modals/UploadDocument';
import DocumentRowDelete from "lib/components/DocumentRowNew/DocumentRow";

const TestPageOC = () => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0); //state for active tab
  const [add, setAdd] = useState(false); //test state for add button
  const [loading, setLoading] = useState(false);

  const dropDownItems = [
    {
      id: 1,
      label: "FEBRUARIE",
    },
    {
      id: 2,
      label: "Test 2",
    },
    {
      id: 3,
      label: "Test 3",
    },
  ];

  const [dropDownValue, setDropDownValue] = useState(dropDownItems[0].label); // initial state of the dropdown

  //data for tables
  const data1 = [
    {
      id: "0",
      counselor: "Onofrei Constantin",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "1",
      counselor: "Razvan Constantin",
      lpf: "2",
      lpj: "2",
      le: "2",
      ce: "0",
      ct: "1",
      ch: "4",
    },
    {
      id: "2",
      counselor: "Codrinel e baiat fain",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "3",
      counselor: "Florinel seful banilor H",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
  ];

  const data2 = [
    {
      id: "0",
      counselor: "Onofrei Constantin",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "1",
      counselor: "Razvan Constantin",
      lpf: "2",
      lpj: "2",
      le: "2",
      ce: "0",
      ct: "1",
      ch: "4",
    },
    {
      id: "2",
      counselor: "Codrinel e baiat fain",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "3",
      counselor: "Florinel nu e baiat fain",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
  ];

  const data3 = [
    {
      id: "0",
      counselor: "Onofrei Constantin e cel mai bun programator",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "1",
      counselor: "Razvan Constantin",
      lpf: "2",
      lpj: "2",
      le: "2",
      ce: "0",
      ct: "1",
      ch: "4",
    },
    {
      id: "2",
      counselor: "Codrinel e cel mai tare team manager",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
    {
      id: "3",
      counselor: "Florinel nu e baiat fain",
      lpf: "3",
      lpj: "2",
      le: "2",
      ce: "2",
      ct: "1",
      ch: "4",
    },
  ];

  //labels data for pages
  const labels1 = [
    {
      id: "id",
      label: "ID",
      minWidth: 10,
    },
    { id: "counselor", label: "Consiler", minWidth: 10 },
    { id: "lpf", label: "Livrari PF", minWidth: 10 },
    { id: "lpj", label: "Livrari PJ", minWidth: 10 },
    { id: "le", label: "Livrari Electrice", minWidth: 10 },
    { id: "ce", label: "Comenzi Electrice", minWidth: 10 },
    { id: "ct", label: "Comenzi Termice", minWidth: 10 },
    { id: "ch", label: "Comenzi Hibrid", minWidth: 10 },
    { id: "functions", label: "", minWidth: 10 },
  ];

  const labels2 = [
    {
      id: "id",
      label: "ID",
      minWidth: 10,
    },
    { id: "counselor", label: "Consiler", minWidth: 10 },
    { id: "lpf", label: "Livrari PF", minWidth: 10 },
    { id: "lpj", label: "Livrari in tab 2", minWidth: 10 },
    { id: "le", label: "Livrari Electrice", minWidth: 10 },
    { id: "ce", label: "Comenzi Electrice", minWidth: 10 },
    { id: "ct", label: "Comenzi Termice", minWidth: 10 },
    { id: "ch", label: "Comenzi Hibrid", minWidth: 10 },
    { id: "functions", label: "", minWidth: 10 },
  ];

  const labels3 = [
    {
      id: "id",
      label: "ID",
      minWidth: 10,
    },
    { id: "counselor", label: "Consiler", minWidth: 10 },
    { id: "lpf", label: "Livrari PF", minWidth: 10 },
    { id: "lpj", label: "Livrari in tab 3", minWidth: 10 },
    { id: "le", label: "Livrari Electrice", minWidth: 10 },
    { id: "ce", label: "Comenzi Electrice", minWidth: 10 },
    { id: "ct", label: "Comenzi Termice", minWidth: 10 },
    { id: "ch", label: "Comenzi Hibrid", minWidth: 10 },
    { id: "functions", label: "", minWidth: 10 },
  ];

  //tabs data for the container
  const tabs = [
    {
      id: 0,
      label: "Dacia",
      onClick: () => {
        setActiveTab(0);
      },
    },
    {
      id: 1,
      label: "Renault",
      onClick: () => {
        setActiveTab(1);
      },
    },
    {
      id: 2,
      label: "Nissan",
      onClick: () => {
        setActiveTab(2);
      },
    },
  ];

  //cell modifier for tables. Create one for each table if you like
  const cellModifier = (row, column, value) => {
    if (column.id === "functions") {
      return (
        <div style={{ display: "flex" }}>
          <EditButton></EditButton>
          <RemoveButton></RemoveButton>
        </div>
      );
    }

    if (column.id === "lpf") {
      return (
        <div>
          <p>
            {value} * <span style={{ color: "blue" }}>60%</span>
          </p>
        </div>
      );
    }
    return value;
  };

  //simulate an async - await behavior in modal component
  const testModal = () => {
    setLoading(true);
    setTimeout(() => {
      setOpen(false);
      setLoading(false);
    }, 5000);
  };

  //data for task
  const dataTasks = [
    {
      id: 1,
      type: 1,
      status: 0,
      date: new Date(Date.now()).setDate(20),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 2,
      type: 2,
      status: 0,
      date: new Date(Date.now()).setDate(15),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 3,
      type: 2,
      status: 0,
      date: new Date(Date.now()).setDate(17),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 4,
      type: 1,
      status: 0,
      date: new Date(Date.now()).setDate(21),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 5,
      type: 4,
      status: 0,
      date: new Date(Date.now()).setDate(21),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 6,
      type: 3,
      status: 1,
      date: new Date(Date.now()).setDate(25),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 7,
      type: 3,
      status: 0,
      date: new Date(Date.now()).setDate(10),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 8,
      type: 5,
      status: 1,
      date: new Date(Date.now()).setDate(1),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
    {
      id: 9,
      type: 4,
      status: 0,
      date: new Date(Date.now()).setDate(3),
      firstName: "Constantin",
      lastName: "Onofrei",
    },
  ];

  /*---------------------DOCUMENTS-------------------------------*/
  const [documents, setDocuments] = useState([
    { documentName: "test", file: { name: "testFileName" } },
    { documentName: "test2", file: { name: "testFileName2" } },
    { documentName: "test3", file: { name: "testFileName3" } },
  ]);
  const [openAddDocument, setOpenAddDocument] = useState(false);

  const onSubmitFunction = ({ values, file }) => {
    alert("Document added!");
  };

  const onDeleteFunction = ({ doc }) => {
    alert("Document deleted!");
  };

  return (
    <>
      <div style={{ padding: "10px" }}>
        <h1>OC TESTING PAGE</h1>

        {/*---------------------BUTTONS-------------------------------*/}
        {/* <PrimaryButton startIcon={<AddIcon />} color="primary" onClick={() => console.log('here')}>
                Adauga utilizator
            </PrimaryButton>
            <PrimaryButton startIcon={<AddIcon />} color="secondary">
                Adauga utilizator
            </PrimaryButton>
            <PrimaryButton startIcon={<AddIcon />} color="dark">
                Adauga utilizator
            </PrimaryButton>
            <PrimaryButton startIcon={<AddIcon />} color="light">
                Adauga utilizator
            </PrimaryButton>
            <RemoveButton onClick={() => console.log('here')} />
            <DuplicateButton onClick={() => console.log('here')} />
            <EditButton onClick={() => console.log('here')} />
            <UtilityButton>Create account</UtilityButton>
            <UtilityButton startIcon={<AddIcon />}>Create account</UtilityButton>
            <UtilityButton endIcon={<AddIcon />}>Create account</UtilityButton>
            <UtilityButton endIcon={<AddIcon />} startIcon={<AddIcon />}>
                Create account v2
            </UtilityButton>
            <UtilityButton endIcon={<AddIcon />} startIcon={<AddIcon />} variant="contained">
                Create account v2
            </UtilityButton>
            <UtilityButton endIcon={<AddIcon />} startIcon={<AddIcon />} variant="outlined" color="red">
                Create account v2
            </UtilityButton>
            <UtilityButton
                endIcon={<AddIcon />}
                startIcon={<AddIcon />}
                color="green"
                onClick={() => console.log('here')}
                height="100px"
                disabled={true}
            >
                Create account v2
            </UtilityButton>
            <ConfirmButton onClick={() => console.log('here')} />
            <DatePickerButton onClick={() => console.log('here')} />
            <NavigationButton onClick={() => console.log('here')}>test</NavigationButton>
            <NavigationButton variant="contained">test</NavigationButton>
            <NavigationButton startIcon={<NavigateBeforeIcon />}>test</NavigationButton>
            <NavigationButton endIcon={<NavigateNextIcon />}>test</NavigationButton>
            <NavigationButton startIcon={<DeleteIcon></DeleteIcon>} color="red">
                sterge sarcina la la la
            </NavigationButton>
            <NavigationButton startIcon={<DeleteIcon></DeleteIcon>} color="green">
                sterge sarcina la la la
            </NavigationButton>
            <NavigationButton color="blue">test</NavigationButton>
            <NavigationButton color="grey">test</NavigationButton> */}

        {/*---------------------TABLES-------------------------------*/}
        {/*---------------------SIMPLE TABLE-------------------------------*/}
        {/* <CustomTable
                title="Test table hello" // if no title is given, CustomTable will not render the Container component
                labels={labels1}
                loading={false}
                tableData={data1}
                cellModifier={cellModifier}
            />

            <div style={{ marginBottom: '100px' }}></div> */}

        {/*---------------------TABS TABLE-------------------------------*/}

        {/*---------------------CUSTOM MODAL-------------------------------*/}

        {/* <PrimaryButton onClick={() => setOpen(true)} color="light">
                open
            </PrimaryButton>

            <CustomDialog
                open={open}
                title="Test modal title"
                buttonClose="Inapoi"
                buttonFinish="Finalizeaza"
                buttonUtility="Sterge stuff"
                buttonFinishDisabled={false}
                buttonCloseDisabled={false}
                buttonUtilityDisabled={false}
                buttonFinishLoading={loading}
                buttonCloseLoading={false}
                buttonUtilityLoading={false}
                onClickButtonFinish={testModal}
                handleClose={() => setOpen(false)}
                width="50%"
            >
                <Divider light></Divider>
                <h2>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat at et quaerat! Architecto, alias!
                    Numquam esse corporis sint sed, eum eius repellendus nam cum repudiandae explicabo ratione facere
                    iste earum!
                </h2>
                <Divider light></Divider>
            </CustomDialog> */}

        {/*---------------------GENERAL ADMINISTRATION BUTTON-------------------------------*/}
        {/* <GeneralAdministrationButton
                onClick={() => console.log('general clicked')}
                text="Utilizatori"
                icon={<GroupIcon />}
            /> */}

        {/*---------------------COMMENTS COMPONENT-------------------------------*/}
        {/* <Comments /> */}

        {/*---------------------COMMENTS COMPONENT-------------------------------*/}
        {/* <LogoutDialog open={true}></LogoutDialog>
            <LogoutButton variant="contained" color="green">
                tata
            </LogoutButton>
            <LogoutButton variant="contained" color="red">
                tata
            </LogoutButton> */}

        {/*---------------------TASK COMPONENT-------------------------------*/}

        {/* {dataTasks.map((item) => (
                <TaskComponent item={item} key={item.id} />
            ))} */}

        <h2>Document component</h2>
        <div
          style={{
            border: "2px solid black",
            borderRadius: "10px",
            padding: "2rem",
          }}
        >
          <div>
            <Typography variant="h6">Documents</Typography>
          </div>
          <div>
            {documents?.map((doc, index) => {
              return (
                <DocumentRowDelete
                  key={index}
                  doc={doc}
                  onDeleteFunc={onDeleteFunction}
                />
              );
            })}
          </div>
          <div>
            <UtilityButton
              startIcon={<AddIcon />}
              onClick={() => setOpenAddDocument(true)}
            >
              ADD DOCUMENT
            </UtilityButton>
          </div>
        </div>

        {/* {dataTasks.map((item) => (
                <TaskComponent item={item} key={item.id} />
            ))} */}
      </div>
      {/* <UploadDocument
        open={openAddDocument}
        setOpen={setOpenAddDocument}
        onSubmitFunc={onSubmitFunction}
      /> */}
    </>
  );
};

export default TestPageOC;
