import React from "react";
import styles from "./UserCard.module.scss";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { CompaniesAPI, UsersAPI } from "api_inbusiness";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import DefaultUserPicture from "resources/img/user/DefaultUserPicture.png";
import DefaultCompanyPicture from "resources/img/InBusiness/no_business_logo.jpeg";
import ImageRenderer from "lib/components/ImageRenderer/ImageRenderer";

const UserCard = ({ user }) => {
  const [userPhoto, setUserPhoto] = React.useState(null);
  const [companyImage, setCompanyImage] = React.useState(null);
  const navigate = useHistory();
  const userInfos = user.county?.name;

  const userCompany =
    user.favoriteCompany?.name !== undefined ? user.favoriteCompany?.name : "";

  React.useEffect(() => {
    (async () => {
      const imgId = user.avatarId;
      const logoId = user.favoriteCompany?.logoId;

      if (imgId) {
        const { data: imgUrl } = await UsersAPI.getNewUserPicture(imgId);
        setUserPhoto(imgUrl);
      }
      if (logoId) {
        const { data: imgUrl } = await CompaniesAPI.getNewLogoCompanies(logoId);
        setCompanyImage(imgUrl);
      }
    })();
  }, [user]);

  return (
    <Card className={styles.mainCard}>
      <div className={styles.profile_wrapper}>
        <div className={styles.name_wrapper}>
          <p>{user.firstName}</p>
          <p>{user.lastName}</p>
        </div>
        {/* <ImageRenderer
          url={userPhoto ? userPhoto : DefaultUserPicture}
          thumb={userPhoto ? userPhoto : DefaultUserPicture}
          className={userPhoto ? styles.userAvatar : styles.userAvatar_default}
        /> */}

        <img
          src={userPhoto ? userPhoto : DefaultUserPicture}
          alt="userImg"
          className={userPhoto ? styles.userAvatar : styles.userAvatar_default}
          loading="lazy"
        />
      </div>

      <div className={styles.company_wrapper}>
        <p>{userInfos}</p>
        <p>{userCompany}</p>

        <div className={styles.company_logo_wrapper}>
          {companyImage && (
            <img
              src={companyImage}
              alt="company logo"
              className={styles.company_logo}
              loading="lazy"
            />
          )}
        </div>
      </div>

      <div className={styles.footer_wrapper}>
        <div className={styles.social_icons}>
          {user.facebookURL && (
            <a
              href={`https://${user.facebookURL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <div>
                <FacebookIcon />
              </div>
            </a>
          )}

          {user.instagramURL && (
            <a
              href={`https://${user.instagramURL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <InstagramIcon />
              </div>{" "}
            </a>
          )}

          {user.linkedinURL && (
            <a
              href={`https://${user.linkedinURL}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <div>
                <LinkedInIcon />
              </div>
            </a>
          )}
        </div>

        <div className={styles.arrow_to_profile}>
          <ArrowForwardIosIcon
            onClick={() => navigate.push(`/users/${user.id}`)}
          />
        </div>
      </div>
    </Card>
  );
};

export default UserCard;
