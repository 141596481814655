import React from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "./TimelineItem.module.scss";
import Tooltip from "lib/components/Tooltip/TooltipWrapper";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

import { Typography, Chip } from "@mui/material";
import SeeParticipantsModal from "./SeeParticipantsModal";
import AddEvent from "pages/Events/AddEvent";
import EventEmail from "pages/Events/EventEmail";
import ConfirmModal from "lib/components/Modals/ConfirmModal";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { EventsAPI, ParticipantsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useUser } from "hooks/useUser";
import { UtilityButton } from "lib/components/Buttons/buttons";
import LoginIcon from "@mui/icons-material/Login";

const AcceptParticipating = ({ eventId, user, getEvent = () => {} }) => {
  const [status, setStatus] = React.useState(false);

  const getUserStatus = () => {
    ParticipantsAPI.getStatus(user.id, eventId).then((res) => {
      if (res.ok === false) {
        // setStatus(res.data);
      } else {
        setStatus(res.data);
      }
    });
  };

  React.useEffect(() => {
    if (Object.keys(user).length === 0) return;
    getUserStatus();
  }, []);

  const handleSend = () => {
    const objForPartipant = {};
    objForPartipant.userId = user.id;
    objForPartipant.eventId = eventId.id;
    objForPartipant.isAttending = true;
    ParticipantsAPI.create(objForPartipant).then((res) => {
      if (res.ok === true) {
        getUserStatus();
        toast.success("Prezență confirmată cu succes!");
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  return (
    <div>
      {status === false && user.id && (
        <UtilityButton
          color="green"
          onClick={handleSend}
          startIcon={<CheckBoxOutlineBlankIcon />}
        >
          Vreau sa particip
        </UtilityButton>
      )}
      {!user.id && (
        <UtilityButton color="blue" startIcon={<LoginIcon />}>
          <a
            href="https://app.inbusiness.club/"
            target={"_blank"}
            rel="noreferrer noopener"
            style={{ textDecoration: "none", color: "black" }}
          >
            Login
          </a>
        </UtilityButton>
      )}
      {status === true && (
        <div className={styles.test}>
          <div className={styles.participantStatus}>
            <CheckBoxIcon />
            <h5>Particip</h5>
          </div>
        </div>
      )}
    </div>
  );
};

const TimelineItem = ({
  id,
  color,
  icon,
  title,
  dateTime,
  time,
  location,
  description,
  badge,
  lastItem,
  countyId,
  participants,
  draft,
  custom,
  tickets,
  handleEdit = () => {},
  selectedEvent,
  setSelectedEvent = () => {},
  getAllEvents = () => {},
  getEvent = () => {},
}) => {
  const eventId = useParams();

  const { user, permissionsData } = useUser();
  const lineOffset = -8;
  const [open, setOpen] = React.useState(false);
  const [openEditForm, setOpenEditForm] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const navigate = useHistory();
  const customEventStyle = "rgb(255 182 1 / 35%) 0px 5px 15px";

  const formatTime = (date) => {
    const newDate = new Date(date);
    const options = { hour12: false, timeZone: "Europe/Bucharest" };
    const localTimeString = newDate.toLocaleTimeString("en-RO", options);

    return localTimeString;
  };

  return (
    <div
      className={styles.mainContainer}
      style={{ boxShadow: custom && customEventStyle }}
    >
      {draft === true && (
        <div
          style={{
            position: "absolute",
            top: "-0.5rem",
            left: "-0.5rem",
            borderRadius: "20px",
            padding: "10px",
            backgroundColor: "red",
            color: "#ffffff",
          }}
        >
          Draft
        </div>
      )}

      <div className={styles.contentContainer}>
        <div className={styles.dateTimeContainer}>
          <Typography variant="tableContent">
            {dayjs(dateTime).format("DD/MM/YYYY")} {formatTime(time)}
          </Typography>

          {/* if we have eventId then it means we are on an individual id so we dont show all the controls */}
          {/* user should only be allowed to accept the participation of the event */}
          {Object.keys(eventId).length > 0 ? (
            <AcceptParticipating
              eventId={eventId}
              user={user}
              getEvent={getEvent}
            />
          ) : (
            <div className={styles.buttonsContainer}>
              {permissionsData.seeAdminButtons && (
                <Tooltip position="right" textTip={"Prezență"} followCursor>
                  <div
                    className={styles.participantsIcon}
                    onClick={() => navigate.push(`/scan-qr/event/${id}`)}
                  >
                    <EmojiPeopleIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}
              {permissionsData.participateEvent && custom === false && (
                <Tooltip position="right" textTip={"Deschide"} followCursor>
                  <div
                    className={styles.openEvent}
                    onClick={() => window.open(`/events/${id}`, "_blank")}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}

              {permissionsData.seeAdminButtons && custom === false && (
                <Tooltip
                  position="right"
                  textTip={"Trimite email"}
                  followCursor
                >
                  <div
                    className={styles.emailAction}
                    onClick={() => {
                      handleEdit();
                      setOpenEmail(true);
                    }}
                  >
                    <EmailIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}

              {permissionsData.seeAdminButtons && (
                <Tooltip position="right" textTip={"Modifica"} followCursor>
                  <div
                    className={styles.editAction}
                    onClick={() => {
                      handleEdit();
                      setIsEditMode(true);
                      setOpenEditForm(true);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}

              {permissionsData.seeAdminButtons && custom === false && (
                <Tooltip position="right" textTip={"Participanti"} followCursor>
                  <div
                    className={styles.seeParticipantsAction}
                    onClick={() => setOpen(true)}
                  >
                    <BusinessCenterIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}

              {permissionsData.seeAdminButtons && (
                <Tooltip position="right" textTip={"Sterge"} followCursor>
                  <div
                    className={styles.deleteEvent}
                    onClick={() => setOpenConfirm(true)}
                  >
                    <DeleteIcon fontSize="small" />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div className={styles.locationContainer}>
          <Typography variant="tableContent">
            {countyId} - {location}
          </Typography>
        </div>

        <h2>{title}</h2>

        {/* eventId is an object and we check to see if there is data inside. if we have data, then we are on an individual event */}
        {!Object.keys(eventId).length > 0 && (
          <p className={styles.numberOfParticipantsStyle}>
            {/* if an event is custom, then we take the participants number from the tickets */}
            Participanti:{" "}
            {custom
              ? tickets?.filter((ticket) => ticket.valid === true).length
              : participants?.length}
          </p>
        )}

        <div
          className={styles.descriptionContainer}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
      {!lastItem && (
        <div className={styles.lineContainer} style={{ top: lineOffset }}></div>
      )}
      <AddEvent
        open={openEditForm}
        setOpen={setOpenEditForm}
        isEditMode={isEditMode}
        selectedEvent={selectedEvent}
        getAllEvents={getAllEvents}
      />
      <SeeParticipantsModal
        open={open}
        setOpen={setOpen}
        title={title}
        id={id}
        eventDate={dateTime}
      />

      <EventEmail
        open={openEmail}
        setOpen={setOpenEmail}
        title={title}
        selectedEvent={selectedEvent}
      />
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        onClickButtonYes={() =>
          EventsAPI.delete(id).then((res) => {
            if (res.ok === true) {
              getAllEvents();
              setOpenConfirm(false);
              toast.success("Evenimentul a fost șters cu succes!");
            } else {
              toast.error(
                "A intervenit o eroare. Evenimentul nu a putut fi șters"
              );
            }
          })
        }
        text={"Sunteți sigur că doriți să ștergeți acest eveniment?"}
      />
    </div>
  );
};

export default TimelineItem;
