import { toast } from "react-toastify";
import React from "react";

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const handleError = (error) => {
  console.error(error);
  const errorKey = Object.keys(error.response.data.errors)[0];
  toast.error(capitalize(error.response.data.errors[errorKey]));
};

export const getPaymentStatusColor = (value, total) => {
  if (value === 0) return "#F88078";
  if (total <= value) return "#7BC67E";
  return "#FFB547";
};

export const getStatusColor = (value) => {
  switch (value) {
    case "MEMBER":
      return "#5cb85c";
    case "EX_MEMBER":
      return "#d9534f";
    case "GUEST":
      return "#f0ad4e";
    case "LEADER":
      return "#337ab7";
    case "MANAGER":
      return "#337ab7";
    default:
      return "#ffffff";
  }
};

export const getUserType = (type) => {
  switch (type) {
    case "MEMBER":
      return "Membru activ";
    case "EX_MEMBER":
      return "Membru inactiv";
    case "GUEST":
      return "Invitat";
    case "LEADER":
      return "Lider";
    case "MANAGER":
      return "Manager";
    default:
      return "Invalid";
  }
};

export const getTaxDetailsColor = (value) => {
  if (value === "Completare dosar") return "#64B6F7";
  if (value === "Verificare/semnare") return "#2196F3";
  if (value === "Fara alocatie") return "#F88078";
  return "#21c5f3";
};

export const uploadSingleFile = async (e, callback, type) => {
  const file = e.target.files[0];

  let typeValidation;

  if (type === "image") {
    typeValidation = ["image/jpeg", "image/png"];
  } else if (type === "file") {
    typeValidation = ["application/pdf"];
  } else if (type === "all") {
    typeValidation = ["image/jpeg", "image/png", "application/pdf"];
  }

  if (file.size / 1024 / 1024 > 5) {
    callback({
      message: "The file size is bigger than 5MB!",
      blob: null,
      info: null,
    });
  } else {
    if (typeValidation.find((type) => type === file.type)) {
      try {
        callback({
          message: null,
          blob: file,
          info: {
            name: file.name,
          },
        });
      } catch (err) {
        callback({
          message: "Something went wrong! Please try uploading the file again!",
          blob: null,
          info: null,
        });
      }
    } else {
      callback({
        message:
          type === "image"
            ? "Wrong file type! Only JPEG, PNG formats are supported!"
            : type === "file"
            ? "Wrong file type! Only PDF format is supported!"
            : "Wrong file type! Only JPEG, PNG and PDF formats are supported!",
        blob: null,
        info: null,
      });
    }
  }
};

export const commonValuesInTwoObjects = (obj1, obj2) =>
  Object.keys(obj1).reduce(
    (result, key) =>
      obj1[key] && obj1[key] === obj2[key]
        ? { ...result, [key]: obj1[key] }
        : typeof obj1[key] === "object" && obj1[key] !== null
        ? { ...result, [key]: commonValuesInTwoObjects(obj1[key], obj2[key]) }
        : result,
    {}
  );

export const calculatePercentage = (done, total) => {
  let procent;
  if (done === null) procent = 0;
  else procent = (done * 100) / total;

  return procent.toFixed(2);
};

export const getProcentColor = (procent) => {
  let color = "#FF4B55";
  if (procent >= 19.99) color = "#5B80BA";
  if (procent >= 99.99) color = "#009C10";

  return color;
};

let listenerCallbacks = new WeakMap();

let observer;

function handleIntersections(entries) {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      let cb = listenerCallbacks.get(entry.target);

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb();
      }
    }
  });
}

function getIntersectionObserver() {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: "100px",
      threshold: 0.15,
    });
  }
  return observer;
}

export function useIntersection(elem, callback) {
  React.useEffect(() => {
    let target = elem.current;
    let observer = getIntersectionObserver();
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
  }, []);
}

export const getMembershipStatus = (membership, subscription) => {
  if (membership !== null && subscription !== null) {
    if (subscription.status === "CANCELED" || subscription.status === "PENDING")
      return false;
    else return true;
  } else return false;
};
