import React from "react";
import { CustomDialog } from "lib";
import { TextField } from "lib";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useRef } from "react";
import { toast } from "react-toastify";
import { PoliticsAPI } from "api_inbusiness";
import styles from "./TermsAndConditions.module.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, ContentState, convertToRaw } from "draft-js";

import { Editor } from "react-draft-wysiwyg";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const TermsAndConditionsModal = ({
  open,
  setOpen,
  rowState,
  triggerRefetch,
  setTriggerRefetch,
}) => {
  const formRef = useRef();

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const INITIAL_FORM_STATE = {
    title: rowState?.title ? rowState.title : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    title: yup
      .string()
      .typeError("Titlul nu este valid!")
      .trim()
      .min(1, "Titlul trebuie să conțină cel puțin un caracter!")
      .required("Titlul este obligatoriu!"),
  });

  const handleAddTerms = (values) => {
    const objForApi = { ...values };
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    objForApi.description = htmlContent;

    PoliticsAPI.create(objForApi).then((res) => {
      if (res.ok === true) {
        toast.success("Poltica a fost adaugată cu succes");
        setTriggerRefetch(!triggerRefetch);
        setEditorState(EditorState.createEmpty());
        setOpen(false);
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  const handleEditTerms = (values) => {
    const objForApi = { ...values };
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    objForApi.description = htmlContent;

    PoliticsAPI.update(objForApi, rowState.id).then((res) => {
      if (res.ok === true) {
        toast.success("Poltica a fost editata");
        setTriggerRefetch(!triggerRefetch);
        setEditorState(EditorState.createEmpty());
        setOpen(false);
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  React.useEffect(() => {
    if (!rowState) return;

    setEditorState(htmlToDraftBlocks(rowState.description));
  }, [rowState]);

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={rowState ? "Editează document" : "Adaugă document"}
      buttonFinish="Finalizează"
      onClickButtonFinish={handleSubmitButton}
      buttonClose="Înapoi"
      maxWidth="650px"
      width="100%"
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (rowState) {
            handleEditTerms(values);
          } else {
            handleAddTerms(values);
          }
        }}
      >
        <Form className={styles.formWrapper}>
          <div className={styles.textFieldsWrapper}>
            <TextField name="title" label="Titlu*" size="medium" />

            <Editor
              name="description"
              placeholder="Introduceți articolul"
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-class"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
              }}
              editorStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
                padding: "0px 8px",
                overflowY: "auto",
                maxHeight: "30vh",
                minHeight: "30vh",
              }}
              preserveSelectionOnBlur={true}
            />
          </div>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default TermsAndConditionsModal;
