import React from "react";

import { CustomTable } from "lib";
import { Typography, TextField, Button } from "@mui/material";
import styles from "./Events.module.scss";

import TimelineList from "lib/components/Timeline/TimelineList/TimelineList";
import TimelineItem from "lib/components/Timeline/TimelineItem/TimelineItem";

import { PrimaryButton } from "lib/components/Buttons/buttons.jsx";

const DisplayEvents = ({
  events,
  getAllEvents = () => {},
  handleMoreEvents = () => {},
  hasMore,
}) => {
  const [selectedEvent, setSelectedEvent] = React.useState(null);

  const renderTimelineItems = events.map(
    ({
      id,
      color,
      icon,
      title,
      date,
      startTime,
      location,
      description,
      badges,
      lastItem,
      county,
      participants,
      draft,
      customId,
      custom,
      subtitle,
      tickets,
    }) => (
      <TimelineItem
        key={id}
        id={id}
        color={color}
        icon={icon}
        title={title}
        dateTime={date}
        time={startTime}
        location={location}
        description={description}
        badges={badges}
        lastItem={lastItem}
        countyId={county?.name}
        participants={participants}
        draft={draft}
        customId={customId}
        custom={custom}
        subtitle={subtitle}
        tickets={tickets}
        handleEdit={() =>
          setSelectedEvent({
            id,
            title,
            date,
            location,
            description,
            startTime,
            county,
            draft,
            customId,
            custom,
            subtitle,
            tickets,
          })
        }
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        getAllEvents={getAllEvents}
      />
    )
  );
  return (
    <div>
      <TimelineList>{renderTimelineItems}</TimelineList>
      {hasMore && (
        <div className={styles.loadMoreButton}>
          <PrimaryButton onClick={handleMoreEvents}>
            Încarcă mai multe
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default DisplayEvents;
