import { EventsAPI } from "api_inbusiness";
import { PageLayout } from "lib";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import styles from "./Ticket.module.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import QrCode from "react-qr-code";
import { LoadingSpinner } from "lib";
import { UtilityButton } from "lib/components/Buttons/buttons";
import EditTicket from "./EditTicket";

const Ticket = () => {
  const { id } = useParams();
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [editTicket, setEditTicket] = useState(false);

  const getTicket = () => {
    EventsAPI.getTicket(id).then((res) => {
      setTicket(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTicket();
  }, []);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Bilet</title>
      </Helmet>
      <PageLayout backArrow={true} title="Bilet">
        {loading ? (
          <LoadingSpinner loading={loading} margin={"0 auto"} />
        ) : (
          <div className={styles.ticketContainer}>
            <div className={styles.ticketInfoContainer}>
              <div>Nume Complet</div>
              <div>{ticket.fullName}</div>
            </div>
            <div className={styles.ticketInfoContainer}>
              <div>Eveniment</div>
              <div>{ticket.event?.title}</div>
            </div>
            <div className={styles.ticketInfoContainer}>
              <div>Seria</div>
              <div>{ticket.serial}</div>
            </div>
            <div className={styles.ticketInfoContainer}>
              <div>Numar</div>
              <div>{ticket.number}</div>
            </div>
            <div className={styles.ticketInfoContainer}>
              <div>Valid</div>
              <div>
                {ticket.valid === true ? (
                  <CheckCircleIcon sx={{ color: "green" }} />
                ) : (
                  <DoDisturbIcon sx={{ color: "red" }} />
                )}
              </div>
            </div>
            <div className={styles.qrContainer}>
              <div>Cod QR</div>
              <div>
                {" "}
                <QrCode size={100} value={ticket.id} viewBox={`0 0 256 256`} />
              </div>
            </div>
            <div className={styles.editButtonStyle}>
              <UtilityButton onClick={() => setEditTicket(true)}>
                Edit
              </UtilityButton>
            </div>
          </div>
        )}
        <EditTicket
          open={editTicket}
          setOpen={setEditTicket}
          ticket={ticket}
          getTicket={getTicket}
        />
      </PageLayout>
    </>
  );
};

export default Ticket;
