import React, { useEffect, useState } from "react";

import { SubscriptionsAPI } from "api_inbusiness";
import { CustomTable, PageLayout } from "lib";

import { Helmet } from "react-helmet";

import EditSubscriptionsDialog from "./EditSubscriptionsDialog";
import { Typography } from "@mui/material";
import { EditButton } from "lib/components/Buttons/buttons";

import dayjs from "dayjs";
import { useUser } from "hooks/useUser";
import { useHistory } from "react-router-dom";

const subscriptionDictionary = {
  MONTHLY: "LUNAR",
  YEARLY: "ANUAL",
};

const labels = [
  {
    id: "name",
    label: "Tip abonament",
    minWidth: 10,
  },

  {
    id: "price",
    label: "Pret",
    minWidth: 10,
  },
  {
    id: "recurrence",
    label: "Plata",
    minWidth: 10,
  },
  {
    id: "lastUpdated",
    label: "Ultima modificare",
    minWidth: 10,
  },
  {
    id: "actions",
    label: "Actiuni",
    minWidth: 10,
  },
];

const SubscriptionNomenclature = () => {
  const { permissionsData } = useUser();
  const navigate = useHistory();
  const [subscriptionsData, setSubscriptionsData] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rowState, setRowState] = useState(null);

  const cellModifier = (row, column, value) => {
    if (column.id === "name") {
      return <Typography variant="tableContent"> {row.name}</Typography>;
    }

    if (column.id === "price") {
      return <Typography variant="tableContent"> {row.price} lei</Typography>;
    }

    if (column.id === "recurrence") {
      return (
        <Typography variant="tableContent">
          {subscriptionDictionary[row.recurrence]}
        </Typography>
      );
    }

    if (column.id === "lastUpdated") {
      return (
        <Typography>{dayjs(row.updatedAt).format("DD/MM/YYYY")}</Typography>
      );
    }

    if (column.id === "actions") {
      return (
        <EditButton
          color="#1860D2"
          onClick={() => {
            setRowState(row);
            setOpenEditModal(true);
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (!permissionsData.seeAdminButtons) {
      navigate.push("/");
    }
  }, [permissionsData.seeAdminButtons]);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Abonamente</title>
      </Helmet>
      <PageLayout title="Abonamente">
        <CustomTable
          labels={labels}
          loading={loading}
          setLoading={setLoading}
          cellModifier={cellModifier}
          tableData={subscriptionsData}
          withPagination={true}
          setterFunction={setSubscriptionsData}
          triggerRefetch={triggerRefetch}
          getterFunction={SubscriptionsAPI.get}
        />

        <EditSubscriptionsDialog
          open={openEditModal}
          setOpen={setOpenEditModal}
          setTriggerRefetch={setTriggerRefetch}
          rowState={rowState}
        />
      </PageLayout>
    </>
  );
};

export default SubscriptionNomenclature;
