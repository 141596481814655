import React, { useState, useEffect } from "react";
import { CustomTable, PageLayout } from "lib";
import { useHistory } from "react-router-dom";
import { Typography, Chip } from "@mui/material";
import Tooltip from "../../lib/components/Tooltip/TooltipWrapper";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import StarIcon from "@mui/icons-material/Star";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { getStatusColor, getUserType } from "utils/functions";
import styles from "./Users.module.scss";
import { UsersAPI } from "api_inbusiness";
import Search from "lib/components/Search/Search";
import AddCompanyModal from "pages/Profile/AddCompanyModal";
import AddMembershipModal from "./UserModals/AddMembership/AddMembershipModal";
import SeeEventsModal from "./UserModals/SeeEventsModal/SeeEventsModal";
import CompanyModal from "./UserModals/CompanyModals/CompanyModal";
import { PrimaryButton } from "lib/components/Buttons/buttons";

import { useUser } from "hooks/useUser";
import ChangeTypeModal from "./UserModals/ChangeTypeModal/ChangeTypeModal";
import DeleteAccount from "lib/components/Modals/DeleteAccount/DeleteAccount";

const AdminViewUsers = () => {
  const navigate = useHistory();
  const { permissionsData } = useUser();
  const [openCompanies, setOpenCompanies] = React.useState(false);
  const [openMembership, setOpenMembership] = React.useState(false);
  const [openEvents, setOpenEvents] = React.useState(false);
  const [changeType, setChangeType] = React.useState(false);

  //for table
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [rowDetails, setRowDetails] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const labels = [
    {
      id: "fullName",
      label: "Name",
      minWidth: 10,
    },
    {
      id: "userType",
      label: "Tip",
      minWidth: 10,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 10,
    },
    {
      id: "phone",
      label: "Număr de telefon",
      minWidth: 10,
    },
    {
      id: "companies",
      label: "Firme",
      minWidth: 10,
    },
    {
      id: "observations",
      label: "Observații",
      minWidth: 10,
    },

    {
      id: "actions",
      minWidth: 10,
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "userType") {
      return (
        <div
          style={{
            backgroundColor: getStatusColor(row.type),
            color: "#ffffff",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          {getUserType(row.type)}
        </div>
      );
    }
    if (column.id === "fullName") {
      return (
        <div className={styles.userNameColumn}>
          <Typography variant="tableContent">
            {row.lastName} {row.firstName}
          </Typography>
        </div>
      );
    }
    if (column.id === "phone") {
      return <Typography variant="tableContent">{row.phone}</Typography>;
    }
    if (column.id === "companies") {
      return (
        <div className={styles.companiesDisplayTable}>
          {row.companies?.map((company) => (
            <Chip
              key={company.id}
              label={company.name}
              sx={{ backgroundColor: "yellowgreen", color: "white" }}
            />
          ))}
        </div>
      );
    }

    if (column.id === "actions") {
      return (
        <div className={styles.actionArrow}>
          <Tooltip position="right" textTip={"View more details"} followCursor>
            <div
              className={styles.seeDetailsButton}
              onClick={() => navigate.push(`/users/${row.id}`)}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip position="right" textTip={"Companii"} followCursor>
            <div
              className={styles.seeCompanies}
              onClick={() => {
                setRowDetails(row);
                setOpenCompanies(true);
              }}
            >
              <BusinessCenterIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip position="right" textTip={"Perioada"} followCursor>
            <div
              className={styles.seePeriod}
              onClick={() => {
                setRowDetails(row);
                setOpenMembership(true);
              }}
            >
              <StarIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip position="right" textTip={"Evenimente"} followCursor>
            <div
              className={styles.seeEvents}
              onClick={() => {
                setRowDetails(row);
                setOpenEvents(true);
              }}
            >
              <ReplayIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip position="right" textTip={"Schimba tipul"} followCursor>
            <div
              className={styles.changeType}
              onClick={() => {
                setRowDetails(row);
                setChangeType(true);
              }}
            >
              <ManageAccountsIcon fontSize="small" />
            </div>
          </Tooltip>
          <Tooltip position="right" textTip={"Sterge"} followCursor>
            <div
              className={styles.deleteMember}
              onClick={() => {
                setRowDetails(row);
                setDeleteModal(true);
              }}
            >
              <DeleteIcon fontSize="small" />
            </div>
          </Tooltip>
        </div>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  const resetSearch = () => {
    setIsSearch(false);
    setSearch("");
  };

  const resetFilter = () => {
    setIsFilter(false);
    setFilterData({});
  };

  useEffect(() => {
    if (!permissionsData.seeAdminButtons) {
      navigate.push("/");
    }
  }, []);

  return (
    <>
      <PageLayout
        title={"Membri"}
        subTitle={"Administrează membri"}
        actionArea={
          <>
            <Search
              isFilter={isFilter}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              search={search}
              setSearch={setSearch}
              nameToFind={"Caută membru"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setLoading={setLoading}
              setterFunction={setUsers}
              setIsFilter={setIsFilter}
              resetFilter={resetFilter}
            />
            <PrimaryButton
              startIcon={<VisibilityIcon />}
              color="primary"
              onClick={() => navigate.push("/users")}
            >
              {"Vezi membri ca utilizator"}
            </PrimaryButton>
          </>
        }
      >
        <CustomTable
          title="Membri"
          labels={labels}
          loading={loading}
          setLoading={setLoading}
          tableData={users}
          cellModifier={cellModifier}
          getterFunction={UsersAPI.get}
          // additionalId={getDynamicData().additionalId}
          setterFunction={setUsers}
          withPagination={true}
          triggerRefetch={triggerRefetch}
          triggerSearch={triggerSearch}
          searchFunction={UsersAPI.searchUsers}
          search={search}
          isSearch={isSearch}
          // filterSearch={getDynamicData().filterSearch}
          triggerFilter={triggerFilter}
          setTriggerFilter={setTriggerFilter}
          isFilter={isFilter}
          // filterFilter={getDynamicData().filterFilter}
          // filterFunction={UsersAPI.filterUsers}
          filterData={filterData}
        />
      </PageLayout>
      {/* <AddCompanyModal
        open={openCompanies}
        setOpen={setOpenCompanies}
        dataForEdit={rowDetails}
        userId={rowDetails.id}
      /> */}
      <CompanyModal
        open={openCompanies}
        setOpen={setOpenCompanies}
        userDetails={rowDetails}
      />

      <AddMembershipModal
        open={openMembership}
        setOpen={setOpenMembership}
        rowDetails={rowDetails}
      />
      <SeeEventsModal
        open={openEvents}
        setOpen={setOpenEvents}
        rowDetails={rowDetails}
      />

      <ChangeTypeModal
        open={changeType}
        setOpen={setChangeType}
        rowDetails={rowDetails}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
      />

      <DeleteAccount
        open={deleteModal}
        setOpen={setDeleteModal}
        type="admin"
        rowDetails={rowDetails}
        setTriggerRefetch={setTriggerRefetch}
      />
    </>
  );
};

export default AdminViewUsers;
