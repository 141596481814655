import axios from "./axios";
import dayjs from "dayjs";

const Finances = {
  /**
   * Fetches all Finances's
   *
   * @return {array} Array of Finances's
   */
  async get({ start, end }, perPage, currentPage, pagesToLoad) {
    try {
      const res = await axios.get("/payment/finances", {
        params: {
          start: new Date(dayjs(start).add(1, "day")),
          end: new Date(dayjs(end).add(1, "day")),
          perPage,
          currentPage,
          pagesToLoad,
        },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Finances by it's ID
   *
   * @param {string} id ID of the Finances
   * @return {object} Finances with the corresponding ID
   */
  async getById(financesId) {
    try {
      const res = await axios.get("/finances/" + financesId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Finances
   *
   * @param {object} finances Object with the creation data
   * @return {object} Newly created Finances
   */
  async create(finances) {
    let reqBody = { ...finances };
    try {
      const res = await axios.post("/finances", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Get invoice files
   *
  
   */
  async getInvoice(invoice) {
    try {
      const res = await axios.get(`/payment/invoice/${invoice}`, {
        responseType: "arraybuffer",
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Deletes Finances by ID
   *
   * @param {string} financesId ID of the Finances to be deleted
   * @return {string} ID of the deleted Finances
   */
  async delete(financesId) {
    try {
      await axios.delete("/finances/" + financesId);
      return {
        ok: true,
        data: {
          id: financesId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: financesId,
        },
      };
    }
  },
};

export default Finances;
