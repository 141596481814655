import React, { useState } from "react";
import { toast } from "react-toastify";
import { ProposedAPI } from "api_inbusiness/index.js";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import styles from "./ProposedEvents.module.scss";
import { useUser } from "hooks/useUser";

const LikeDislike = ({ event, isChecked, setIsChecked }) => {
  const { permissionsData } = useUser();
  const [likeCount, setLikeCount] = useState(event.likes);
  const [dislikeCount, setDislikeCount] = useState(event.dislikes);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  // Handle Like
  function handleLike() {
    // if (!liked) {
    //   handleUpdatedEvent(likeCount + 1, dislikeCount, isChecked);
    //   setLikeCount(likeCount + 1);
    //   setLiked(true);
    //   if (disliked) {
    //     setDisliked(false);
    //     handleUpdatedEvent(likeCount, dislikeCount - 1, isChecked);
    //     setDislikeCount(dislikeCount - 1);
    //   }
    // }
    ProposedAPI.likeProposedEvent(event.id).then((res) => {
      if (res.ok === true) {
        setLikeCount(res.data.likes);
        setDislikeCount(res.data.dislikes);
        toast.success("Ai apreciat această sugestie!");
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  }

  // Handle Dislike
  function handleDislike() {
    // if (!disliked) {
    //   handleUpdatedEvent(likeCount, dislikeCount + 1, isChecked);
    //   setDislikeCount(dislikeCount + 1);
    //   setDisliked(true);
    //   if (liked) {
    //     setLiked(false);
    //     setLikeCount(likeCount - 1);
    //     handleUpdatedEvent(likeCount - 1, dislikeCount, isChecked);
    //   }
    // }
    ProposedAPI.dislikeProposedEvent(event.id).then((res) => {
      if (res.ok === true) {
        setDislikeCount(res.data.dislikes);
        setLikeCount(res.data.likes);
        toast.success("Nu ai apreciat această sugestie!");
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  }

  // Handle Remove Like
  function handleRemoveLike() {
    if (liked) {
      handleUpdatedEvent(likeCount - 1, dislikeCount, isChecked);
      setLikeCount(likeCount - 1);
      setLiked(false);
    }
  }

  // Handle Remove Dislike
  function handleRemoveDislike() {
    if (disliked) {
      handleUpdatedEvent(likeCount, dislikeCount - 1, isChecked);
      setDislikeCount(dislikeCount - 1);
      setDisliked(false);
    }
  }

  // Handle Edited Event
  function handleUpdatedEvent(likes, dislikes, checkedStatus) {
    const eventObject = {
      checked: checkedStatus,
    };
    ProposedAPI.updateEvent(event.id, eventObject).then((res) => {
      if (res.ok) {
        if (res.data.checked === true)
          toast.success("Propunerea a fost marcată ca propusă!");
        else {
          toast.success("Propunerea nu mai este luată în evidență!");
        }
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  }

  // Handle Check
  const handleChange = () => {
    permissionsData.seeAdminButtons && setIsChecked(!isChecked);

    permissionsData.seeAdminButtons &&
      handleUpdatedEvent(likeCount, dislikeCount, !isChecked);
  };

  return (
    <div className={styles.eventFooter}>
      <label>
        <input
          value={isChecked}
          type="checkbox"
          className={styles.eventCheckbox}
          checked={isChecked}
          onChange={() => {
            handleChange();
          }}
        />
        Propunerea a fost luată în considerare
      </label>

      <div className={styles.eventButtons}>
        <PrimaryButton
          startIcon={<ThumbUpIcon />}
          onClick={() => {
            handleLike();
          }}
          onDoubleClick={handleRemoveLike}
          disabled={liked || disliked}
        >
          {likeCount}
        </PrimaryButton>
        <PrimaryButton
          startIcon={<ThumbDownIcon />}
          onClick={() => {
            handleDislike();
          }}
          onDoubleClick={handleRemoveDislike}
          disabled={liked || disliked}
        >
          {dislikeCount}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default LikeDislike;
