import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

const SimpleDatePicker = ({ date, setDate = () => {}, label, name }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={date}
        onChange={(newValue) =>
          setDate({ target: { name: name, value: newValue } })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              fieldset: { borderRadius: "10px" },
              ".css-16jwpsg-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#FF4B55",
                },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#5b80ba",
                },
              },
              "& label.Mui-focused": {
                color: "#5b80ba",
              },
            }}
          />
        )}
        inputFormat={"DD/MM/YYYY"}
        formatDensity="spacious"
      />
    </LocalizationProvider>
  );
};

export default SimpleDatePicker;
