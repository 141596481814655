import React from "react";
import styles from "./TableCard.module.scss";

const TableCard = ({ data, columnModifier, cardHeaders }) => {
  return (
    <div className={styles.tableCard}>
      {Object.entries(cardHeaders).map(([id, name]) => (
        <div className={styles.cardHeader} key={id}>
          <p className={styles.headerText}>{name}</p>
          <div className={styles.columnData}>
            {columnModifier(data, { id, name }, data[id])}
          </div>{" "}
        </div>
      ))}
    </div>
  );
};

export default TableCard;
