import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { TextField } from "lib";
import { CustomDialog } from "lib";
import { EducationAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import styles from "./Education.module.scss";

const AddEducationCategory = ({
  open,
  setOpen,
  rowState = null,
  getCategories,
  triggerRefetch,
  setTriggerRefetch = () => {},
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [hasModified, setHasModified] = useState(false);
  const [loading, setLoading] = useState(false);

  const INITIAL_FORM_STATE = {
    name: rowState?.name ? rowState.name : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    name: yup.string().trim().required(t("Categoria este obligatorie!")),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={rowState ? t("Editează categoria") : t("Adaugă categorie")}
      buttonClose={t("Înapoi")}
      buttonFinish={t("Finalizează")}
      buttonFinishDisabled={rowState ? !hasModified : false}
      onClickButtonFinish={handleSubmit}
      onClickButtonClose={() => {
        setOpen(false);
        setHasModified(false);
      }}
      width="580px"
      buttonFinishLoading={loading}
      buttonCloseDisabled={loading}
    >
      <Formik
        innerRef={formRef}
        // enableReinitialize={true}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          rowState
            ? EducationAPI.updateCategory(rowState.id, values).then((res) => {
                if (res.ok === true) {
                  toast.success("Categoria a fost actualizată cu succes!");
                  setTriggerRefetch(!triggerRefetch);
                  setOpen(false);
                } else {
                  toast.error("A intervenit o eroare!");
                }
              })
            : EducationAPI.createCategory(values).then((res) => {
                if (res.ok === true) {
                  toast.success("Categorie adăugată cu succes!");
                  setTriggerRefetch(!triggerRefetch);
                  setOpen(false);
                } else {
                  toast.error("A intervenit o eroare!");
                }
              });
        }}
      >
        <Form
          onChange={(e) => {
            const { name, value } = e.target;

            if (rowState && INITIAL_FORM_STATE[name] === value)
              setHasModified(false);
            else if (rowState && INITIAL_FORM_STATE[name] !== value)
              setHasModified(true);
          }}
        >
          <div className={styles.addWorkPoint}>
            <TextField name="name" label={`${t("Categorie")}`} size="medium" />
          </div>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddEducationCategory;
