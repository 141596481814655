import React from "react";
import TableCard from "./TableCard";

const WrapperTableCards = ({ data, columnModifier, cardHeaders }) => {
  return data.map((item) => (
    <TableCard
      key={item.id}
      data={item}
      columnModifier={columnModifier}
      cardHeaders={cardHeaders}
    />
  ));
};

export default WrapperTableCards;
