import React from "react";

import styles from "./SubscriptionRenewal.module.scss";
import { Divider, Typography } from "@mui/material";
import { ActionButton } from "lib/components/Buttons/buttons";
import CheckIcon from "@mui/icons-material/Check";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { PaymentsAPI } from "api_inbusiness";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";
import JuidicalPersonPayment from "pages/BecomeAMember/JuidicalPersonPayment";

const SubscriptionRenewal = (props) => {
  const { data, benefits } = props;
  const { getUpdatedUser } = useUser();
  const navigate = useHistory();

  const [agree, setIsAgree] = React.useState(false);

  const [pjPayment, setPjPayment] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);

  const handleNewPayment = () => {
    PaymentsAPI.create().then((res) => {
      if (res.ok === true) {
        window.open(res.data.link, "_blank");
        PaymentsAPI.getPaymentStatus(res.data.estimateId).then((res) => {
          if (res.ok) {
            toast.success("Subscripția a fost achiziționată cu succes!");
            getUpdatedUser();
            navigate.push("/");
          } else {
            toast.error("A intervenit o eroare la plata subscripției!");
          }
        });
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  return (
    <div className={styles.mainContainer}>
      <div>
        <Typography variant="h7">{data[0].title}</Typography>
      </div>
      <div className={styles.priceInfo}>{data[0].price}</div>
      <div className={styles.benefitsInfo}>
        {benefits.map((bnf) => (
          <div key={bnf.id}>
            <CheckIcon color="success" fontSize="small" />
            <Typography variant="clientListMember">{bnf.text1}</Typography>
            {bnf.text2 && (
              <ul className={styles.listOfBenefits}>
                <li>
                  <AddTaskIcon
                    className={styles.checkedIcon}
                    color="success"
                    fontSize="small"
                  />
                  <Typography variant="clientListMember">
                    {bnf.text2}
                  </Typography>
                </li>
                {bnf.text3 && (
                  <li>
                    <AddTaskIcon
                      className={styles.checkedIcon}
                      color="success"
                      fontSize="small"
                    />
                    <Typography variant="clientListMember">
                      {bnf.text3}
                    </Typography>
                  </li>
                )}
              </ul>
            )}

            <Divider />
          </div>
        ))}
      </div>
      <div>
        <label style={{ display: "flex", gap: "10px" }}>
          <input
            value={agree}
            type="checkbox"
            checked={agree}
            onChange={() => {
              setIsAgree(!agree);
            }}
          />
          <Typography variant="clientListMember">
            Am citit și sunt de acord cu{" "}
            <a
              target="_blank"
              href="https://inbusiness.club/termeni-si-conditii"
              rel="noopener noreferrer"
            >
              Termenii și condițiile
            </a>{" "}
            acestui website <span>*</span>
          </Typography>
        </label>
      </div>

      <div>
        <label style={{ display: "flex", gap: "10px" }}>
          <input
            value={pjPayment}
            type="checkbox"
            checked={pjPayment}
            onChange={() => {
              setPjPayment(!pjPayment);
            }}
          />
          <Typography variant="clientListMember">
            Doresc facturare pe persoana juridică
          </Typography>
        </label>
      </div>

      <div>
        <ActionButton
          onClick={() => {
            if (pjPayment === true) setOpenPayment(true);
            else {
              handleNewPayment();
            }
          }}
          color={"blue"}
          disabled={!agree}
        >
          Vreau să reînnoiesc abonamentul
        </ActionButton>
      </div>

      <JuidicalPersonPayment open={openPayment} setOpen={setOpenPayment} />
    </div>
  );
};

export default SubscriptionRenewal;
