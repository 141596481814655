import React, { useEffect, useState } from "react";
import { PageLayout } from "lib";
import { useUser } from "hooks/useUser";
import { Helmet } from "react-helmet";
import SubscriptionRenewal from "./SubscriptionRenewal";
import styles from "./RenewalMember.module.scss";
import visaLogo from "../../resources/visa-and-mastercard-logo-26-1024x256.png";
import euPlatesc from "../../resources/logoEuPlatesc.png";

const RenewalMember = () => {
  const data = [
    {
      title: "Pentru doar",
      price: "270lei/3luni",
    },
  ];

  const benefits = [
    {
      id: 1,
      text1: "Acces la intalniri InBusiness saptamanale",
      text2:
        "dezbateri de video-uri de business, TED-uri, podcast-uri, articole tematice de afaceri etc",
      text3:
        "sesiuni de mastermind, mai exact posibilitatea de a obtine raspunsuri diversificate si utile din partea grupului de antreprenori si manageri InBusiness, la probleme/nevoi legate de afacerea ta",
    },
    {
      id: 2,
      text1: "Acces gratuit inclusiv la intalnirile cu invitati speciali",
      text2: " pentru non membri va exista o taxa de participare",
    },
    {
      id: 3,
      text1:
        "Networking de calitate si posiblitatea de a genera parteneriate, dar si deal-uri noi pentru business-ul tau",
    },
    {
      id: 4,
      text1: "Acces la documente educationale InBusiness",
      text2:
        "video-uri, cursuri, seminarii/workshop-uri, carti in format audio si video etc",
    },
    {
      id: 5,
      text1:
        "Posibilitatea de a-ti aduce aportul la dezvoltarea comunitatii de afaceri locale",
    },
  ];
  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {"Reînnoire abonament"}</title>
      </Helmet>
      <PageLayout title={"Reînnoire abonament"}>
        <div className={styles.mainContainer}>
          <SubscriptionRenewal data={data} benefits={benefits} />
        </div>
        <div className={styles.paymentsContainer}>
          <img src={visaLogo} alt="visamastercard logo" />
        </div>
      </PageLayout>
    </>
  );
};

export default RenewalMember;
