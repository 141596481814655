import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingSpinner, PageLayout } from "lib";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import CheckAttendanceEvent from "./CheckAttendanceEvent";
import { EventsAPI, ParticipantsAPI } from "api_inbusiness";
import ParticipantsList from "./ParticipantsList";

import styles from "./QrAdminPage.module.scss";
import CheckAttendanceCustomEvent from "./CheckAttendanceCustomEvent";

const QrAdminPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState({});
  const [userData, setUserData] = useState("");
  const [openAttendance, setOpenAttendance] = useState(false);
  const [openCustomEvent, setOpenCustomEvent] = useState(false);
  // const [html5QrcodeScanner, setHtml5QrcodeScanner] = useState(null);
  const scannerRef = useRef(null);

  const onNewScanResult = (decodedText, decodedResult, html5QrcodeScanner) => {
    if (decodedText !== "") {
      setUserData(decodedText);
      eventData.custom === true
        ? setOpenCustomEvent(true)
        : setOpenAttendance(true);
      html5QrcodeScanner
        .stop()
        .then((ignore) => {})
        .catch((err) => {
          console.error("QR code scanning failed to stop. ", err);
        });
    }
  };

  const getEvent = () => {
    EventsAPI.getById(id).then((res) => {
      setEventData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <>
      <PageLayout title="Scanează codul QR">
        {loading ? (
          <LoadingSpinner loading={loading} />
        ) : (
          <div className={styles.qrAdminPageContainer}>
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={(
                decodedText,
                decodedResult,
                html5QrcodeScanner
              ) =>
                onNewScanResult(decodedText, decodedResult, html5QrcodeScanner)
              }
            />
          </div>
        )}
      </PageLayout>
      <CheckAttendanceEvent
        open={openAttendance}
        setOpen={setOpenAttendance}
        userData={userData}
        eventId={id}
      />

      <CheckAttendanceCustomEvent
        open={openCustomEvent}
        setOpen={setOpenCustomEvent}
        eventData={eventData}
        qrData={userData}
        eventId={id}
      />
    </>
  );
};

export default QrAdminPage;
