const ro = {
  /**
   * Users
   */
  Users: "Utilizatori",
  "Manage your users": "Administrează-ți utilizatorii",
  "FILTER USERS": "FILTRARE UTILIZATORI",
  "ADD USER": "ADAUGĂ UTILIZATOR",
  DETAILS: "DETALII",
  Name: "Nume",
  "Creation date": "Dată creare",
  "Add user": "Adăugare utilizator",
  "Add profile picture": "Adaugă imagine de profil",
  "Choose the brands": "Alege mărcile",
  UPLOAD: "ÎNCARCĂ",
  BACK: "ÎNAPOI",
  COMPLETE: "FINALIZEAZĂ",
  Login: "Autentificare",
  "Manage your counsellors": "Gestionați-vă consilierii",
  "FILTER COUNCILORS": "FILTRARE CONSILIERI",
  "Filter users": "Filtrați utilizatorii",
  /**
   * User
   */
  "User clients": "Clienți utilizator",
  "User objectives": "Obiective utilizator",
  "User tasks": "Sarcini utilizator",
  "User offers": "Oferte utilizator",
  Back: "Înapoi",
  User: "Utilizator",
  Brands: "Mărci",
  EDIT: "MODIFICĂ",
  BLOCK: "BLOCARE",
  UNBLOCK: "DEBLOCARE",
  "RESET PASSWORD": "RESETARE PAROLA",
  "First Name": "Prenume",
  "Assigned work point": "Punct de lucru alocat",
  "Last Name": "Nume",
  Role: "Rol",
  SAVE: "SALVEAZĂ",
  Offers: "Oferte",
  Tasks: "Sarcini",
  Objectives: "Obiective",
  Clients: "Clienți",
  "The first name is not valid!": "Prenumele nu este valid!",
  "The first name must contain at least 3 characters!":
    "Prenumele trebuie să conțină cel puțin 3 caractere!",
  "The first name is mandatory!": "Prenumele este obligatoriu!",
  "This last name is not valid!": "Acest nume de familie nu este valabil!",
  "The last name must contain at least 3 characters!":
    "Numele de familie trebuie să conțină cel puțin 3 caractere!",
  "The last name is mandatory!": "Numele de familie este obligatoriu!",
  "The role is mandatory!": "Rolul este obligatoriu!",
  "The location is mandatory!": "Locația este obligatorie!",
  "At least one brand is required!": "Este necesară cel puțin o marcă!",
  "Select one or more brands!": "Selectați una sau mai multe mărci!",
  "CHANGE PHOTO": "SCHIMBĂ FOTOGRAFIA",
  "Are you sure you want to block this user?":
    "Sigur doriți să blocați acest utilizator?",
  "Are you sure you want to reset this user?":
    "Sigur doriți să resetați acest utilizator?",
  "Edit user": "Editați utilizatorul",
  "Search user": "Caută utilizator",

  //Errors and success messages
  "Profile picture has been uploaded successfully!":
    "Poza de profil a fost încărcată cu succes!",
  "Something went wrong! Profile picture could not be uploaded successfully!":
    "Ceva n-a mers bine! Poza de profil nu a putut fi încărcată cu succes!",
  "The role with this name already exist":
    "Un rol cu același nume deja există!",
  "Invalid email or password": "Parola sau email-ul sunt greșite!",
  "The email doesn't exist! ": "Email-ul nu există în baza de date!",
  "Are you sure you want to unblock this user?":
    "Sigur doriți să deblocați acest utilizator?",
  "This user has been blocked!": "Acest utilizator a fost blocat!",
  "Something went wrong! This user could not be blocked!":
    "Ceva n-a mers bine! Acest utilizator nu a putut fi blocat!",
  "This user has been unblocked!": "Acest utilizator a fost deblocat!",
  "Something went wrong! This user could not be unblocked!":
    "Ceva n-a mers bine! Acest utilizator nu a putut fi deblocat!",
  "Email is required!": "E-mailul este necesar!",
  "Upload a profile picture before submitting the form!":
    "Încarcă o poză de profil înainte de a trimite formularul!",
  "User has been created successfully!": "Utilizatorul a fost creat cu succes!",
  "The email is already use! User could not been created successfully!":
    "E-mailul este deja folosit! Utilizatorul nu a putut fi creat cu succes!",
  "Something went wrong! User could not been created":
    "Ceva n-a mers bine! Utilizatorul nu a putut fi creat!",
  "User has been updated successfully!":
    "Utilizatorul a fost actualizat cu succes!",
  "Something went wrong! User could not be updated!":
    "Ceva n-a mers bine! Utilizatorul nu a putut fi actualizat!",
  "Not assigned work point yet. Please, go to nomenclature and choose one":
    "Nu există un punct de lucru definit. Vă rog, adăugați unul din nomenclatoare",
  "View more details": "Vezi mai multe detalii",
  "ADD WORKPOINT": "ADAUGĂ PUNCT DE LUCRU",
  "Something went wrong! User image could not be updated!":
    "Ceva n-a mers bine! Imaginea utilizatorului nu a putut fi actualizată!",
  "Work point": "Punct de lucru",
  "You must enter a valid email!": "Adresa de email e invalidă!",
  "Something went wrong! User could not been created!":
    "Ceva n-a mers bine! Utilizatorul nu a putut fi creat!",
  "Only one brand is required!": "Este necesară o singură marcă!",
  "Select one brand!": "Alege o singură marcă!",
  "At least one superior is required!": "Este necesar cel putin un superior!",
  "Only one superior is required!": "Este necesar un singur superior!",
  "Select one superior!": "Alege unul superior!",
  "You can have a maximum of three superiors!":
    "Poti avea maxim trei superiori!",
  "You have to select at least one brand!":
    "Trebuie să selectați cel puțin o marcă!",
  "You have to select the correct number of brands!":
    "Trebuie să selectați numărul corect de mărci!",
};

export default { ro };
