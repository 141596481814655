import { useState, useEffect, useCallback } from "react";
import { useUser } from "hooks/useUser";
import useWindowDimensions from "../hooks/useWindowDimensions";

import { Drawer } from "@mui/material";
import { Appbar, Sidebar } from "../lib";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.scss";

//svg imports
import { ReactComponent as UsersIcon } from "resources/svg/menu-navbar/UsersIcon.svg";
import { ReactComponent as DashboardIcon } from "resources/svg/menu-navbar/DashboardIcon.svg";

import { ReactComponent as ProfileIcon } from "resources/svg/menu-navbar/ProfileIcon.svg";
import { ReactComponent as LogoutIcon } from "resources/svg/menu-navbar/LogoutIcon.svg";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SchoolIcon from "@mui/icons-material/School";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TermsIcon from "@mui/icons-material/AssignmentLate";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PaymentsIcon from "@mui/icons-material/Payments";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PaidIcon from "@mui/icons-material/Paid";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { getMembershipStatus } from "utils/functions";
import { UsersAPI } from "api_inbusiness";

function Layout() {
  const { vw } = useWindowDimensions();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  // const [selectedLang, setSelectedLang] = useState(i18n.language);
  const { permissionsData, user } = useUser();
  const [profilePic, setProfilePic] = useState(null);

  const menuHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    (async () => {
      if (user.avatarId) {
        const { data: imgUrl } = await UsersAPI.getNewUserPicture(
          user.avatarId
        );
        setProfilePic(imgUrl);
      }
    })();
  }, [user.avatarId]);

  // const handleLangChange = (prop) => {
  //   const lang = prop;
  //   localStorage.setItem("preferredLang", lang);
  //   setSelectedLang(lang);
  //   i18n.changeLanguage(lang);
  // };

  // useEffect(() => {
  //   setSelectedLang(i18n.language);
  // }, [i18n.language]);

  return (
    <>
      <div className={styles.wrapper}>
        <Appbar onMenuClick={menuHandler} />

        <Drawer
          open={open}
          onClose={menuHandler}
          variant={vw >= 1080 ? "permanent" : "temporary"}
          sx={{
            ".MuiPaper-root": {
              height: vw >= 1080 ? "auto" : "100svh", //this is a fix for the eternal safari browser
            },
          }}
        >
          <div className={styles.container}>
            <Sidebar
              profilePicture={profilePic}
              navMain={[
                {
                  render: true,
                  label: t("Menu"),
                  navItems: [
                    {
                      content: t("Bine ai venit!"),
                      icon: <DashboardIcon />,
                      render: true,
                      path: "/",
                      nested: false,
                    },
                    {
                      content: "Noutăți",
                      icon: <NewspaperIcon />,
                      render: permissionsData.seeSection,
                      path: "/news",
                      nested: false,
                    },
                    {
                      content: "Membri",
                      icon: <UsersIcon />,
                      render: permissionsData.seeSection,
                      path: "/users",
                      nested: false,
                    },
                    {
                      content: t("Finanțe"),
                      icon: <PaymentsIcon />,
                      render: permissionsData.seeAdminButtons,
                      path: "/finances",
                      nested: false,
                    },
                    {
                      content: t("Nomenclator Abonamente"),
                      icon: <LoyaltyIcon />,
                      render: permissionsData.seeAdminButtons,
                      path: "/adminSubscriptions",
                      nested: false,
                    },
                    {
                      content: t("Abonamentele mele"),
                      icon: <SubscriptionsIcon />,
                      render: true,
                      path: "/my-payments",
                      nested: false,
                    },
                    {
                      content: t("Evenimente"),
                      icon: <CalendarMonthIcon />,
                      render: true,
                      path: "/events",
                      nested: false,
                    },
                    {
                      content: t("Tranzacții"),
                      icon: <PaidIcon />,
                      render: permissionsData.seeAdminButtons,
                      path: "/transactions",
                      nested: false,
                    },
                    {
                      content: t("Bilete"),
                      icon: <ConfirmationNumberIcon />,
                      render: permissionsData.seeAdminButtons,
                      path: "/tickets",
                      nested: false,
                    },
                    {
                      content: t("Evenimente propuse"),
                      icon: <EventNoteIcon />,
                      render: permissionsData.seeSection,
                      path: "/proposedEvents",
                      nested: false,
                    },
                    {
                      content: t("Educatie"),
                      icon: <SchoolIcon />,
                      render: permissionsData.seeSection,
                      path: "/education",
                      nested: false,
                    },
                    {
                      content: t("Cod QR"),
                      icon: <QrCode2Icon />,
                      render: true,
                      path: "/qr",
                      nested: false,
                    },
                    {
                      content: t("Termeni & Condiții"),
                      icon: <TermsIcon />,
                      render: true,
                      path: "/terms",
                      nested: false,
                    },
                  ],
                },

                {
                  render: true,
                  label: "",
                  navItems: [
                    {
                      content: getMembershipStatus(
                        user.membership,
                        user.subscription
                      )
                        ? "Membership"
                        : "Devino Membru",
                      icon: <PersonAddAlt1Icon />,
                      render: true,
                      path: "/becomeMember",
                      nested: false,
                    },
                  ],
                },
                {
                  render: true,
                  label: t("Account"),
                  navItems: [
                    {
                      content: t("Profile"),
                      icon: <ProfileIcon />,
                      render: true,
                      path: "/profile",
                      nested: false,
                    },
                    {
                      content: t("Logout"),
                      icon: <LogoutIcon />,
                      render: true,
                      nested: false,
                      popover: true,
                    },
                    // {
                    //   withNoIconColor: true,
                    //   content: selectedLang === "ro" ? "Română" : "English",
                    //   icon:
                    //     selectedLang === "ro" ? (
                    //       <RomanaIcon />
                    //     ) : (
                    //       <EnglishIcon />
                    //     ),
                    //   render: true,
                    //   nested: true,
                    //   nestedItems: [
                    //     {
                    //       content: "Română",
                    //       render: true,
                    //       icon: <RomanaIcon />,
                    //       onClick: () => {
                    //         handleLangChange("ro");
                    //       },
                    //     },
                    //     {
                    //       content: "English",
                    //       render: true,
                    //       icon: <EnglishIcon />,
                    //       onClick: () => {
                    //         handleLangChange("en");
                    //       },
                    //     },
                    //   ],
                    // },
                  ],
                },
              ]}
            />
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default Layout;
