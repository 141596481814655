import React, { useRef } from "react";
import styles from "./EventEmail.module.scss";
import { CustomDialog, TextField as TextFieldWrapper } from "lib";
import * as yup from "yup";
import { Formik, Form } from "formik";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EventsAPI, UsersAPI, ParticipantsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";

const CheckboxComponent = ({ user, onChecked, checked }) => {
  const handleChange = (event) => {
    onChecked(user.id, event.target.checked);
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={user.lastName + " " + user.firstName}
    />
  );
};

const EventEmail = ({ open, setOpen = () => {}, title, selectedEvent }) => {
  const { user } = useUser();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const [users, setUsers] = React.useState([]);

  const [checkedUsers, setCheckedUsers] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  //function for handling the checbkoxex
  const handleCheck = (userId, isChecked) => {
    if (isChecked) {
      setCheckedUsers([...checkedUsers, { userId: userId }]);
    } else {
      //delete the user from the array
      setCheckedUsers(checkedUsers.filter((user) => user.userId !== userId));
    }
  };

  const getAllUsers = () => {
    UsersAPI.get().then((res) =>
      setUsers(res.data.content.filter((item) => item.id !== user.id))
    );
  };

  //retrieving all users
  React.useEffect(() => {
    getAllUsers();
  }, []);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  //setting the content of the editor when opening the modal
  //checking all the checkboxes initially
  React.useEffect(() => {
    if (!selectedEvent || users.length <= 0) return;

    let html = selectedEvent.description;
    setEditorState(htmlToDraftBlocks(html));
  }, [selectedEvent, users, open]);

  const formRef = useRef();
  const INITIAL_FORM_STATE = {
    subject: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    subject: yup
      .string()
      .typeError("Subiectul nu este valid!")
      .trim()
      .min(3, "Subiectul trebuie să conțină cel puțin 3 caractere!")
      .required("Subiectul este obligatoriu!"),
  });

  const optionsFilter = [
    {
      status: "Membri activi",
      category: "Status Membru",
      type: "MEMBER",
    },
    { status: "Fosti membri", category: "Status Membru", type: "EX_MEMBER" },
    { status: "Invitati", category: "Status Membru", type: "GUEST" },
    // { status: "Viitori membri", category: "Status Membru" },
    // { status: "Cei care participa", category: "Eveniment" },
    // { status: "Cei care nu participa", category: "Eveniment" },
    // { status: "In acelasi judet", category: "Judet eveniment" },
    // { status: "Alte judete", category: "Judet eveniment" },
  ];

  const onSubmitFunc = (values) => {
    //getting the content of the html editor
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    const objForEmail = {};

    objForEmail.subject = values.subject;
    objForEmail.message = htmlContent;
    objForEmail.eventId = selectedEvent.id;
    objForEmail.userIds = checkedUsers;

    if (objForEmail.userIds.length <= 0) {
      toast.error("Trebuie să selectați cel puțin un utilizator!");
    } else {
      EventsAPI.sendEmail(objForEmail).then((res) => {
        if (res.ok === true) {
          setOpen(false);
          setCheckedUsers([]);

          toast.success("Email trimis cu succes");
        } else {
          toast.error("A intervenit o eroare");
        }
      });
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const filteredUsers = users.filter((item) => {
    if (selectedOptions.length === 0) return users;

    return selectedOptions.some((option) => option.type === item.type);
  });

  const handleChangeAllMembers = () => {
    if (checkedUsers.length === filteredUsers.length) {
      setCheckedUsers([]);
    } else {
      const newCheckedList = filteredUsers.map((user) => {
        return {
          userId: user.id,
        };
      });

      setCheckedUsers(newCheckedList);
    }
  };

  return (
    <CustomDialog
      width="100%"
      maxWidth="800px"
      open={open}
      setOpen={setOpen}
      title={title}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={handleSubmit}
    >
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.upperLeft}>
            <Autocomplete
              multiple
              options={optionsFilter}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.status}
              value={selectedOptions}
              onChange={(_, newValue) => {
                setSelectedOptions(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filtru"
                  sx={{ fieldset: { borderRadius: "16px" } }}
                />
              )}
            />
          </div>
          <div className={styles.downLeft}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p>
                Membri{" "}
                <Checkbox
                  checked={checkedUsers.length === filteredUsers.length}
                  onChange={handleChangeAllMembers}
                />
              </p>
            </div>
            {filteredUsers.map((d) => (
              <div key={d.id}>
                <CheckboxComponent
                  user={d}
                  onChecked={handleCheck}
                  checked={
                    checkedUsers.find((us) => us.userId === d.id) ? true : false
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <Formik
            innerRef={formRef}
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              onSubmitFunc(values);
            }}
          >
            {(props) => {
              return (
                <Form className={styles.form}>
                  <TextFieldWrapper
                    name="subject"
                    label="Subiect*"
                    size="medium"
                  />
                  <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editor-class"
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbarStyle={{
                      borderRadius: 10,
                      border: "1px solid #cbcbcb",
                    }}
                    editorStyle={{
                      borderRadius: 10,
                      border: "1px solid #cbcbcb",
                      padding: "0px 8px",
                      overflowY: "auto",
                      maxHeight: "30vh",
                      minHeight: "30vh",
                    }}
                    preserveSelectionOnBlur={true}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </CustomDialog>
  );
};

export default EventEmail;
