import axios from "./axios";

const Periods = {
  /**
   * Fetches all Periods's
   *
   * @return {array} Array of Periods's
   */
  async get(userId) {
    try {
      const res = await axios.get("/payment");
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Periods by it's ID
   *
   * @param {string} id ID of the Periods
   * @return {object} Periods with the corresponding ID
   */
  async getById(periodsId) {
    try {
      const res = await axios.get("/Periods/" + periodsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Periods
   *
   * @param {object} periods Object with the creation data
   * @return {object} Newly created Periods
   */
  async create(periods) {
    let reqBody = { ...periods };
    try {
      const res = await axios.post("/Periods", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Deletes Periods by ID
   *
   * @param {string} periodsId ID of the Periods to be deleted
   * @return {string} ID of the deleted Periods
   */
  async delete(id) {
    try {
      await axios.delete(`/payment?id=${id}`);
      return {
        ok: true,
        data: {
          id: id,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: id,
        },
      };
    }
  },

  async update(id, body) {
    try {
      await axios.patch(`/payment?id=${id}`, { ...body });
      return {
        ok: true,
        data: {
          id: id,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: id,
        },
      };
    }
  },

  async addManualMembership(userId, periods) {
    let reqBody = { ...periods };
    try {
      const res = await axios.post(`/payment/no-pay/${userId}`, reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },
};

export default Periods;
