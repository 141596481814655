import React from "react";
import { CustomDialog } from "lib";
import Company from "pages/Profile/Company";
import styles from "./CompanyModal.module.scss";
const CompanyModal = ({ open, setOpen, userDetails }) => {
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={`Companii ${userDetails.firstName} ${userDetails.lastName}`}
      buttonClose={"Înapoi"}
      buttonFinish={"Finalizează"}
      width={"1000px"}
      maxWidth={"1000px"}
    >
      <div>
        {userDetails.companies?.length > 0 ? (
          <div className={styles.companyContainer}>
            {userDetails.companies.map((company) => (
              <React.Fragment key={company.id}>
                <Company company={company} withButtons={false} />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div>Nu sunt companii disponibile.</div>
        )}
      </div>
    </CustomDialog>
  );
};

export default CompanyModal;
