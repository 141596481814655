import React, { useState } from "react";
import styles from "./Finance.module.scss";
import { Helmet } from "react-helmet";
import { PageLayout, CustomTable } from "lib";
import { Typography } from "@mui/material";

import dayjs from "dayjs";
import { FinancesAPI } from "api_inbusiness";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import FilterFinance from "./FilterFinance";
import { toast } from "react-toastify";

const Finance = () => {
  const [financeData, setFinanceData] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const thisMonthDate = new Date();

  const start = new Date(
    thisMonthDate.getFullYear(),
    thisMonthDate.getMonth(),
    1
  );

  const end = new Date(
    thisMonthDate.getFullYear(),
    thisMonthDate.getMonth() + 1,
    0
  );

  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState({
    start: start,
    end: end,
  });
  const [isFilter, setIsFilter] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const labels = [
    {
      id: "index",
      label: "Nr. Crt.",
      minWidth: 10,
    },
    {
      id: "userName",
      label: "Utilizator",
      minWidth: 10,
    },
    {
      id: "emailAddress",
      label: "Email",
      minWidth: 10,
    },

    {
      id: "start",
      label: "Data de început",
      minWidth: 10,
    },
    {
      id: "end",
      label: "Data de sfârșit",
      minWidth: 10,
    },
    {
      id: "notes",
      label: "Observații",
      minWidth: 10,
    },
    {
      id: "status",
      label: "Status",
      minWidth: 10,
    },
    {
      id: "amount",
      label: "Suma",
      minWidth: 10,
    },
    {
      id: "invoice",
      label: "Factură",
      minWidth: 10,
    },
  ];

  const donwloadInvoice = (invoice, userName) => {
    FinancesAPI.getInvoice(invoice).then((res) => {
      if (res.ok) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "file/pdf" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", userName + ".pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Factura a fost descărcată cu succes!");
      } else {
        toast.error("A intervenit o eroare la descărcarea facturii!");
      }
    });
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "userName") {
      return (
        <Typography variant="tableContent">
          {row.user?.firstName + " " + row.user?.lastName}
        </Typography>
      );
    }

    if (column.id === "emailAddress") {
      return (
        <Typography variant="tableContent"> {row.user?.email} </Typography>
      );
    }
    if (column.id === "start") {
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD/MM/YYYY")}
        </Typography>
      );
    }

    if (column.id === "end") {
      return (
        <Typography variant="tableContent">
          {dayjs(value).format("DD/MM/YYYY")}
        </Typography>
      );
    }

    if (column.id === "invoice") {
      const userName = row.user.firstName + row.user.lastName + "Factura";
      return row.invoice !== null ? (
        <DownloadIcon
          style={{ cursor: "pointer" }}
          onClick={() => donwloadInvoice(row.invoice, userName)}
        />
      ) : (
        <div></div>
      );
    }

    return <Typography variant="tableContent">{value}</Typography>;
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Finanțe</title>
      </Helmet>
      <PageLayout
        title="Finanțe"
        actionArea={
          <>
            <PrimaryButton
              startIcon={<FilterListIcon />}
              color="primary"
              onClick={() => setOpenFilter(true)}
            >
              Filtrare
            </PrimaryButton>
          </>
        }
      >
        <div className={styles.table}>
          <CustomTable
            labels={labels}
            loading={loading}
            setLoading={setLoading}
            cellModifier={cellModifier}
            tableData={financeData}
            withPagination={true}
            setterFunction={setFinanceData}
            triggerRefetch={triggerRefetch}
            getterFunction={FinancesAPI.get}
            additionalId={filterData}
          />
        </div>
      </PageLayout>
      <FilterFinance
        open={openFilter}
        setOpen={setOpenFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        setTriggerRefetch={setTriggerRefetch}
      />
    </>
  );
};

export default Finance;
