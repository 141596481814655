import React, { useEffect, useMemo, useState } from "react";
import { LoadingSpinner, PageLayout } from "lib";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { ThumbUp } from "@mui/icons-material";
import styles from "./ProposedEvents.module.scss";
import { ProposedAPI } from "api_inbusiness";
import ProposedEvent from "./ProposedEvent";
import AddProposedEvent from "./AddProposedEvent";
import { Helmet } from "react-helmet";

const ProposedEvents = () => {
  const [proposedEvents, setProposedEvents] = useState([]);
  const [isSorted, setIsSorted] = useState("likes");
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [dataForEdit, setDataForEdit] = useState(null);

  const sortedEvents = useMemo(() => {
    const eventsCopy = [...proposedEvents];

    const order = isSorted === "likes" ? -1 : 1;

    function cmpLikes(a, b) {
      if (a.nrLike < b.nrLike) return order;
      else return -order;
    }
    eventsCopy.sort(cmpLikes);

    return eventsCopy;
  }, [proposedEvents, isSorted]);

  const getInitialEvents = () => {
    setLoading(true);
    ProposedAPI.get().then((res) => {
      if (res.ok) {
        setProposedEvents(res.data);
      } else {
        toast.error("A intervenit o eroare la preluarea datelor");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getInitialEvents();
  }, []);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {"Evenimente Propuse"}</title>
      </Helmet>
      <PageLayout
        title="Evenimente Propuse"
        subTitle="Propune o idee"
        backArrow={true}
        actionArea={
          <div className={styles.actionAreaButtons}>
            <PrimaryButton
              startIcon={<AddIcon />}
              onClick={() => setOpenAdd(true)}
            >
              Propune un eveniment
            </PrimaryButton>
            <PrimaryButton
              startIcon={
                <ThumbUp
                  style={{
                    transform: `rotate(${
                      isSorted === "likes" ? "0" : "180"
                    }deg)`,
                    transition: "transform .2s ease",
                  }}
                />
              }
              onClick={() =>
                setIsSorted(isSorted === "likes" ? "dislikes" : "likes")
              }
            >
              Sortează
            </PrimaryButton>
          </div>
        }
      >
        <div className={styles.row}>
          {loading ? (
            <LoadingSpinner margin="5rem auto" loading={loading} />
          ) : (
            sortedEvents.map((event) => (
              <ProposedEvent
                key={event.id}
                event={event}
                setDataForEdit={setDataForEdit}
                setOpenEdit={setOpenAdd}
                getInitialEvents={getInitialEvents}
              />
            ))
          )}
        </div>

        <AddProposedEvent
          open={openAdd}
          setOpen={setOpenAdd}
          getRefreshedEvents={getInitialEvents}
          dataForEdit={dataForEdit}
          setDataForEdit={setDataForEdit}
        />
      </PageLayout>
    </>
  );
};

export default ProposedEvents;
