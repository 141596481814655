import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PageLayout } from "lib";
import { Helmet } from "react-helmet";
import styles from "./Education.module.scss";
import { LoadingSpinner } from "lib";

const VisualizeYoutubeVideo = () => {
  const { state } = useLocation();
  const [loadingYoutube, setLoadingYoutube] = useState(true);

  const getYoutubeEmbededCode = () => {
    const ytLink = state.youtube;
    const videoId = ytLink.split("/").pop();
    return videoId;
  };

  const isValidYoutubeLink = (link) => {
    const youtubeRegex = /^https?:\/\/youtu\.be\/[a-zA-Z0-9_-]{11}$/;
    return youtubeRegex.test(link);
  };

  const handleIframeLoad = () => {
    setLoadingYoutube(false);
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {state.name} </title>
      </Helmet>
      <PageLayout title={state.name} backArrow={true}>
        <div className={styles.mainPageContainer}>
          {isValidYoutubeLink(state.youtube) ? (
            <div className={styles.youtubePlayer}>
              {loadingYoutube && (
                <LoadingSpinner margin={"0 auto"} loading={loadingYoutube} />
              )}
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${getYoutubeEmbededCode()}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          ) : (
            <div className={styles.invalidYoutubeLink}>
              Link-ul introdus este gresit!
            </div>
          )}

          {(state.link || state.description) && (
            <div className={styles.descriptionContainer}>
              {state.link && (
                <p>
                  Link:{" "}
                  <a
                    href={`https://${state.link}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {state.link}
                  </a>
                </p>
              )}
              {state.description && (
                <div>
                  <p>Descriere:</p>
                  <div
                    dangerouslySetInnerHTML={{ __html: state.description }}
                  ></div>
                </div>
              )}
            </div>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default VisualizeYoutubeVideo;
