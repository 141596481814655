import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes, { RouteWithSubRoutes } from "./routes";
import { useUser } from "./hooks/useUser";

import { Content, Layout, Login } from "./pages";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import Event from "pages/Event/Event";

function App() {
  const { user, loadingUser } = useUser();

  useEffect(() => {
    const handleNewVersionAvailable = (event) => {
      if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
        // Show notification to the user
        alert(
          "O nouă versiune a aplicației este disponibilă. Apăsați aici pentru a actualiza!."
        );
      }
    };

    if ("serviceWorker" in navigator) {
      // Check if service worker is supported
      // Listen for messages from service worker
      navigator.serviceWorker.addEventListener(
        "message",
        handleNewVersionAvailable
      );

      return () => {
        navigator.serviceWorker.removeEventListener(
          "message",
          handleNewVersionAvailable
        );
      };
    } else {
      console.warn("Service Worker is not supported in this browser.");
    }
  }, []);

  return loadingUser ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <React.Fragment>
      {user.id ? (
        <React.Fragment>
          <Helmet>
            <title>InBUSINESS CRM</title>
          </Helmet>

          <Layout />
          <Content>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </Content>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Switch>
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route
              path="/reset_password/:token"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              path="/events/:id"
              render={(props) => <Event {...props} />}
            ></Route>
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default App;
