import React from "react";
import { CustomDialog } from "lib";
import { SimpleDatePicker } from "lib";

import styles from "./Finance.module.scss";

const FilterFinance = ({
  open,
  setOpen,
  setTriggerRefetch,
  filterData,
  setFilterData = () => {},
}) => {
  const handleChange = (e) => {
    setFilterData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Filtrează data de creare între două date"}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={() => {
        setTriggerRefetch((prev) => !prev);
        setOpen(false);
      }}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
    >
      <div className={styles.filterData}>
        <div>
          <SimpleDatePicker
            date={filterData.start}
            setDate={handleChange}
            label="Alege data de început"
            name="start"
          />
        </div>
        <div>
          <SimpleDatePicker
            date={filterData.end}
            setDate={handleChange}
            label="Alege data de sfârșit"
            name="end"
          />
        </div>{" "}
      </div>
    </CustomDialog>
  );
};

export default FilterFinance;
