import { LoadingSpinner, PageLayout } from "lib";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import AddNewsModal from "./AddNewsModal";
import NewsItem from "./NewsItem";
import { NewsAPI } from "api_inbusiness";
import styles from "./News.module.scss";
import { useUser } from "hooks/useUser";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const News = () => {
  const { permissionsData } = useUser();
  const [openAddNews, setOpenAddNews] = useState(false);
  const [dataForEdit, setDataForEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddNewsClose = () => setOpenAddNews(false);
  const handleAddNewsOpen = () => setOpenAddNews(true);
  const [newsData, setNewsData] = useState([]);

  const handleAddNewsEdit = (data) => {
    setDataForEdit(data);
    handleAddNewsOpen();
  };

  const getNews = async () => {
    setLoading(true);
    try {
      const res = await NewsAPI.get();

      if (res.ok) {
        setNewsData(res.data);
      }
    } catch (error) {
      toast.error("A intervenit o eroare!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Noutăți</title>
      </Helmet>
      <PageLayout
        title="Noutăți"
        subTitle="În această secțiune puteți vedea noile anunțuri."
        backArrow={true}
        actionArea={
          permissionsData.seeAdminButtons && (
            <PrimaryButton
              startIcon={<AddIcon />}
              onClick={() => {
                setDataForEdit(null);
                handleAddNewsOpen();
              }}
            >
              Adaugă
            </PrimaryButton>
          )
        }
      >
        {loading ? (
          <LoadingSpinner loading={loading} margin="5rem auto" />
        ) : (
          <div className={styles.newsWrapper}>
            {newsData.map((news) => (
              <NewsItem
                key={news.id}
                news={news}
                getNews={getNews}
                handleAddNewsEdit={handleAddNewsEdit}
              />
            ))}
          </div>
        )}

        <AddNewsModal
          open={openAddNews}
          setOpen={setOpenAddNews}
          handleClose={handleAddNewsClose}
          dataForEdit={dataForEdit}
          getNews={getNews}
        />
      </PageLayout>
    </>
  );
};

export default News;
