import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { TextField, Tooltip } from "lib";
import { CustomDialog } from "lib";

import { toast } from "react-toastify";
import styles from "./Education.module.scss";
import { EducationAPI } from "api_inbusiness";

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
//html editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import InfoIcon from "@mui/icons-material/Info";

const AddEducationResource = ({
  open,
  setOpen,
  rowState,
  getResources,
  id,
  triggerRefetch,
  setTriggerRefetch = () => {},
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [hasModified, setHasModified] = useState(false);
  const [loading, setLoading] = useState(false);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [initialHTML, setInitialHTML] = React.useState(``);

  const INITIAL_FORM_STATE = {
    name: rowState?.name ? rowState.name : "",
    link: rowState?.link ? rowState.link : "",
    youtube: rowState?.youtube ? rowState.youtube : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    name: yup.string().trim().required(t("Categoria este obligatorie!")),
    // link: yup.string().trim().required(t("Link-ul este obligatorie!")),
    // youtube: yup.string().trim().required(t("Codul este obligatorie!")),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmitResource = (values) => {
    const objForResource = { ...values };
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    objForResource.categoryId = id;
    objForResource.description = htmlContent;

    setLoading(true);
    rowState
      ? EducationAPI.updateResource(rowState.id, objForResource).then((res) => {
          if (res.ok === true) {
            toast.success("Resursa a fost editată cu succes!");
            getResources();
            setOpen(false);
          } else {
            toast.error("A intervenit o eroare!");
          }
        })
      : EducationAPI.create(objForResource).then((res) => {
          if (res.ok === true) {
            toast.success("Resursa a fost adăugată cu succes!");
            getResources();
            setOpen(false);
          } else {
            toast.error("A intervenit o eroare");
          }
        });
  };

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  useEffect(() => {
    setEditorState(htmlToDraftBlocks(initialHTML));
  }, [initialHTML]);

  useEffect(() => {
    if (rowState === null) return;
    if (rowState.description !== null) {
      let html = rowState.description;
      setInitialHTML(html);
    } else setInitialHTML("");
  }, [rowState]);

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={rowState ? t("Editează resursa") : t("Adaugă resursa")}
      buttonClose={t("Înapoi")}
      buttonFinish={t("Finalizează")}
      buttonFinishDisabled={rowState ? !hasModified : false}
      onClickButtonFinish={handleSubmit}
      onClickButtonClose={() => {
        setOpen(false);
        setHasModified(false);
      }}
      width="580px"
      // buttonFinishLoading={loading}
      // buttonCloseDisabled={loading}
    >
      <Formik
        innerRef={formRef}
        // enableReinitialize={true}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          handleSubmitResource(values);
        }}
      >
        <Form
          onChange={(e) => {
            const { name, value } = e.target;

            if (rowState && INITIAL_FORM_STATE[name] === value)
              setHasModified(false);
            else if (rowState && INITIAL_FORM_STATE[name] !== value)
              setHasModified(true);
          }}
        >
          <div className={styles.addResource}>
            <TextField name="name" label={"Denumire"} size="medium" />
            <TextField name="link" label={"Link"} size="medium" />

            <div className={styles.youtubeLinkContainer}>
              <TextField name="youtube" label={"Cod Youtube"} size="medium" />
              <Tooltip
                position="right"
                textTip={
                  "Pentru a putea viziona videoclipul pe platformă fără probleme, apăsați butonul de distribuire de pe youtube apoi copiați link-ul oferit de la finalul modalului! Un exemplu de link corect : https://youtu.be/45PvLSyHKRg "
                }
                style={{
                  border: "1px solid",
                  borderColor: "#ff4b55",
                  color: "#ff4b55",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    color: "#ff4b55",
                    marginTop: "0.5rem",
                  }}
                >
                  <InfoIcon
                    sx={{
                      fontSize: "22px",
                    }}
                  />
                </div>
              </Tooltip>
            </div>

            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-class"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
              }}
              editorStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
                padding: "0px 8px",
                overflowY: "auto",
                maxHeight: "30vh",
                minHeight: "30vh",
              }}
              preserveSelectionOnBlur={true}
            />
          </div>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddEducationResource;
