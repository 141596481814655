import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Typography, Divider, Button } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useTranslation } from "react-i18next";

function DetailsComponet({
  title,
  tableData,
  cellModifier,
  showAdd,
  onAdd,
  showEdit,
  onEdit,
  showBlock,
  onBlock,
  showUnblock,
  onUnblock,
  showTrip,
  showClient,
  onShowClient,
  onShowTrip,
  showFinished,
  onFinished,
  addHours,
  onAddHours,
  render,
}) {
  const { t } = useTranslation();
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (tableData) {
      setKeys(Object.keys(tableData));
    }
  }, [tableData]);

  return (
    <Box
      sx={{
        width: "100%",
        background: "#FFFFFF",
        borderRadius: "1rem",
        minWidth: "25.31rem",
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        sx={{ paddingTop: "1rem", marginBottom: "1rem", marginLeft: "1rem" }}
      >
        {title}
      </Typography>

      <Divider sx={{ marginBottom: "1rem" }} />

      {keys.map((key) => {
        const index = render.findIndex((e) => e.key === key);
        return (
          <Box key={key}>
            {index !== -1 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                  }}
                >
                  <Typography variant="detailsText">
                    {render[index]?.label}
                  </Typography>

                  {render[index].cellModifier ? (
                    render[index].cellModifier(tableData[key], tableData)
                  ) : (
                    <Typography variant="detailsText">
                      {tableData[key]}
                    </Typography>
                  )}
                </Box>

                <Divider sx={{ marginTop: "1rem", marginBottom: "1rem" }} />
              </>
            ) : (
              <></>
            )}
          </Box>
        );
      })}

      {showAdd && (
        <Button
          startIcon={<AddIcon />}
          onClick={onAdd}
          sx={{
            height: "2.25rem",
            color: "#5664D2",
            border: "1px solid rgba(86, 100, 210, 0.5)",
            borderRadius: "4rem",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("Add")}
        </Button>
      )}

      {showEdit && (
        <Button
          startIcon={<ModeEditIcon />}
          onClick={onEdit}
          sx={{
            height: "2.25rem",
            color: "#5664D2",
            border: "1px solid rgba(86, 100, 210, 0.5)",
            borderRadius: "4rem",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("Edit")}
        </Button>
      )}

      {showBlock && (
        <Button
          startIcon={<LockIcon />}
          onClick={onBlock}
          sx={{
            height: "2.25rem",
            backgroundColor: "#E91E63",
            boxShadow:
              "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
            borderRadius: "4rem",
            color: "#FFFFFF",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("Block")}
        </Button>
      )}
      {showUnblock && (
        <Button
          startIcon={<LockIcon />}
          onClick={onUnblock}
          sx={{
            height: "2.25rem",
            backgroundColor: "#E91E63",
            boxShadow:
              "0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
            borderRadius: "4rem",
            color: "#FFFFFF",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("Unblock")}
        </Button>
      )}
      {showTrip && (
        <Button
          endIcon={<ArrowForwardIosIcon />}
          onClick={onShowTrip}
          sx={{
            height: "2.25rem",
            color: "#5664D2",
            border: "1px solid rgba(86, 100, 210, 0.5)",
            borderRadius: "4rem",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("See trip")}
        </Button>
      )}
      {showClient && (
        <Button
          endIcon={<ArrowForwardIosIcon />}
          onClick={onShowClient}
          sx={{
            height: "2.25rem",
            color: "#5664D2",
            border: "1px solid rgba(86, 100, 210, 0.5)",
            borderRadius: "4rem",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("See client")}
        </Button>
      )}
      {showFinished && (
        <Button
          endIcon={<DoneAllIcon />}
          onClick={onFinished}
          sx={{
            height: "2.25rem",
            color: "#5664D2",
            border: "1px solid rgba(86, 100, 210, 0.5)",
            borderRadius: "4rem",
            marginBottom: "0.8rem",
            marginLeft: "0.5rem",
          }}
        >
          {t("Finished Trip")}
        </Button>
      )}
    </Box>
  );
}

DetailsComponet.propTypes = {
  title: PropTypes.string,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showBlock: PropTypes.bool,
  showFinished: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onBlock: PropTypes.func,
  onShowClient: PropTypes.func,
  onShowTrip: PropTypes.func,
  onFinished: PropTypes.func,
  cellModifier: PropTypes.func,
  tableData: PropTypes.object,
};

DetailsComponet.defaultProps = {
  title: "Dummy",
  cellModifier: () => {},
  showAdd: false,
  showEdit: false,
  showBlock: false,
  showFinished: false,
  onAdd: () => {},
  onEdit: () => {},
  onBlock: () => {},
  onShowClient: () => {},
  onShowTrip: () => {},
  onFinished: () => {},
  tableData: {},
};

export default DetailsComponet;
