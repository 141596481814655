import React, { useState } from "react";
import { CustomDialog } from "lib";
import styles from "./SeeEventsModal.module.scss";
import { Divider } from "@mui/material";
import { EventsAPI, ParticipantsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { getUserType, getStatusColor } from "utils/functions";
import { LoadingSpinner } from "lib";

const Row = ({ row }) => {
  return (
    <div
      className={styles.rowMainWrapper}
      style={{
        backgroundColor:
          row.event.participants[0] &&
          row.event.participants[0].isAttending === true
            ? "#dfefd8"
            : "#f2dedd",
      }}
    >
      <div className={styles.leftSide}>
        <div className={styles.upperSide}>
          {dayjs(row.event.date).format("DD/MM/YYYY")}
        </div>
        <div
          style={{ backgroundColor: getStatusColor(row.type) }}
          className={styles.downSide}
        >
          {getUserType(row.type)}
        </div>
      </div>
      <div className={styles.rightSide}>{row.event.title}</div>
    </div>
  );
};

const SeeEventsModal = ({ open, setOpen, rowDetails }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = React.useState([]);
  const [participationsOnEvents, setParticipationsOnEvents] = React.useState(
    {}
  );
  const getUsersEvents = () => {
    setLoading(true);
    EventsAPI.getEventsByUserId(rowDetails.id).then((res) => {
      if (res.ok === true) {
        setLoading(false);
        setEvents(res.data);
      } else {
        toast.error("Eroare la încarcareea datelor!");
      }
    });
  };

  const getUserPresence = () => {
    ParticipantsAPI.getUserParticipantions(rowDetails.id).then((res) => {
      setParticipationsOnEvents(res.data);
    });
  };

  React.useEffect(() => {
    if (open) {
      getUsersEvents();
      getUserPresence();
    }
  }, [open]);

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={`Evenimente ${rowDetails.firstName} ${rowDetails.lastName}`}
      buttonClose={"Înapoi"}
      buttonFinish={"Finalizează"}
    >
      <div className={styles.modal}>
        {loading ? (
          <LoadingSpinner loading={loading} margin={"0 auto"} />
        ) : (
          <div className={styles.scrollable}>
            {events?.map((event) => (
              <Row key={event.event.id} row={event} />
            ))}
          </div>
        )}

        <div className={styles.static}>
          <div className={styles.enrollDate}>
            <div>Data inscriere</div>
            <div>16.03.2016</div>
          </div>
          <div className={styles.divder}></div>
          <div className={styles.eventsParticipation}>
            <div> Participare evenimente</div>
            <div>
              {participationsOnEvents.countPresence} /{" "}
              {participationsOnEvents.countEvents}
            </div>
          </div>
          <div className={styles.divder}></div>

          <div className={styles.eventsAsMember}>
            <div>Participare ca membru</div>
            <div>
              {participationsOnEvents.memberPresence} /
              {participationsOnEvents.countEvents}
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};

export default SeeEventsModal;
