import { CustomDialog, TextField, Select } from "lib";
import React, { useRef } from "react";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { MenuItem } from "@mui/material";
import styles from "./Subscriptions.module.scss";
import { SubscriptionsAPI } from "api_inbusiness";

const EditSubscriptionsDialog = ({
  open,
  setOpen,
  setTriggerRefetch,
  rowState,
}) => {
  const formRef = useRef();

  const INITIAL_FORM_STATE = {
    price: rowState?.price ? rowState.price : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    price: yup
      .string()
      .typeError("Pretul nu este valid!")
      .trim()
      .required("Pretul este obligatoriu!"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmitFunc = (values) => {
    SubscriptionsAPI.updateSubscription(rowState.id, {
      price: Number(values.price),
    }).then((res) => {
      if (res.ok === true) {
        toast.success("Suscriptia a fost modificata cu succes");
        setTriggerRefetch((prev) => !prev);
        setOpen(false);
      } else {
        toast.error("A intervenit o eroare la modificarea subscriptiei");
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Editeaza subscriptia"}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={handleSubmit}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        <Form className={styles.formStyles}>
          <TextField name="price" label="Pret*" size="medium" />
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default EditSubscriptionsDialog;
