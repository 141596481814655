import React, { useState } from 'react';
import { PageLayout } from 'lib';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import Search from 'lib/components/Search/Search';
import { CustomTable } from 'lib';

const TestLayout = () => {
    const { t } = useTranslation();
    const navigate = useHistory();

    const dummyData = [
        {
            name: 'Florinel',
            email: 'sefulbanilorh@yahoo.com',
            role: 'Sef la bani normal',
            brands: ['Dacia', 'Renault', 'Nissan'],
            date: Date.now(),
            id: 1,
        },
        {
            name: 'Constantin',
            email: 'cobranebuna2022@cobrinia.com',
            role: 'Vipera',
            brands: ['Dacia', 'Renault'],
            date: Date.now(),
            id: 2,
        },
        {
            name: 'Alin',
            email: 'domnullabadeurs@raisis.com',
            role: 'Sensei',
            brands: ['Dacia'],
            date: Date.now(),
            id: 3,
        },
        {
            name: 'Codrin',
            email: 'bossdeboss@tesimttupamiros.com',
            role: 'Senpai',
            brands: ['Dacia', 'Renault'],
            date: Date.now(),
            id: 4,
        },
        {
            name: 'Arcadie',
            email: 'maajutatlanavbar@:P.com',
            role: 'Tate al RaisisSoftware',
            brands: [],
            date: Date.now(),
            id: 5,
        },
        {
            name: 'Codrin',
            email: 'bossdeboss@tesimttupamiros.com',
            role: 'Senpai',
            brands: [],
            date: Date.now(),
            id: 6,
        },
        {
            name: 'Arcadie',
            email: 'maajutatlanavbar@:D.com',
            role: 'Tate al RaisisSoftware',
            brands: [],
            date: Date.now(),
            id: 7,
        },
    ];
    const [users, setUsers] = useState(dummyData);

    const labels = [
        {
            id: 'name',
            label: t('Name'),
            minWidth: 10,
        },
        {
            id: 'email',
            label: 'Email',
            minWidth: 10,
        },
        {
            id: 'role',
            label: t('Role'),
            minWidth: 10,
        },
        {
            id: 'brands',
            label: t('Brands'),
            minWidth: 10,
        },
        {
            id: 'date',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'actions',
            minWidth: 10,
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'brands') {
            return (
                <div style={{ display: 'flex' }}>
                    {value.map((item, index) => {
                        return <Typography variant="tableContent">{item}</Typography>;
                    })}
                </div>
            );
        }
        if (column.id === 'date') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'actions') {
            return (
                <div className="actions-class" onClick={() => navigate.push(`/users/${row.id}`)}>
                    <ChevronRightIcon />
                </div>
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    return (
        <PageLayout
            title={t('Users')}
            backArrow={true}
            subTitle={'arcadie4'}
            actionArea={
                <>
                    <Search nameToFind={t('user')} />
                    <PrimaryButton startIcon={<FilterListIcon />} color="light" width="240px">
                        {t('FILTER USERS')}
                    </PrimaryButton>
                    <PrimaryButton startIcon={<AddIcon />} color="primary" width="240px">
                        {t('ADD USER')}
                    </PrimaryButton>
                </>
            }
        >
            <CustomTable
                title={t('Users')}
                labels={labels}
                loading={false}
                tableData={users}
                cellModifier={cellModifier}
            />
        </PageLayout>
    );
};

export default TestLayout;
