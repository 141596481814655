import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { PoliticsAPI } from "api_inbusiness";
import styles from "./TermsAndConditions.module.scss";
import DOMPurify from "dompurify";
import { LoadingSpinner } from "lib";
import { PageLayout } from "lib";

const TermsAndConditionsItem = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PoliticsAPI.getById(id).then((res) => {
      if (res.ok) {
        setData(res.data);
      }
      setLoading(false);
    });
  }, [id]);

  return loading ? (
    <LoadingSpinner loading={loading} margin="20px auto" />
  ) : (
    <PageLayout title={data.title} backArrow={true}>
      <div className={styles.container}>
        <h2 className={styles.title}>{data.title}</h2>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data.description),
          }}
        />
      </div>
    </PageLayout>
  );
};

export default TermsAndConditionsItem;
