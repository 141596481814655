import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";

import { MenuItem, TextField } from "@mui/material";
import styles from "./FilterEvents.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";

const FilterEvents = ({
  open,
  setOpen,
  filterData,
  setFilterData,
  setTriggerFilter,
  triggerFilter,
  triggerRefetch,
  setTriggerRefetch,
  setIsFilter,
  setData,
  setLoading,
  resetSearch,
  resetFilter,
  isFilter,
}) => {
  //TO DO: change this to an empty array
  const [locations, setLocations] = useState([
    { id: 0, name: "Suceava" },
    { id: 1, name: "Botosani" },
  ]);

  const getLocations = () => {
    //TO DO: request locations
  };

  useEffect(() => {
    getLocations();
  }, []);

  const handleChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRemoveFilters = () => {
    resetFilter();
    setLoading(true);
    setData([]);
    setTriggerRefetch(!triggerRefetch);
    setOpen(false);
  };

  const handleFilter = () => {
    resetSearch();
    setLoading(true);
    setData([]);
    setIsFilter(true);
    setTriggerFilter(!triggerFilter);
    setOpen(false);
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title="Filtrează evenimentele"
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      buttonUtility="ȘTERGE FILTRE"
      onClickButtonFinish={handleFilter}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
      onClickButtonUtility={handleRemoveFilters}
      buttonUtilityDisabled={!isFilter}
      buttonFinishDisabled={Object.values(filterData).every(
        (x) => x === null || x === ""
      )}
    >
      <div className={styles.container}>
        <div className={styles.rowContainer}>
          <TextField
            name="locationId"
            label={`Locație*`}
            size="medium"
            fullWidth
            select
            sx={{
              fieldset: {
                borderRadius: "10px",
              },
            }}
            id="outlined-required"
            value={filterData.locationId}
            onChange={handleChange}
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id} sx>
                {location.name}
              </MenuItem>
            ))}
          </TextField>
          <DeleteIcon
            fontSize="large"
            className={`${styles.delete} ${
              filterData.locationId !== "" && styles.deleteActive
            }`}
            onClick={() =>
              setFilterData({
                ...filterData,
                locationId: "",
              })
            }
          />
        </div>
      </div>
    </CustomDialog>
  );
};

export default FilterEvents;
