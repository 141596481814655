import React from "react";
import styles from "./Subscription.module.scss";
import { Divider, Typography } from "@mui/material";
import { ActionButton } from "lib/components/Buttons/buttons";
import CheckIcon from "@mui/icons-material/Check";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { PaymentsAPI } from "api_inbusiness";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";
import JuidicalPersonPayment from "./JuidicalPersonPayment";
import ConfirmModal from "lib/components/Modals/ConfirmModal";

const recurrenceDictionay = {
  MONTHLY: "LUNAR",
  YEARLY: "ANUAL",
};

const Subscription = (props) => {
  const { data, benefits } = props;
  const { getUpdatedUser, user } = useUser();
  const navigate = useHistory();
  const [agreePay, setAgreePay] = React.useState(false);
  const [pjPayment, setPjPayment] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [openUnsusbcribe, setOpenUnsubscribe] = React.useState(false);

  const handleNewPayment = (planId) => {
    PaymentsAPI.create(planId).then((res) => {
      if (res.ok === true) {
        window.open(res.data.link, "_blank");
        PaymentsAPI.getPaymentStatus(res.data.estimateId).then((res) => {
          if (res.ok) {
            toast.success("Subscripția a fost achiziționată cu succes!");
            getUpdatedUser();
            navigate.push("/");
          } else {
            toast.error("A intervenit o eroare la plata subscripției!");
          }
        });
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  const cancelSubscription = () => {
    PaymentsAPI.cancelSubscription().then((res) => {
      if (res.ok === true) {
        toast.success("Subscripția a fost anulată cu succes!");
        getUpdatedUser();
        navigate.push("/");
      } else {
        toast.error("A intervenit o eroare la anularea subscripției!");
      }
    });
  };

  return (
    <div className={styles.mainContainer}>
      <>
        <div>
          <Typography variant="h6">
            {Object.keys(data).length === 0 ? (
              <div>
                {user.membership?.plan === null
                  ? "Abonament manual"
                  : "Abonament" +
                    " " +
                    recurrenceDictionay[user.subscription?.plan?.recurrence]}
              </div>
            ) : (
              data.name
            )}
          </Typography>
        </div>
        {Object.keys(data).length === 0 ? (
          <div className={styles.priceInfo}>
            Expiră in {user.membership.daysLeft} zile
          </div>
        ) : (
          <div className={styles.priceInfo}>Pentru doar {data.price} lei</div>
        )}
      </>

      <div className={styles.benefitsInfo}>
        {benefits.map((bnf) => (
          <div key={bnf.id}>
            <CheckIcon color="success" fontSize="small" />
            <Typography variant="clientListMember">{bnf.text1}</Typography>
            {bnf.text2 && (
              <ul className={styles.listOfBenefits}>
                <li>
                  <AddTaskIcon
                    className={styles.checkedIcon}
                    color="success"
                    fontSize="small"
                  />
                  <Typography variant="clientListMember">
                    {bnf.text2}
                  </Typography>
                </li>
                {bnf.text3 && (
                  <li>
                    <AddTaskIcon
                      className={styles.checkedIcon}
                      color="success"
                      fontSize="small"
                    />
                    <Typography variant="clientListMember">
                      {bnf.text3}
                    </Typography>
                  </li>
                )}
              </ul>
            )}

            <Divider />
          </div>
        ))}
      </div>

      {Object.keys(data).length !== 0 && (
        <div>
          <label style={{ display: "flex", gap: "10px" }}>
            <input
              value={agreePay}
              type="checkbox"
              checked={agreePay}
              onChange={() => {
                setAgreePay(!agreePay);
              }}
            />
            <Typography variant="clientListMember">
              Am citit și sunt de acord cu{" "}
              <a
                target="_blank"
                href="https://inbusiness.club/termeni-si-conditii"
                rel="noopener noreferrer"
              >
                Termenii și condițiile
              </a>{" "}
              acestui website <span>*</span>
            </Typography>
          </label>
        </div>
      )}

      {/* {Object.keys(data).length !== 0 && (
        <div>
          <label style={{ display: "flex", gap: "10px" }}>
            <input
              value={pjPayment}
              type="checkbox"
              checked={pjPayment}
              onChange={() => {
                setPjPayment(!pjPayment);
              }}
            />
            <Typography variant="clientListMember">
              Doresc facturare pe persoana juridică
            </Typography>
          </label>
        </div>
      )} */}

      {Object.keys(data).length !== 0 && (
        <div>
          <ActionButton
            onClick={() => {
              // if (pjPayment === true)
              setOpenPayment(true);
              // else {
              //   handleNewPayment(data.id);
              // }
            }}
            color={"blue"}
            disabled={!agreePay}
          >
            Vreau să devin membru
          </ActionButton>
        </div>
      )}

      {Object.keys(data).length === 0 && (
        <ActionButton onClick={() => setOpenUnsubscribe(true)} color={"red"}>
          Anulează abonament
        </ActionButton>
      )}

      <ConfirmModal
        open={openUnsusbcribe}
        setOpen={setOpenUnsubscribe}
        text={
          "Sunteți sigur că doriți să vă dezabonați? Această acțiune este ireversibilă, fiind necesară achiziționarea altui abonament!"
        }
        onClickButtonYes={() => {
          cancelSubscription();
        }}
      />

      <JuidicalPersonPayment
        open={openPayment}
        setOpen={setOpenPayment}
        planData={data.id}
      />
    </div>
  );
};

export default Subscription;
