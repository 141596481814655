import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";
import {
  UsersAPI,
  PeriodsAPI,
  ParticipantsAPI,
  EventsAPI,
} from "api_inbusiness";
import { getUserType, getStatusColor } from "utils/functions";
import { EditClientButton } from "lib/components/Buttons/buttons";
import styles from "./QrAdminPage.module.scss";
import { toast } from "react-toastify";
import AddNewPeriodForInactiveMember from "./AddNewPeriodForInactiveMember";
import { Typography } from "@mui/material";
import { useUser } from "hooks/useUser";

const CheckAttendanceEvent = ({ open, setOpen, userData, eventId }) => {
  const { permissionsData } = useUser();
  const [userInfo, setUserInfo] = useState({});
  const [addNewPeriod, setAddNewPeriod] = useState(false);
  const [noOfParticipations, setNoOfParticipations] = useState(null);

  const getUserInfo = () => {
    UsersAPI.getById(userData).then((res) => {
      if (res.ok === false) {
        setUserInfo({});
      } else {
        setUserInfo(res.data);
      }
    });
  };

  const getNoOfParticipationsAsGuest = () => {
    ParticipantsAPI.getNoOfParticipationsAsGuest(userData).then((res) =>
      setNoOfParticipations(res.data)
    );
  };

  const addUniquePayment = () => {
    const objForPayment = {};
    objForPayment.userId = userData;
    objForPayment.amount = 0;
    objForPayment.startDate = new Date();
    objForPayment.endDate = new Date();
    objForPayment.observation = "PLATA UNICĂ PER EVENIMENT";

    PeriodsAPI.create(objForPayment).then((res) => {
      if (res.ok === true) {
        toast.success(
          "Plata unică a fost înregistrată. Verificați în secția plăți!"
        );
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  useEffect(() => {
    getUserInfo();
    if (userInfo.type === "GUEST") getNoOfParticipationsAsGuest();
  }, [open, userData, userInfo.type]);

  const confirmUserPresence = () => {
    const presenceObj = {};
    presenceObj.userId = userData;
    presenceObj.eventId = eventId;
    EventsAPI.createUserPresence(presenceObj).then((res) => {
      if (res.ok === true) {
        toast.success("Prezența a fost confirmată cu succes");
        setOpen(false);
        setUserInfo({});
      } else {
        setUserInfo({});
        toast.error("Prezența acestui utilizator a fost înregistrată deja!");
        setOpen(false);
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title="Verifică prezență eveniment"
      buttonClose="Înapoi"
      buttonFinish={
        userInfo.type !== "LEADER" ||
        (noOfParticipations < 2 && userInfo.type === "GUEST")
          ? "Confirmă prezența"
          : ""
      }
      onClickButtonFinish={confirmUserPresence}
      onClickButtonClose={() => {
        setUserInfo({});
        setOpen(false);
      }}
      width="700px"
      maxWidth="1000px"
    >
      {Object.keys(userInfo).length > 0 ? (
        <div className={styles.attendanceModal}>
          <div className={styles.userStyle}>
            {userInfo.firstName} {userInfo.lastName}
          </div>
          <div
            style={{ backgroundColor: getStatusColor(userInfo.type) }}
            className={styles.userStatus}
          >
            {getUserType(userInfo.type)}
          </div>
          {(userInfo.type === "EX_MEMBER" || noOfParticipations >= 2) && (
            <div className={styles.exMemberContainer}>
              <EditClientButton onClick={() => setAddNewPeriod(true)}>
                Prelungire perioadă
              </EditClientButton>
              {/* <EditClientButton onClick={addUniquePayment}>
                Plata unică
              </EditClientButton> */}
            </div>
          )}

          {userInfo.type === "GUEST" && (
            <div className={styles.guestContainer}>
              {noOfParticipations >= 2 && (
                <EditClientButton onClick={confirmUserPresence}>
                  Invitat
                </EditClientButton>
              )}

              {noOfParticipations !== null && (
                <div>
                  <Typography variant="objectivesName">
                    A participat la {noOfParticipations} evenimente ca invitat
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div> Acest utilizator nu exista in baza de date </div>
      )}

      <AddNewPeriodForInactiveMember
        open={addNewPeriod}
        setOpen={setAddNewPeriod}
        userData={userData}
        getUserInfo={getUserInfo}
      />
    </CustomDialog>
  );
};

export default CheckAttendanceEvent;
