import React, { useEffect, useMemo, useState } from "react";
import { ProposedAPI } from "api_inbusiness";
import styles from "./ProposedEvents.module.scss";
import LikeDislike from "./LikeDislike";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ApprovedStamp from "resources/approved.png";
import { useUser } from "hooks/useUser";

const ProposedEvent = ({
  event,
  getInitialEvents,
  setDataForEdit = () => {},
  setOpenEdit = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(event.checked);
  const { permissionsData } = useUser();

  // Remove Proposed Event
  const handleRemoveEvent = () => {
    ProposedAPI.delete(event.id).then((res) => {
      if (res.ok) {
        getInitialEvents();
        toast.success("Propunerea a fost ștearsă!");
      } else {
        toast.error("Propunerea nu a putut fi ștearsă!");
      }
    });
  };

  return (
    <div
      className={`${styles.event} ${isChecked && styles.active}`}
      style={{
        position: "relative",
      }}
      key={event.id}
    >
      {isChecked && (
        <img
          style={{
            position: "absolute",
            width: "clamp(64px, 25%, 128px)",
            top: "-1rem",
            right: "-2rem",
            pointerEvents: "none",
          }}
          src={ApprovedStamp}
          alt="Approved Stamp"
        />
      )}
      <div className={styles.eventHeader}>
        <h2 className={styles.eventTitle}>{event.title}</h2>
        {permissionsData.seeAdminButtons && (
          <div className={styles.eventEdit}>
            <EditIcon
              className={styles.eventEditBtn}
              onClick={() => {
                setDataForEdit(event);
                setOpenEdit(true);
              }}
            />
            <CloseIcon
              className={styles.eventRemove}
              onClick={handleRemoveEvent}
            />
          </div>
        )}
      </div>
      <p className={styles.eventDescription}>{event.description}</p>
      <LikeDislike
        event={event}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        getRefreshedEvents={getInitialEvents}
      />
    </div>
  );
};

export default ProposedEvent;
