import React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { PasswordField } from "lib";
import { CustomDialog } from "lib";
import { passwordRegex } from "utils/regex";
import { toast } from "react-toastify";

import { UsersAPI } from "api_inbusiness";
import styles from "./Profile.module.scss";

const ChangePassword = ({ open, setOpen, userData }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  const INITIAL_FORM_STATE = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    currentPassword: yup
      .string()
      .trim()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .notOneOf(
            [yup.ref("newPassword")],
            t("Parola nouă trebuie să fie diferită de parola veche!")
          ),
      })
      .required(t("Parola curentă este obligatorie!")),
    newPassword: yup
      .string()
      .trim()
      .matches(
        passwordRegex,
        t(
          "Parola introdusă nu este validă! Parola trebuie să conțină cel puțin 8 caractere, o literă mare și un număr!"
        )
      )
      .required(t("Parola curentă este obligatorie!")),
    confirmNewPassword: yup
      .string()
      .trim()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("newPassword")], t("Parolele trebuie să coincidă!")),
      })
      .required(t("Confirmarea noii parole este obligatorie!!")),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChangePassword = ({ values }) => {
    setLoading(true);
    UsersAPI.changeUserPassword(
      userData.email,
      values.currentPassword,
      values.newPassword
    ).then((res) => {
      if (res.ok) {
        setOpen(false);
        setLoading(false);
        toast.success(t("Parola a fost resetată cu succes!"));
      } else if (res.error === "Old password doesn't match") {
        setLoading(false);
        toast.error(t("Parola curentă nu este corectă!"));
      } else {
        setLoading(false);
        toast.error(t("A intervenit o eroare!"));
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={t("Schimbați parola")}
      buttonClose={t("Înapoi")}
      buttonFinish={t("Finalizare")}
      onClickButtonFinish={handleSubmit}
      width="580px"
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          handleChangePassword({ values });
        }}
        onClickButtonClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        buttonFinishLoading={loading}
        buttonCloseDisabled={loading}
      >
        <Form>
          <div className={styles.changeWrapper}>
            <PasswordField
              name="currentPassword"
              type="password"
              label={`${t("Parola curentă")}`}
              size="medium"
            />
            <PasswordField
              name="newPassword"
              type="password"
              label={`${t("Parola nouă")}`}
              size="medium"
            />
            <PasswordField
              name="confirmNewPassword"
              type="password"
              label={`${t("Confirmă parola")}`}
              size="medium"
            />
          </div>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default ChangePassword;
