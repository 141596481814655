import React, { useState } from "react";
import { PageLayout } from "lib";
import { PrimaryButton } from "lib/components/Buttons/buttons";

import AddIcon from "@mui/icons-material/Add";
import styles from "./TermsAndConditions.module.scss";
import { CustomTable } from "lib";
import { Typography } from "@mui/material";
import { EditButton } from "lib/components/Buttons/buttons";
import { RemoveButton } from "lib/components/Buttons/buttons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Tooltip } from "lib";
import { PoliticsAPI } from "api_inbusiness";
import { useHistory } from "react-router-dom";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import ConfirmModal from "lib/components/Modals/ConfirmModal";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";
import { Helmet } from "react-helmet";
import { getMembershipStatus } from "utils/functions";

const TermsAndConditions = () => {
  const { permissionsData } = useUser();
  const [openAdd, setOpenAdd] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [rowState, setRowState] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const navigate = useHistory();
  const [politics, setPolitics] = useState([]);
  const [loading, setLoading] = useState(true);

  const labels = [
    { id: "title", label: "Titlu", minWidth: 100 },
    { id: "actions", label: "", minWidth: 100 },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <div className={styles.actionAreaIndividualEducation}>
          {permissionsData.seeAdminButtons && (
            <>
              <EditButton
                onClick={() => {
                  setRowState(row);
                  setOpenAdd(true);
                }}
              />
              <RemoveButton
                onClick={() => {
                  setOpenConfirm(true);
                  setRowId(row.id);
                }}
              />
            </>
          )}

          <Tooltip
            position="left"
            textTip={"Vezi mai mult"}
            className={styles.tooltip}
            followCursor
          >
            <ArrowForwardIosIcon
              sx={{ color: "#808080", cursor: "pointer" }}
              onClick={() =>
                navigate.push({
                  pathname: `/terms/${row.id}`,
                  state: row,
                })
              }
            />
          </Tooltip>
        </div>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  const handleDeleteTerms = async () => {
    try {
      const res = await PoliticsAPI.delete(rowId);
      if (res.ok) {
        setTriggerRefetch(!triggerRefetch);
        setOpenConfirm(false);
        toast.success("Documentul a fost șters cu succes!");
      } else {
        toast.error("Documentul na a putut fi șters cu succes!");
      }
    } catch (error) {
      toast.error("A intervenit o eroare!");
    }
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Termeni și condiții</title>
      </Helmet>
      <PageLayout
        title="Documente importante"
        backArrow={true}
        actionArea={
          permissionsData.seeAdminButtons && (
            <>
              <PrimaryButton
                startIcon={<AddIcon />}
                onClick={() => {
                  setRowState(null);
                  setOpenAdd(true);
                }}
              >
                Adaugă
              </PrimaryButton>
            </>
          )
        }
      >
        <div>
          <CustomTable
            labels={labels}
            tableData={politics}
            cellModifier={cellModifier}
            loading={loading}
            setLoading={setLoading}
            getterFunction={PoliticsAPI.get}
            withPagination={true}
            setterFunction={setPolitics}
            triggerRefetch={triggerRefetch}
          />
          <TermsAndConditionsModal
            open={openAdd}
            setOpen={setOpenAdd}
            rowState={rowState}
            triggerRefetch={triggerRefetch}
            setTriggerRefetch={setTriggerRefetch}
          />
        </div>
        <ConfirmModal
          text={"Sunteți sigur că doriți să ștergeți acest document?"}
          buttonYes={"DA"}
          buttonNo={"NU"}
          onClickButtonYes={handleDeleteTerms}
          open={openConfirm}
          setOpen={setOpenConfirm}
        />
      </PageLayout>
    </>
  );
};

export default TermsAndConditions;
