import React from "react";
import { useRef, useState, useEffect } from "react";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { CustomDialog, TextField } from "lib";
import styles from "../Profile/Profile.module.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { NewsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";

const AddNewsModal = ({ open, setOpen, dataForEdit, getNews }) => {
  const formRef = useRef();
  const { user } = useUser();

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (dataForEdit) {
      setEditorState(htmlToDraftBlocks(dataForEdit.description));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [dataForEdit]);

  const INITIAL_FORM_STATE = {
    title: dataForEdit?.title ? dataForEdit.title : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    title: yup.string().trim().required("Titlul anunțului este obligatoriu"),
  });

  const handleAddNews = async (objForApi) => {
    try {
      const res = await NewsAPI.create(objForApi);
      if (res.ok) {
        toast.success("Anuntul a fost adaugat cu succes!");
        getNews();
        setOpen(false);
      } else {
        toast.error("Anuntul nu a putut fi creat!");
      }
    } catch (error) {
    } finally {
      setEditorState(EditorState.createEmpty());
    }
  };

  const handleEditNews = async (objForApi) => {
    try {
      const res = await NewsAPI.update(dataForEdit.id, objForApi);
      if (res.ok) {
        toast.success("Anuntul a fost actualizat cu succes!");
        getNews();
        setOpen(false);
      } else {
        toast.error("Anuntul nu a putut fi actualizat!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setEditorState(EditorState.createEmpty());
    }
  };

  const handleSubmit = async (values) => {
    const objForApi = { ...values };
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    objForApi.description = htmlContent;
    objForApi.userId = user.id;

    if (dataForEdit) await handleEditNews(objForApi);
    else await handleAddNews(objForApi);
  };

  const handleSubmitButton = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={dataForEdit ? "Editează anunț" : "Adaugă anunț nou"}
      buttonClose={"ÎNAPOI"}
      buttonFinish={"FINALIZEAZĂ"}
      onClickButtonFinish={handleSubmitButton}
      maxWidth="650px"
      width="100%"
      handleClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form className={styles.formWrapper}>
          <div className={styles.textFieldsWrapper}>
            <TextField name="title" label="Titlu anunț*" size="medium" />
            <Editor
              placeholder="Intrduceți aici conținutul anunțului dumneavoastră*"
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-class"
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
              }}
              editorStyle={{
                borderRadius: 10,
                border: "1px solid #cbcbcb",
                padding: "0px 8px",
                overflowY: "auto",
                maxHeight: "30vh",
                minHeight: "30vh",
              }}
              preserveSelectionOnBlur={true}
            />
          </div>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddNewsModal;
