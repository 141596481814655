import React, { useRef } from "react";
import { CustomDialog, TextField } from "lib";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { PaymentsAPI, UsersAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";
import { useHistory } from "react-router-dom";

const JuidicalPersonPayment = ({ open, setOpen = () => {}, planData }) => {
  const formRef = useRef();
  const { getUpdatedUser, user } = useUser();
  const navigate = useHistory();

  const INITIAL_FORM_STATE = {
    name: user.billingData?.name ? user.billingData.name : "",
    cui: user.billingData?.cui ? user.billingData.cui : "",
    country: user.billingData?.country ? user.billingData.country : "",
    city: user.billingData?.city ? user.billingData.city : "",
    county: user.billingData?.county ? user.billingData.county : "",
    address: user.billingData?.address ? user.billingData.address : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    name: yup
      .string()
      .typeError("Numele nu este valid!")
      .trim()
      .min(3, "Numele trebuie să conțină cel puțin 3 caractere!")
      .required("Numele este obligatoriu!"),
    cui: yup
      .string()
      .typeError("CUI-ul nu este valid!")
      .trim()
      .min(6, "CUI-ul trebuie să conțină cel puțin 6 caractere!"),

    country: yup
      .string()
      .typeError("Țara nu este validă!")
      .trim()
      .min(3, "Țara trebuie să conțină cel puțin 3 caractere!")
      .required("Țara este obligatorie!"),
    city: yup
      .string()
      .typeError("Orașul nu este valid!")
      .trim()
      .min(3, "Orașul trebuie să conțină cel puțin 3 caractere!")
      .required("Orașul este obligatoriu!"),
    county: yup
      .string()
      .typeError("Județulnu este valid!")
      .trim()
      .min(3, "Județul trebuie să conțină cel puțin 3 caractere!")
      .required("Județul este obligatoriu!"),
    address: yup
      .string()
      .typeError("Adresa nu este validă!")
      .trim()
      .min(3, "Adresa trebuie să conțină cel puțin 3 caractere!")
      .required("Adresa este obligatorie!"),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // const handleNewPayment = (values) => {
  //   const objForPayments = { ...values };
  //   PaymentsAPI.create(objForPayments).then((res) => {
  //     if (res.ok === true) {
  //       window.open(res.data.link, "_blank");
  //       PaymentsAPI.getPaymentStatus(res.data.estimateId).then((res) => {
  //         if (res.ok) {
  //           toast.success("Subscripția a fost achiziționată cu succes!");
  //           getUpdatedUser();
  //           navigate.push("/");
  //         } else {
  //           toast.error("A intervenit o eroare la plata subscripției!");
  //         }
  //       });
  //     } else {
  //       toast.error("A intervenit o eroare!");
  //     }
  //   });
  // };

  // in this function first we update the billing data, if the request goes true, we proceed to the payment.
  // after the user completes the euPlatesc form, we will check if the payment went through then we will get the updated user
  const handleNewPayment = (values) => {
    UsersAPI.updateBillingData(values).then((res) => {
      if (res.ok === true) {
        PaymentsAPI.create(planData).then((res) => {
          if (res.ok === true) {
            window.open(res.data.link, "_blank");
            PaymentsAPI.getPaymentStatus(res.data.estimateId).then((res) => {
              if (res.ok) {
                toast.success("Subscripția a fost achiziționată cu succes!");
                getUpdatedUser();
                navigate.push("/");
              } else {
                toast.error("A intervenit o eroare la plata subscripției!");
              }
            });
          } else {
            toast.error("A intervenit o eroare!");
          }
        });
      } else {
        toast.error("A intervenit o eroare la salvarea datelor de facturare.");
        setOpen(false);
      }
    });
  };
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={
        user.billingData !== null
          ? "Actualizează date de facturare"
          : "Adauga date de facturare"
      }
      buttonClose={"Înapoi"}
      buttonFinish={"Trimite"}
      onClickButtonFinish={handleSubmit}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => handleNewPayment(values)}
      >
        <Form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <TextField name="name" label="Nume*" size="medium" />

          <TextField name="cui" label="CUI (opțional)" size="medium" />

          <TextField name="country" label="Țară*" size="medium" />

          <TextField name="county" label="Județ*" size="medium" />

          <TextField name="city" label="Oraș*" size="medium" />

          <TextField name="address" label="Adresă*" size="medium" />
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default JuidicalPersonPayment;
