import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styles from "./Education.module.scss";

import { PrimaryButton, EditButton } from "lib/components/Buttons/buttons";
import AddIcon from "@mui/icons-material/Add";

import Search from "lib/components/Search/Search";

import { CustomTable, PageLayout, Tooltip } from "lib";

import { Helmet } from "react-helmet";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddEducationCategory from "./AddEducationCategory";
import { EducationAPI } from "api_inbusiness";
import { useUser } from "hooks/useUser";

const Education = () => {
  const { permissionsData } = useUser();
  const [open, setOpen] = useState(false);
  const [educationCategories, setEducationCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [rowState, setRowState] = useState(null);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const navigate = useHistory();
  const labels = [
    {
      id: "name",
      label: "Nume",
      minWidth: 10,
    },
    {
      id: "articles",
      label: "Articole",
      minWidth: 10,
    },
    {
      id: "actions",
      label: "Actiuni",
      minWidth: 10,
    },
  ];

  const getCategories = () => {
    EducationAPI.getCategories().then((res) =>
      setEducationCategories(res.data.content)
    );
  };
  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <div className={styles.actionButtons}>
          {permissionsData.seeAdminButtons && (
            <EditButton
              color="#1860D2"
              onClick={() => {
                setRowState(row);
                setOpen(true);
              }}
            />
          )}

          <Tooltip position="left" textTip={"Vezi mai mult"} followCursor>
            <ArrowForwardIosIcon
              sx={{ color: "#808080", cursor: "pointer" }}
              onClick={() => navigate.push(`/education/${row.id}`)}
            />
          </Tooltip>
        </div>
      );
    }

    if (column.id === "name") {
      return <div>{row.name}</div>;
    }
    if (column.id === "articles") {
      return <div>{row.nrEducation}</div>;
    }
    // return <Typography variant="tableContent">{value}</Typography>;
  };

  const resetFilter = () => {
    setIsFilter(false);
    // setFilterData({});
  };
  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {"Educatie"}</title>
      </Helmet>
      <PageLayout
        title={"Educatie"}
        subTitle={"Manageriaza resursele"}
        actionArea={
          <React.Fragment>
            <Search
              search={search}
              setSearch={setSearch}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              nameToFind={"Caută categorie"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setterFunction={setEducationCategories}
              setLoading={setLoading}
              setIsFilter={setIsFilter}
              resetFilter={resetFilter}
            />

            {permissionsData.seeAdminButtons && (
              <PrimaryButton
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => {
                  setRowState(null);
                  setOpen(true);
                }}
              >
                {"Adauga categorie"}
              </PrimaryButton>
            )}
          </React.Fragment>
        }
      >
        <CustomTable
          labels={labels}
          tableData={educationCategories}
          cellModifier={cellModifier}
          loading={loading}
          setLoading={setLoading}
          getterFunction={EducationAPI.getCategories}
          setterFunction={setEducationCategories}
          withPagination={true}
          triggerRefetch={triggerRefetch}
          searchFunction={EducationAPI.searchEducationCategory}
          search={search}
          isSearch={isSearch}
          triggerSearch={triggerSearch}
        />

        <AddEducationCategory
          open={open}
          setOpen={setOpen}
          rowState={rowState}
          getCategories={getCategories}
          triggerRefetch={triggerRefetch}
          setTriggerRefetch={setTriggerRefetch}
        />
      </PageLayout>
    </>
  );
};

export default Education;
