import React from "react";
import QrCode from "react-qr-code";
import { useUser } from "hooks/useUser";
import { PageLayout } from "lib";
import styles from "./QrUserPage.module.scss";
import { Helmet } from "react-helmet";

const QrUserPage = () => {
  const { user } = useUser();
  const userIdString = user.id && user.id.toString();

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: QR</title>
      </Helmet>
      <PageLayout
        title={`Cod QR`}
        subTitle={` ${user.firstName} ${user.lastName}`}
      >
        <div className={styles.mainQRContainer}>
          <QrCode size={256} value={userIdString} viewBox={`0 0 256 256`} />
        </div>
      </PageLayout>
    </>
  );
};

export default QrUserPage;
