import { useUser } from "hooks/useUser";
import React, { useRef } from "react";
import { CustomDialog, TextField, Tooltip } from "lib";
import ConfirmModal from "../ConfirmModal";
import { UsersAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import InfoIcon from "@mui/icons-material/Info";
import * as yup from "yup";

const DeleteAccount = ({
  open,
  setOpen,
  type,
  rowDetails,
  setTriggerRefetch = () => {},
}) => {
  const { user, setAccessToken } = useUser();
  const formRef = useRef();

  const INITIAL_FORM_STATE = {
    reason: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    reason: yup
      .string()
      .typeError(
        type === "admin"
          ? "Acest câmp nu poate fi gol!"
          : "Vă rugăm să completați acest câmp în care să ne spuneți ce am putea îmbunătăți la această platformă."
      )
      .trim()
      .required(
        type === "admin"
          ? "Acest câmp nu poate fi gol!"
          : "Vă rugăm să completați acest câmp în care să ne spuneți ce am putea îmbunătăți la această platformă."
      ),
  });

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Șterge cont"}
      buttonClose={"Înapoi"}
      buttonFinish={"Trimite"}
      onClickButtonFinish={handleSubmit}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
      // buttonFinishLoading={loading}
      // buttonCloseDisabled={loading}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (type === "admin") {
            UsersAPI.deleteUserAdmin(rowDetails.id, values.reason).then(
              (res) => {
                if (res.ok) {
                  toast.success("Contul a fost șters cu succes!");
                  setOpen(false);
                  setTriggerRefetch((prev) => !prev);
                } else {
                  toast.error("Contul a putut fi șters!");
                }
              }
            );
          } else {
            UsersAPI.delete(values.reason).then((res) => {
              if (res.ok) {
                toast.success("Contul a fost șters cu succes!");
                setOpen(false);
                setAccessToken(undefined);
              } else {
                toast.error("Contul a putut fi șters!");
              }
            });
          }
        }}
      >
        <Form>
          <TextField
            name="reason"
            label={
              type === "admin"
                ? "Motiv"
                : "Ce am putea îmbunătăți la această platforma?"
            }
            size="medium"
            multiline
            rows={6}
          />
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default DeleteAccount;
