import axios from "./axios";

const Mysubscriptions = {
  /**
   * Fetches all Mysubscriptions's
   *
   * @return {array} Array of Mysubscriptions's
   */
  async get(perPage, currentPage, pagesToLoad) {
    try {
      const res = await axios.get("/payment/my-payments", {
        params: {
          perPage,
          currentPage,
          pagesToLoad,
        },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Mysubscriptions by it's ID
   *
   * @param {string} id ID of the Mysubscriptions
   * @return {object} Mysubscriptions with the corresponding ID
   */
  async getById(mysubscriptionsId) {
    try {
      const res = await axios.get("/mysubscriptions/" + mysubscriptionsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Mysubscriptions
   *
   * @param {object} mysubscriptions Object with the creation data
   * @return {object} Newly created Mysubscriptions
   */
  async create(mysubscriptions) {
    let reqBody = { ...mysubscriptions };
    try {
      const res = await axios.post("/mysubscriptions", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Deletes Mysubscriptions by ID
   *
   * @param {string} mysubscriptionsId ID of the Mysubscriptions to be deleted
   * @return {string} ID of the deleted Mysubscriptions
   */
  async delete(mysubscriptionsId) {
    try {
      await axios.delete("/mysubscriptions/" + mysubscriptionsId);
      return {
        ok: true,
        data: {
          id: mysubscriptionsId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: mysubscriptionsId,
        },
      };
    }
  },

  /**
   * Get payment invoice files
   *
   */
  async getPaymentInvoice(invoice) {
    try {
      const res = await axios.get(`/payment/invoice/${invoice}`, {
        responseType: "arraybuffer",
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },
};

export default Mysubscriptions;
