import React, { useState, useEffect, useRef } from "react";
import { CustomDialog, DatePicker, TextField } from "lib";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import styles from "./QrAdminPage.module.scss";
import { PeriodsAPI } from "api_inbusiness";

const AddNewPeriodForInactiveMember = ({
  open,
  setOpen,
  userData,
  getUserInfo = () => {},
}) => {
  const membershipRef = useRef();

  const INITIAL_FORM_STATE_ROW = {
    amount: "",
    startDate: new Date(),
    endDate: new Date(),
  };

  const FORM_VALIDATION_ROW = yup.object().shape({
    amount: yup
      .string()
      .typeError("Suma plătită nu este valid")
      .trim()
      .required("Suma plătită este obligatorie"),
    startDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de început este obligatorie"),
    endDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de sfârșit este obligatorie"),
  });

  const handleSubmitAddPeriod = () => {
    if (membershipRef.current) {
      membershipRef.current.handleSubmit();
    }
  };

  const addPeriod = (values) => {
    const objForPeriod = { ...values };
    // objForPeriod.userId = Number(userData);
    objForPeriod.amount = Number(objForPeriod.amount);
    // objForPeriod.noOfMonths = Number(objForPeriod.noOfMonths);

    PeriodsAPI.addManualMembership(userData, objForPeriod).then((res) => {
      if (res.ok === true) {
        toast.success("Perioada a fost actualizată cu succes");
        getUserInfo();
        setOpen(false);
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title="Adaugă perioadă"
      buttonClose="Înapoi"
      buttonFinish={"Finalizează"}
      width="700px"
      maxWidth="1000px"
      onClickButtonFinish={handleSubmitAddPeriod}
    >
      <Formik
        innerRef={membershipRef}
        initialValues={{
          ...INITIAL_FORM_STATE_ROW,
        }}
        validationSchema={FORM_VALIDATION_ROW}
        onSubmit={(values) => {
          addPeriod(values);
        }}
      >
        {(props) => {
          return (
            <Form className={styles.formNewPeriodModal}>
              <DatePicker name="startDate" label="Data început" />
              <DatePicker name="endDate" label="Data sfârșit" />
              <TextField name="amount" label={"Suma plătită*"} size="medium" />
            </Form>
          );
        }}
      </Formik>
    </CustomDialog>
  );
};

export default AddNewPeriodForInactiveMember;
