import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Tickets.module.scss";
import { PageLayout, CustomTable } from "lib";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { Helmet } from "react-helmet";
import { Typography, Chip } from "@mui/material";
import { EventsAPI, UsersAPI } from "api_inbusiness";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Tooltip } from "lib";
import Search from "lib/components/Search/Search";
import ConfirmModal from "lib/components/Modals/ConfirmModal";
import { toast } from "react-toastify";
import DownloadTicketsModal from "./DownloadTicketsModal";

const Tickets = () => {
  const navigate = useHistory();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");

  const cellModifier = (row, column, value) => {
    if (column.id === "eventId") {
      return <Typography variant="tableContent">{row.event.title}</Typography>;
    }

    if (column.id === "company") {
      return (
        <Typography variant="tableContent">{row.purchase.company}</Typography>
      );
    }

    if (column.id === "email") {
      return (
        <Typography variant="tableContent">{row.purchase.email}</Typography>
      );
    }
    if (column.id === "scanned") {
      return (
        <Chip
          label={row.scanned === true ? "Da" : "Nu"}
          sx={{
            backgroundColor: row.scanned === true ? "green" : "red",
            color: "white",
          }}
        />
      );
    }

    if (column.id === "valid") {
      return (
        <Typography variant="tableContent">
          {row.valid === true ? "Da" : "Nu"}
        </Typography>
      );
    }

    if (column.id === "actions") {
      return (
        <div className={styles.actionArea}>
          <Tooltip position="right" textTip={"Detalii bilet"} followCursor>
            <div
              className={styles.seeDetailsButton}
              onClick={() => navigate.push(`/tickets/${row.id}`)}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </div>
          </Tooltip>

          <Tooltip position="right" textTip={"Scanează manual"} followCursor>
            <div
              className={styles.scanButton}
              onClick={() => {
                setRowData(row);
                setOpenConfirm(true);
              }}
            >
              <DocumentScannerIcon fontSize="small" />
            </div>
          </Tooltip>
        </div>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  const labels = [
    {
      id: "eventId",
      label: "Eveniment",
      minWidth: 10,
    },

    {
      id: "serial",
      label: "Seria",
      minWidth: 10,
    },
    {
      id: "number",
      label: "Număr",
      minWidth: 10,
    },
    {
      id: "fullName",
      label: "Nume Complet",
      minWidth: 10,
    },
    {
      id: "company",
      label: "Companie",
      minWidth: 10,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 10,
    },

    {
      id: "valid",
      label: "Valid",
      minWidth: 10,
    },
    {
      id: "scanned",
      label: "Scanat",
      minWidth: 10,
    },

    {
      id: "reason",
      label: "Motiv invalidare",
      minWidth: 10,
    },
    {
      id: "actions",
      label: "",
      minWidth: 10,
    },
  ];

  const manualScanTicket = (ticketId) => {
    EventsAPI.manualScanTicket(ticketId).then((res) => {
      if (res.ok === true) {
        toast.success("Biletul a fost scanat cu succes");
        setOpenConfirm(false);
        setTriggerRefetch(!triggerRefetch);
        setRowData({});
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Bilete</title>
      </Helmet>
      <PageLayout
        title="Bilete"
        actionArea={
          <>
            <PrimaryButton
              color="primary"
              onClick={() => setOpenDownloadModal(true)}
            >
              Descarcă bilete
            </PrimaryButton>
            <Search
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              search={search}
              setSearch={setSearch}
              nameToFind={"Caută bilet"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setLoading={setLoading}
              setterFunction={setTickets}
            />
          </>
        }
      >
        <CustomTable
          labels={labels}
          loading={loading}
          setLoading={setLoading}
          tableData={tickets}
          cellModifier={cellModifier}
          getterFunction={EventsAPI.getAllTickets}
          setterFunction={setTickets}
          withPagination={true}
          triggerRefetch={triggerRefetch}
          searchFunction={EventsAPI.searchTicket}
          triggerSearch={triggerSearch}
          search={search}
          isSearch={isSearch}
        />

        <DownloadTicketsModal
          open={openDownloadModal}
          setOpen={setOpenDownloadModal}
        />

        <ConfirmModal
          text={"Sunteți sigur că doriți să scanați acest bilet?"}
          buttonYes={"DA"}
          buttonNo={"NU"}
          onClickButtonYes={() => manualScanTicket(rowData.id)}
          open={openConfirm}
          setOpen={setOpenConfirm}
        />
      </PageLayout>
    </>
  );
};

export default Tickets;
