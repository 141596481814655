import React, { useState, useEffect } from "react";
import { CustomDialog } from "lib";
import { EventsAPI } from "api_inbusiness";
import styles from "./QrAdminPage.module.scss";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

const CheckAttendanceCustomEvent = ({
  open,
  setOpen = () => {},
  eventData,
  qrData,
  eventId,
}) => {
  const [ticketData, setTicketData] = useState({});

  const getTicket = () => {
    EventsAPI.getTicket(qrData).then((res) => {
      setTicketData(res.data);
    });
  };

  const scanTicket = () => {
    EventsAPI.scanTicket(ticketData.id, eventId)
      .then((res) => {
        if (res.ok === true) {
          toast.success("Biletul a fost scanat cu succes!");
          getTicket();
          setOpen(false);
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Ticket already scanned") {
          toast.error("Biletul a fost scanat o dată!");
        } else {
          toast.error("A intervenit o eroare!");
        }
      });
  };

  useEffect(() => {
    if (open) getTicket();
  }, [open]);

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={ticketData.event?.title}
      buttonClose="Înapoi"
      width="700px"
      maxWidth="1000px"
      onClickButtonFinish={scanTicket}
      buttonFinish={ticketData.valid === true ? "Scanează" : ""}
    >
      <div className={styles.eventCustomContainer}>
        <div>
          <Typography variant="h6">
            {ticketData.serial} - {ticketData.number}
          </Typography>
        </div>
        <div>
          <Typography variant="h5"> {ticketData.fullName}</Typography>{" "}
        </div>
        <div>
          <Typography variant="h6">{ticketData.purchase?.company}</Typography>
        </div>
        <div
          style={{
            color: ticketData.valid === true ? "green" : "red",
            marginTop: "10px",
          }}
        >
          {ticketData.valid === true
            ? "Biletul este valid"
            : "Biletul a fost invalidat"}
        </div>
        <div style={{ color: ticketData.scanned === true ? "red" : "green" }}>
          {ticketData.scanned === true
            ? "Biletul a fost scanat o dată!"
            : "Biletul nu a fost scanat încă!"}
        </div>
      </div>
    </CustomDialog>
  );
};

export default CheckAttendanceCustomEvent;
