import React from "react";
import { useRef, useState } from "react";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { CustomDialog, TextField, FileUploadWrapper } from "lib";
import { toast } from "react-toastify";
import { urlRegex, phoneRegex } from "utils/regex";

import styles from "./Profile.module.scss";

import { Avatar } from "@mui/material";
import DefaultCompanyPicture from "resources/img/InBusiness/defaultImage.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/Info";
import { uploadSingleFile } from "utils/functions";
import { UtilityButton } from "lib/components/Buttons/buttons";
import { CompaniesAPI } from "api_inbusiness";
import { Tooltip } from "lib";

import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const AddCompanyModal = ({
  open,
  setOpen,
  dataForEdit,
  userId,
  getUpdatedUser,
}) => {
  const [blobCompanyImage, setBlobCompanyImage] = useState(null);
  const [cropData, setCropData] = useState(null);

  const cropperRef = useRef();
  const formRef = useRef();

  const INITIAL_FORM_STATE = {
    name: dataForEdit?.name ? dataForEdit.name : "",
    site: dataForEdit?.site ? dataForEdit.site : "",
    phone: dataForEdit?.phone ? dataForEdit.phone : "",
    description: dataForEdit?.description ? dataForEdit.description : "",
  };

  const FORM_VALIDATION = yup.object().shape({
    name: yup.string().trim().required("Numele companiei este obligatoriu"),
    site: yup
      .string()
      .trim()
      .matches(urlRegex, "Introduceți un site valid")
      .required("Numele site-ului este obligatoriu"),
    phone: yup
      .string()
      .trim()
      .matches(phoneRegex, "Introduceți un număr de telefon valid"),
  });

  const handleAddCompany = (values) => {
    CompaniesAPI.create({ userId, ...values }).then((res) => {
      if (res.ok) {
        if (blobCompanyImage) {
          const croppedImage = handleCrop();
          CompaniesAPI.addCompanyLogo(res.data.id, croppedImage).then(
            (resImg) => {
              if (resImg.ok) {
                toast.success("Logo-ul companiei a fost incarcat cu succes!");
                toast.success("Compania a fost creată!");
                getUpdatedUser();
                setBlobCompanyImage(null);
                setCropData(null);
                setOpen(false);
              } else {
                toast.success("Compania a fost creată!");
                toast.error("Logo-ul companiei nu a putut fi încarcat!");
              }
            }
          );
        } else {
          toast.success("Compania a fost creată!");
          getUpdatedUser();
          setBlobCompanyImage(null);
          setOpen(false);
        }
      } else {
        toast.error("Compania nu a putut fi creată!");
        setBlobCompanyImage(null);
        setOpen(false);
      }
    });
  };

  const handleFileUpload = async (e) => {
    await uploadSingleFile(
      e,
      ({ message, blob }) => {
        if (message) {
          toast.error(message);
          return;
        }
        setBlobCompanyImage(blob);
      },
      "image"
    );
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  //code for cropper
  const getCropData = () => {
    if (cropperRef && cropperRef.current !== undefined) {
      setCropData(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
      toast.success("Imaginea a fost decupată cu succes!");
    }
  };

  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    //image from cropper
    let base64Data = cropper.getCroppedCanvas().toDataURL();

    // Get the content type and data
    let contentType = base64Data.split(";")[0].split(":")[1];
    let raw = window.atob(base64Data.split(",")[1]);

    // Create a Uint8Array from the base64 raw data
    let uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    // const blob = dataURItoBlob(cropper.getCroppedCanvas().toDataURL());
    let blob = new Blob([uint8Array], { type: contentType });

    // Create a FormData object and append the blob
    let formData = new FormData();
    formData.append("file", blob, "filename.png");

    return formData.get("file");
  };

  const handleEditCompany = (values) => {
    CompaniesAPI.update(dataForEdit.id, { ...values }).then((res) => {
      if (res.ok) {
        if (blobCompanyImage) {
          const croppedImage = handleCrop();
          CompaniesAPI.addCompanyLogo(res.data.id, croppedImage).then(
            (resImg) => {
              if (resImg.ok) {
                toast.success("Logo-ul companiei a fost incarcat cu succes!");
                toast.success("Compania a fost editată!");
                getUpdatedUser();
                setBlobCompanyImage(null);
                setCropData(null);
                setOpen(false);
              } else {
                toast.success("Compania a fost editată!");
                toast.error("Logo-ul companiei nu a putut fi încarcat!");
              }
            }
          );
        } else {
          toast.success("Compania a fost editată!");
          getUpdatedUser();
          setBlobCompanyImage(null);
          setOpen(false);
        }
      } else {
        toast.error("Compania nu a putut fi editată!");
        setBlobCompanyImage(null);
        setOpen(false);
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setBlobCompanyImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      //   title={t("Edit profile information")}
      title={dataForEdit ? "Editează companie noua" : "Adaugă companie noua"}
      //   buttonClose={t("BACK")}
      buttonClose={"ÎNAPOI"}
      //   buttonFinish={t("COMPLETE")}
      buttonFinish={"FINALIZEAZĂ"}
      onClickButtonFinish={handleSubmit}
      maxWidth="650px"
      width="100%"
      handleClose={() => {
        setBlobCompanyImage(null);
        setOpen(false);
        setCropData(null);
        cropperRef.current = null;
      }}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (dataForEdit) {
            handleEditCompany(values);
          } else {
            handleAddCompany(values);
          }
        }}
      >
        <Form className={styles.formWrapper}>
          <div className={styles.textFieldsWrapper}>
            <TextField name="name" label="Nume*" size="medium" />
            <TextField name="phone" label="Număr de telefon" size="medium" />
            <TextField name="site" label="Site*" size="medium" />
            <TextField
              name="description"
              label="Descrierea firmei"
              size="medium"
              multiline
              rows={6}
            />
          </div>

          <div className={styles.imageWrapper}>
            <Avatar
              src={
                // dataForEdit?.companyImage && !blobCompanyImage
                //   ? dataForEdit?.companyImage
                //   : blobCompanyImage
                //   ? URL.createObjectURL(blobCompanyImage)
                //   : DefaultCompanyPicture
                blobCompanyImage ? blobCompanyImage : dataForEdit?.companyImage
              }
              alt={
                dataForEdit?.name
                  ? dataForEdit.name
                  : blobCompanyImage?.name
                  ? blobCompanyImage.name
                  : "Company Image"
              }
              className={styles.avatar}
              variant="rounded"
            />
            {/* <FileUploadWrapper onUpload={handleFileUpload}>
              <UtilityButton variant="contained" type="button" height="41px">
                {blobCompanyImage || dataForEdit?.companyImage
                  ? "SCHIMBĂ FOTOGRAFIA"
                  : "ÎNCARCĂ"}
              </UtilityButton>
            </FileUploadWrapper> */}
            <div>
              <label
                htmlFor="file-upload"
                className={styles.customCompanyImageUpload}
              >
                Selectează o imagine nouă
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>

            {(blobCompanyImage || dataForEdit?.companyImage) && (
              <Tooltip
                position="right"
                textTip={
                  "Pentru a decupa logo-ul, trebuie doar să mutați chenarul în zona dorită!"
                }
                style={{
                  border: "1px solid",
                  borderColor: "#ff4b55",
                  color: "#ff4b55",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    color: "#ff4b55",
                    marginTop: "0.5rem",
                  }}
                >
                  <InfoIcon
                    sx={{
                      fontSize: "22px",
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </Form>
      </Formik>
      <div>
        {(blobCompanyImage || dataForEdit?.companyImage) && (
          <div>
            <Cropper
              ref={cropperRef}
              style={{ height: 200, width: "100%", marginTop: "10px" }}
              aspectRatio={800 / 800}
              initialAspectRatio={16 / 9}
              preview=".img-preview"
              src={
                blobCompanyImage ? blobCompanyImage : dataForEdit?.companyImage
              }
              viewMode={1}
              minCropBoxWidth={512}
              minCropBoxHeight={512}
              responsive={true}
              autoCropArea={1}
              autoCrop={true}
              guides={false}
              crop={handleCrop}
            />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {cropData ? (
            <img
              src={cropData}
              alt="crop"
              style={{ width: "auto", height: "200px" }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </CustomDialog>
  );
};

export default AddCompanyModal;
