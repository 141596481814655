import axios from "./axios";

const Payments = {
  /**
   * Fetches all Payments's
   *
   * @return {array} Array of Payments's
   */
  async get() {
    try {
      const res = await axios.get("/payment");
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Payments by it's ID
   *
   * @param {string} id ID of the Payments
   * @return {object} Payments with the corresponding ID
   */
  async getById(paymentsId) {
    try {
      const res = await axios.get("/payment/" + paymentsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetches one Payments by user ID
   *
   * @param {string} id userId
   * @return {object} Payments for the corresponding user ID
   */
  async getByUserId(userId) {
    try {
      const res = await axios.get("/payment/" + userId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Payments
   *
   * @param {object} payments Object with the creation data
   * @return {object} Newly created Payments
   */
  async create(values) {
    try {
      const res = await axios.post(`payment/subscription/${values}`);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetches payment status
   *
   * @return something
   */
  async getPaymentStatus(id) {
    try {
      const res = await axios.get(`/payment/status/${id}`);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Deletes Payments by ID
   *
   * @param {string} paymentsId ID of the Payments to be deleted
   * @return {string} ID of the deleted Payments
   */
  async delete(paymentsId) {
    try {
      await axios.delete("/payment/" + paymentsId);
      return {
        ok: true,
        data: {
          id: paymentsId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: paymentsId,
        },
      };
    }
  },

  /**
   * Cancels Subscription by ID
   *
   * @param {string} paymentsId ID of the Payments to be canceled
   * @return {string} ID of the canceled Payments
   */
  async cancelSubscription() {
    try {
      const res = await axios.post("/subscription/cancel");
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },
};

export default Payments;
