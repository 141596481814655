const ro = {
    'Tasks to do': 'Sarcini de făcut',
    'Tasks performed': 'Sarcini efectuate',
    'Search task': 'Caută sarcină',
    'Manage Tasks': 'Administrează Sarcini',
    'FILTER TASKS': 'FILTRARE SARCINI',
    'ADD TASK': 'ADAUGĂ SARCINĂ',
    'Schedule drive test': 'Programare drive-test',
    'Costumer recontracting': 'Recontactare client',
    'GDPR activation': 'Activare GDPR',
    'Re-activare GDPR': 'GDPR re-activation',
    'Other task': 'Altă sarcină',
    'To do tasks': 'Sarcini de făcut',
    'Unfinished tasks': 'Sarcini nefinalizate',
    'Are you sure you want to delete this task?': 'Sunteți sigur că doriți să ștergeți această sarcină?',
    'Are you sure you want to mark this task as finished?':
        'Sunteți sigur că doriți să marcați această sarcină ca finalizată?',
    'The task was successfully deleted!': 'Sarcina a fost ștearsă cu succes!',
    'Something went wrong! This task could not be deleted!':
        'Ceva n-a mers bine! Această sarcină nu a putut fi ștearsă!',
    'The task was successfully marked as completed!': 'Sarcina a fost marcată cu succes ca finalizată!',
    'Ceva n-a mers bine! Această sarcină nu a putut fi marcată ca finalizată!':
        'Ceva n-a mers bine! This sarcină nu a putut fi marcată ca finalizată!',
    'Edit task': 'Editați sarcina',
    'Add task': 'Adăugați sarcină',
    Task: 'Sarcină',
    Type: 'Tip',
    'Start date': 'De la data de',
    'End date': 'Pană la data de',
    'Choose a valid date type!': 'Alegeți un tip de dată valid!',
    'The date is mandatory!': 'Data este obligatorie!',
    'This client is not valid!': 'Acest client nu este valabil!',
    'The client is mandatory!': 'Clientul este obligatoriu!',
    WITHOUT: 'FARĂ',
    'A CLIENT': 'CLIENT',
    'The task has been updated successfully!': 'Sarcina a fost actualizată cu succes!',
    'The task has been added successfully!': 'Sarcina a fost adăugată cu succes!',
    'Filter tasks': 'Filtrare sarcini',
    Title: 'Titlu',
    Details: 'Detalii',
    'The title is mandatory!': 'Titlul este obligatoriu!',
    'The title must contain at least 3 characters!': 'Titlul trebuie să conțină cel puțin 3 caractere!',
    'The details are mandatory!': 'Detaliile sunt obligatorii!',
    'The counselor is mandatory!': 'Consilierul este obligatoriu!',
};

export default { ro };
