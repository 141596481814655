import axios from "./axios";

const Subscriptions = {
  /**
   * Fetches all Subscriptions's
   *
   * @return {array} Array of Subscriptions's
   */
  async get() {
    try {
      const res = await axios.get("/plan");
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Subscriptions by it's ID
   *
   * @param {string} id ID of the Subscriptions
   * @return {object} Subscriptions with the corresponding ID
   */
  async getById(subscriptionsId) {
    try {
      const res = await axios.get("/subscriptions/" + subscriptionsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Subscriptions
   *
   * @param {object} subscriptions Object with the creation data
   * @return {object} Newly created Subscriptions
   */
  async create(subscriptions) {
    let reqBody = { ...subscriptions };
    try {
      const res = await axios.post("/subscriptions", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Deletes Subscriptions by ID
   *
   * @param {string} subscriptionsId ID of the Subscriptions to be deleted
   * @return {string} ID of the deleted Subscriptions
   */
  async delete(subscriptionsId) {
    try {
      await axios.delete("/subscriptions/" + subscriptionsId);
      return {
        ok: true,
        data: {
          id: subscriptionsId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: subscriptionsId,
        },
      };
    }
  },

  /**
   * Updates Subscriptions by ID
   *
   * @param {string} subscriptionsId ID of the Subscriptions to be updated
   * @return {string} ID of the updated Subscriptions
   */

  async updateSubscription(id, subscription) {
    try {
      const res = await axios.patch("/plan/" + id, undefined, {
        params: {
          price: subscription.price,
        },
      });
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: error,
      };
    }
  },
};

export default Subscriptions;
