import React, { useRef, useState } from "react";
import styles from "./AddEvent.module.scss";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { CustomDialog, Select, TextField, DatePicker, TimePicker } from "lib";
import { MenuItem } from "@mui/material";

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EventsAPI, CountiesAPI } from "api_inbusiness";

const AddEvent = ({
  open,
  setOpen,
  triggerRefetch,
  setTriggerRefetch,
  getAllEvents = () => {},
  isEditMode,
  selectedEvent,
}) => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const [initialHTML, setInitialHTML] = React.useState(``);
  const [isDraft, setIsDraft] = React.useState(false);
  const [isCustom, setIsCustom] = React.useState(false);

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    CountiesAPI.get()
      .then((res) => {
        if (res.ok) {
          setLocations(res.data.content);
        } else {
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const INITIAL_FORM_STATE = {
    title: isEditMode ? selectedEvent.title : "",
    date: isEditMode ? selectedEvent.date : "",
    startTime: isEditMode ? selectedEvent.startTime : "",
    countyId: isEditMode ? selectedEvent.county.id : "",
    location: isEditMode ? selectedEvent.location : "",
    subtitle: isEditMode ? selectedEvent.subtitle : "",
    description: "",
    customId: isEditMode ? selectedEvent.customId ?? "" : "", //if customId is coming null from backend so we transform it to empty string
  };

  const FORM_VALIDATION = yup.object().shape({
    title: yup
      .string()
      .typeError("Titlul nu este valid!")
      .trim()
      .min(3, "Titlul trebuie să conțină cel puțin 3 caractere!")
      .required("Titlul este obligatoriu!"),
    subtitle: yup
      .string()
      .typeError("Subtitlul nu este valid!")
      .trim()
      .min(3, "Subtitlul trebuie să conțină cel puțin 3 caractere!")
      .nullable(),
    date: yup
      .date()
      .typeError("Data nu este valida!")
      .required("Data este obligatorie!"),
    startTime: yup
      .date()
      .typeError("Ora nu este valida!")
      .required("Ora este obligatorie!"),
    countyId: yup
      .string()
      .typeError("Județul nu este valida!")
      .required("Județul este obligatorie!"),
    location: yup
      .string()
      .typeError("Locația nu este valida!")
      .trim()
      .min(2, "Locația trebuie să conțină cel puțin 2 caractere!")
      .required("Locația este obligatorie!"),
    customId: yup
      .string()
      .nullable()
      .when(["isCustom"], {
        is: () => isCustom === true,
        then: () =>
          yup.string().trim().required("Acest camp este obligatoriu!"),
      }),
  });

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  React.useEffect(() => {
    setEditorState(htmlToDraftBlocks(initialHTML));
  }, [initialHTML]);

  React.useEffect(() => {
    if (isEditMode) {
      let html = selectedEvent.description;
      setInitialHTML(html);
      setIsDraft(selectedEvent.draft);
      setIsCustom(selectedEvent.custom);
    }
    getLocations();
  }, [isEditMode]);

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmitFunc = (values) => {
    setLoading(true);

    const objForApi = { ...values };
    delete objForApi.time;

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    objForApi.description = htmlContent;
    objForApi.draft = isDraft;
    objForApi.custom = isCustom;

    if (isEditMode) {
      EventsAPI.update(selectedEvent.id, objForApi).then((res) => {
        if (res.ok === true) {
          getAllEvents();
          setOpen(false);
          toast.success("Evenimentul a fost actualizat cu succes!");
          setLoading(false);
        } else {
          toast.error("A intervenit o eroare");
        }
      });
    } else {
      EventsAPI.create(objForApi).then((res) => {
        if (res.ok === true) {
          getAllEvents();
          setEditorState(EditorState.createEmpty());
          setOpen(false);
          toast.success("Evenimentul a fost adăugat cu succes!");
          setLoading(false);
        } else {
          toast.error("A intervenit o eroare!");
        }
      });
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={isEditMode ? "Editează eveniment" : "Adaugă eveniment"}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={handleSubmit}
      // onClickButtonFinish={handleSave}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      // buttonFinishLoading={loading}
      // buttonCloseDisabled={loading}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        <Form className={styles.container}>
          <TextField name="title" label="Titlu*" size="medium" />
          <TextField name="subtitle" label="Subtitlu*" size="medium" />
          <div className={styles.firstContainer}>
            <DatePicker name="date" label="Data" inputFormat="DD/MM/YYYY" />
            <Select name="countyId" size="medium" label="Județ*">
              {locations.map((location) => {
                return (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={styles.secondContainer}>
            <TimePicker name="startTime" label="Ora" />

            <TextField name="location" label="Locație*" size="medium" />
          </div>

          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editor-class"
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarStyle={{
              borderRadius: 10,
              border: "1px solid #cbcbcb",
            }}
            editorStyle={{
              borderRadius: 10,
              border: "1px solid #cbcbcb",
              padding: "0px 8px",
              overflowY: "auto",
              maxHeight: "30vh",
              minHeight: "30vh",
            }}
            preserveSelectionOnBlur={true}
          />

          <div>
            <label>
              <input
                value={isDraft}
                type="checkbox"
                checked={isDraft}
                onChange={() => {
                  setIsDraft(!isDraft);
                }}
              />
              Salvează evenimentul ca draft
            </label>
          </div>

          <div>
            <label>
              <input
                value={isCustom}
                type="checkbox"
                checked={isCustom}
                onChange={() => {
                  setIsCustom(!isCustom);
                }}
              />
              Eveniment Custom
            </label>
          </div>

          {isCustom && (
            <TextField name="customId" label="Parolă eveniment" size="medium" />
          )}
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddEvent;
