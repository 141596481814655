import axios from "./axios";

const News = {
  /**
   * Fetches all News's
   *
   * @return {array} Array of News's
   */
  async get() {
    try {
      const res = await axios.get("/news");
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one News by it's ID
   *
   * @param {string} id ID of the News
   * @return {object} News with the corresponding ID
   */
  async getById(newsId) {
    try {
      const res = await axios.get("/news/" + newsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one News
   *
   * @param {object} news Object with the creation data
   * @return {object} Newly created News
   */
  async create(news) {
    let reqBody = { ...news };
    try {
      const res = await axios.post("/news", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Update one News
   *
   * @param {object} news Object with the update data
   * @return {object} Newly created News
   */
  async update(id, news) {
    let reqBody = { ...news };
    try {
      const res = await axios.patch(`/news/${id}`, reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Deletes News by ID
   *
   * @param {string} newsId ID of the News to be deleted
   * @return {string} ID of the deleted News
   */
  async delete(newsId) {
    try {
      await axios.delete("/news/" + newsId);
      return {
        ok: true,
        data: {
          id: newsId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: newsId,
        },
      };
    }
  },
};

export default News;
