import React, { useEffect, useRef, useState } from "react";
import { CustomDialog, DatePicker, TextField } from "lib";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { EventsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import styles from "./Ticket.module.scss";

const EditTicket = ({ open, setOpen, ticket, getTicket = () => {} }) => {
  const ticketRef = useRef();
  const [isValid, setIsValid] = useState(ticket.valid);
  const INITIAL_FORM_STATE_ROW = {
    fullName: ticket.fullName !== "" ? ticket.fullName : "",
    reason: ticket.reason !== "" ? ticket.reason : "",
  };

  const FORM_VALIDATION_ROW = yup.object().shape({
    fullName: yup.string().trim().nullable(),
    reason: yup.string().nullable(),
  });

  const handleSubmitEdit = () => {
    if (ticketRef.current) {
      ticketRef.current.handleSubmit();
    }
  };

  const onSubmitFunc = (values) => {
    const backendObj = { ...values };
    backendObj.valid = isValid;

    EventsAPI.updateTicket(ticket.id, backendObj).then((res) => {
      if (res.ok === true) {
        toast.success("Biletul a fost editat cu succes!");
        getTicket();
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  useEffect(() => {
    if (open) setIsValid(ticket.valid);
  }, [open]);

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title="Editează bilet"
      buttonClose="Înapoi"
      buttonFinish={"Finalizează"}
      width="700px"
      maxWidth="1000px"
      onClickButtonFinish={handleSubmitEdit}
    >
      <Formik
        innerRef={ticketRef}
        initialValues={{
          ...INITIAL_FORM_STATE_ROW,
        }}
        validationSchema={FORM_VALIDATION_ROW}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        <Form className={styles.editTicketContainer}>
          <TextField name="fullName" label={"Nume Complet"} size="medium" />

          <div>
            <label>
              <input
                value={isValid}
                type="checkbox"
                checked={isValid}
                onChange={() => {
                  setIsValid(!isValid);
                }}
              />
              Valid
            </label>
          </div>

          {isValid === false && (
            <TextField name="reason" label={"Motiv invalidare"} size="medium" />
          )}
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default EditTicket;
