import React from "react";
import { useRef } from "react";

import * as yup from "yup";
import { Formik, Form } from "formik";
import { CustomDialog, TextField } from "lib";
import { toast } from "react-toastify";
import { ProposedAPI } from "api_inbusiness";
import styles from "./ProposedEvents.module.scss";

const AddProposedEvent = ({
  open,
  setOpen,
  dataForEdit,
  userId,
  getRefreshedEvents,
  setDataForEdit = () => {},
}) => {
  const addProposedEventRef = useRef();

  const INITIAL_FORM_STATE_PROPOSED_EVENTS = {
    title: dataForEdit?.title ? dataForEdit.title : "",
    description: dataForEdit?.description ? dataForEdit.description : "",
    nrLike: dataForEdit?.nrLike ? dataForEdit.nrLike : 0,
    nrDislike: dataForEdit?.nrDislike ? dataForEdit.nrDislike : 0,
    checked: dataForEdit?.checked ? dataForEdit.checked : false,
  };

  const FORM_VALIDATION_PROPOSED_EVENTS = yup.object().shape({
    title: yup
      .string()
      .typeError("Titlul nu este valid!")
      .trim()
      .min(
        1,
        "Titlul evenimentului propus trebuie să conțină cel puțin un caracter!"
      )
      .required("Titlul este obligatoriu!"),
    description: yup.string().trim().required("Descrierea este obligatorie!"),
  });

  const handleSubmit = () => {
    if (addProposedEventRef.current) {
      addProposedEventRef.current.handleSubmit();
    }
  };

  const handleAddEvent = (values) => {
    const proposalsObject = {};
    proposalsObject.title = values.title;
    proposalsObject.description = values.description;

    dataForEdit !== null
      ? ProposedAPI.updateEvent(dataForEdit.id, proposalsObject).then((res) => {
          if (res.ok) {
            toast.success("Propunerea a fost editată!");
            getRefreshedEvents();
            setDataForEdit(null);
            setOpen(false);
          } else {
            toast.error("Eroare");
          }
        })
      : ProposedAPI.create({ userId, ...proposalsObject }).then((res) => {
          if (res.ok) {
            toast.success("Propunerea a fost postată!");
            getRefreshedEvents();
            setOpen(false);
          } else {
            toast.error("Propunerea nu a putut fi creată!");
          }
        });
  };
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Propune o idee de eveniment"}
      buttonClose={"ÎNAPOI"}
      buttonFinish={"FINALIZEAZĂ"}
      onClickButtonFinish={handleSubmit}
      width="580px"
    >
      <Formik
        innerRef={addProposedEventRef}
        initialValues={{
          ...INITIAL_FORM_STATE_PROPOSED_EVENTS,
        }}
        validationSchema={FORM_VALIDATION_PROPOSED_EVENTS}
        onSubmit={(values) => {
          handleAddEvent(values);
        }}
      >
        <Form className={styles.formWrapperChangeMemberInformation}>
          <TextField name="title" label="Tema evenimentului" size="medium" />
          <TextField
            name="description"
            label="Scurtă descriere a propunerii dvs. (va apărea pe pagină)"
            size="medium"
            multiline
            rows={6}
          />
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddProposedEvent;
