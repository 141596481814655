import React, { useRef } from "react";
import { CustomDialog } from "lib";
import styles from "./ChangeTypeModal.module.scss";
import { getStatusColor, getUserType } from "utils/functions";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { Select, TextField } from "lib";
import { MenuItem } from "@mui/material";
import { UsersAPI } from "api_inbusiness";
import { emailRegex } from "utils/regex";

const ChangeTypeModal = ({
  open,
  setOpen,
  rowDetails,
  triggerRefetch,
  setTriggerRefetch = () => {},
}) => {
  const formRef = useRef();
  const INITIAL_FORM_STATE = {
    type: rowDetails.type,
    email: rowDetails.email,
  };

  const FORM_VALIDATION = yup.object().shape({
    type: yup.string().trim().required("Tipul este obligatoriu!"),
    email: yup
      .string()
      .trim()
      .matches(emailRegex, "Trebuie să introduceți un format de email valid!")
      .required("Adresa de email este obligatorie!"),
  });

  const userTypes = [
    {
      id: "GUEST",
      name: "Invitat",
    },
    {
      id: "MEMBER",
      name: "Membru",
    },
    {
      id: "LEADER",
      name: "Lider",
    },
    {
      id: "MANAGER",
      name: "Manager",
    },
  ];
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={`Schimbare tip ${rowDetails.firstName} ${rowDetails.lastName}`}
      buttonClose={"Înapoi"}
      buttonFinish={"Finalizează"}
      onClickButtonFinish={handleSubmit}
      width="600px"
    >
      <div
        style={{
          backgroundColor: getStatusColor(rowDetails.type),
          padding: "5px",
          borderRadius: "10px",
          textAlign: "center",
          color: "#ffffff",
          marginBottom: "20px",
        }}
      >
        {getUserType(rowDetails.type)}
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          UsersAPI.update(rowDetails.id, values).then((res) => {
            if (res.ok) {
              toast.success("Tipul a fost schimbat cu succes!");
              setTriggerRefetch(!triggerRefetch);
              setOpen(false);
            } else {
              toast.error("A intervenit o eroare!");
            }
          });
        }}
      >
        <div className={styles.mainContainer}>
          <Select name="type" size="medium" label="Tipul utilizatorului*">
            {userTypes.map((type) => {
              return (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              );
            })}
          </Select>
          <TextField name="email" label={"Adresa de email"} size="medium" />
        </div>
      </Formik>
    </CustomDialog>
  );
};

export default ChangeTypeModal;
