import React from "react";
import { Dashboard, TestPageOC, Users, TestForm, TestLayout } from "pages";
import User from "pages/Users/User";
import { Switch, Route } from "react-router-dom";

import News from "pages/News/News";

import Profile from "pages/Profile/Profile";

import ComponentsOverview from "pages/TestPageOC/ComponentsOverview";
import Versions from "pages/Versions/Versions";
import BecomeMember from "pages/BecomeAMember/BecomeMember";
import Education from "pages/Education/Education";
import IndividualEducation from "pages/Education/IndividualEducation";
import Events from "pages/Events/Events";
import Event from "pages/Event/Event";
import AdminViewUsers from "pages/Users/AdminViewUsers";
import QrUserPage from "pages/QR/QrUserPage";
import QrAdminPage from "pages/QR/QrAdminPage";
import VisualizeYoutubeVideo from "pages/Education/VisualizeYoutubeVideo";
import TermsAndConditions from "pages/Terms/TermsAndConditions";
import TermsAndConditionsItem from "pages/Terms/TermsAndConditionsItem";
import ProposedEvents from "pages/ProposedEvents/ProposedEvents";
import Finance from "pages/Finance/Finance";
import Transactions from "pages/Transactions/Transactions";
import Tickets from "pages/Tickets/Tickets";
import Ticket from "pages/Tickets/Ticket";
import RenewalMember from "pages/RenewalMember/RenewalMember";
import SubscriptionNomenclature from "pages/SubscriptionNomenclature/SubscriptionNomenclature";
import UserPayments from "pages/MyPayments/UserPayments";

export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact || false}
      render={(props) => (
        <route.component {...props} routes={route.routes || []} />
      )}
    />
  );
}

const routes = [
  //Dashboard
  {
    path: "/",
    exact: true,
    component: Dashboard,
  },

  /**
   * Routes for NEWS
   */
  {
    path: "/news",
    exact: true,
    component: News,
  },

  /**
   * Routes for USERS
   */
  {
    path: "/users",
    exact: true,
    component: Users,
  },
  {
    path: "/users/:id",
    exact: true,
    component: User,
  },

  {
    path: "/users/admin/view",
    exact: true,
    component: AdminViewUsers,
  },

  // Routes for Finance
  {
    path: "/finances",
    exact: true,
    component: Finance,
  },

  /**
   * Routes for EDUCATION
   */
  {
    path: "/education",
    exact: true,
    component: Education,
  },

  {
    path: "/education/:id",
    exact: true,
    component: IndividualEducation,
  },
  {
    path: "/education/video/:id",
    exact: true,
    component: VisualizeYoutubeVideo,
  },

  /**
   * Routes for EVENTS
   */
  {
    path: "/events",
    exact: true,
    component: Events,
  },

  {
    path: "/events/:id",
    exact: true,
    component: Event,
  },
  {
    path: "/proposedEvents",
    exact: true,
    component: ProposedEvents,
  },

  /**
   * Routes for Transactions
   */

  {
    path: "/transactions",
    exact: true,
    component: Transactions,
  },

  /**
   * Routes for Tickets
   */

  {
    path: "/tickets",
    exact: true,
    component: Tickets,
  },
  {
    path: "/tickets/:id",
    exact: true,
    component: Ticket,
  },

  // Routes for subscriptions nomenclature
  {
    path: "/adminSubscriptions",
    exact: true,
    component: SubscriptionNomenclature,
  },

  /**
   * Routes for NOMENCLATURES
   */

  {
    path: "/becomeMember",
    exact: true,
    component: BecomeMember,
  },
  {
    path: "/renewal",
    exact: true,
    component: RenewalMember,
  },

  // Routes for QR section
  {
    path: "/qr",
    exact: true,
    component: QrUserPage,
  },
  {
    path: "/scan-qr/event/:id",
    exact: true,
    component: QrAdminPage,
  },

  //Terms & Condițions
  {
    path: "/terms",
    exact: true,
    component: TermsAndConditions,
  },
  {
    path: "/terms/:id",
    component: TermsAndConditionsItem,
  },

  /**
   * Route for PROFILE
   */
  {
    path: "/profile",
    exact: true,
    component: Profile,
  },

  // Routes for user subscriptions
  {
    path: "/my-payments",
    exact: true,
    component: UserPayments,
  },

  /**
   * Versions of the CRM
   */
  {
    path: "/versions",
    exact: true,
    component: Versions,
  },

  /**
   * Routes for TESTING
   */
  //Test pages Onofrei Constantin
  {
    path: "/testoc",
    exact: true,
    component: TestPageOC,
  },
  {
    path: "/components-overview",
    exact: true,
    component: ComponentsOverview,
  },
  //Test pages Codrin
  {
    path: "/form-test",
    exact: true,
    component: TestForm,
  },
  {
    path: "/test-layout",
    exact: true,
    component: TestLayout,
  },

  /**
   * Nested route - is just an example
   */
  {
    path: "/nested",
    component: ({ routes }) => (
      <React.Fragment>
        <p>Nested route example</p>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </React.Fragment>
    ),
    routes: [
      {
        path: "/nested/page-1",
        component: () => <React.Fragment>nest here!</React.Fragment>,
      },
    ],
  },
];

export default routes;
