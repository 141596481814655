import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { NavigationButton } from "lib/components/Buttons/buttons";

import EditIcon from "@mui/icons-material/Edit";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WebIcon from "@mui/icons-material/Web";
import DescriptionIcon from "@mui/icons-material/Description";
import BadgeIcon from "@mui/icons-material/Badge";

import { PageLayout } from "lib";

import { UsersAPI, CompaniesAPI } from "api_inbusiness";
import { LoadingSpinner } from "lib";

import { useUser } from "hooks/useUser";
import { Helmet } from "react-helmet";
import DefaultUserPicture from "resources/img/user/DefaultUserPicture.png";
import DefaultCompanyPicture from "resources/img/InBusiness/no_business_logo.jpeg";
import { useCallback } from "react";

import ChangeProfilePicture from "pages/Profile/ChangeProfilePicture";
import styles from "./User.module.scss";

const User = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPhoto, setUserPhoto] = React.useState(null);
  const [companyLogo, setCompanyLogo] = React.useState([]);
  const { user } = useUser();

  const getUserById = useCallback(() => {
    UsersAPI.getById(id).then((res) => {
      setUserState(res.data);
      getUserImage(res.data);
      getCompaniesLogo(res.data);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    getUserById();
  }, [getUserById]);

  const getUserImage = (userState) => {
    const imgId = userState.avatarId;
    if (imgId) {
      UsersAPI.getNewUserPicture(imgId).then((res) => {
        if (res.ok === true) {
          setUserPhoto(res.data);
        }
      });
    }
  };

  const getCompaniesLogo = async (userState) => {
    const logos = [];
    for (const lg of userState.companies) {
      try {
        const res = await new Promise((resolve, reject) => {
          CompaniesAPI.getNewLogoCompanies(lg.logoId).then((res) => {
            if (res.ok === true) {
              resolve(res.data);
            } else {
              reject("Failed to fetch company logo");
            }
          });
        });
        logos.push(res);
      } catch (error) {
        logos.push(false);
      }
    }
    setCompanyLogo(logos);
  };

  return (
    <>
      <Helmet>
        <title>InBUSINESS CRM :: User</title>
      </Helmet>
      <PageLayout
        title={userState?.firstName + " " + userState?.lastName}
        backArrow={true}
      >
        {loading ? (
          <LoadingSpinner loading={loading} margin="20% auto" />
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.mainContainer}>
              <div className={styles.firstContainer}>
                <div className={styles.upperFirst}>
                  <div className={styles.userPhotoContainer}>
                    {userPhoto ? (
                      <img
                        alt="userPhoto"
                        src={userPhoto}
                        className={styles.userPhoto}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        alt="Standard"
                        src={DefaultUserPicture}
                        className={styles.userPhoto}
                        loading="lazy"
                      />
                    )}
                  </div>
                  {userState.id === user.id && (
                    <NavigationButton
                      variant="text"
                      color="blue"
                      startIcon={<EditIcon />}
                      onClick={() => setOpen(true)}
                    >
                      {"Modifică"}
                    </NavigationButton>
                  )}
                </div>
              </div>
              <div className={styles.secondContainer}>
                <h4>Detalii</h4>
                <div className={styles.upperSecond}>
                  <div>
                    <h5>Email</h5>
                    <div className={styles.info}>{userState.email}</div>
                  </div>
                  <div>
                    <h5>Număr de telefon</h5>
                    <div>{userState.phone}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.companiesContainerDetails}>
              {userState?.companies.length > 0 ? (
                userState?.companies.map((comp, index) => (
                  <UserCompanies
                    key={index}
                    company={comp}
                    logo={companyLogo[index]}
                  />
                ))
              ) : (
                <div>
                  <h4>Nu sunt companii disponibile!</h4>
                </div>
              )}
            </div>
          </div>
        )}
      </PageLayout>
      {userState && (
        <ChangeProfilePicture
          open={open}
          setOpen={setOpen}
          userData={userState}
          getUserById={getUserById}
          userPhoto={userPhoto}
        />
      )}
    </>
  );
};

const UserCompanies = ({ company, logo }) => {
  const { name, site, description, phone } = company;

  return (
    <div className={styles.companyDetails}>
      <div className={styles.firstSectionCompany}>
        <div className={styles.companyLogoContainer}>
          <img
            src={logo ? logo : DefaultCompanyPicture}
            alt={name}
            className={styles.companyLogo}
          />
        </div>

        <div className={styles.companyInfos}>
          <div className={styles.companyDetailsIcons}>
            <BadgeIcon />
            {name}
          </div>
          {phone && (
            <div className={styles.companyDetailsIcons}>
              <PhoneAndroidIcon />
              <a className={styles.site} href={"tel:" + phone}>
                {phone}
              </a>
            </div>
          )}

          {site && (
            <div className={styles.companyDetailsIcons}>
              <WebIcon />
              {/* <b>{site}</b> */}
              <b>
                <a
                  href={`https://${site}`}
                  target="_black"
                  className={styles.site}
                >
                  {site}
                </a>
              </b>
            </div>
          )}
        </div>
      </div>

      {description && (
        <div className={styles.companyDescription}>
          <DescriptionIcon />
          {description}
        </div>
      )}
    </div>
  );
};

export default User;
