import React, { useEffect } from "react";
import { EventsAPI } from "api_inbusiness";
import { useParams } from "react-router-dom";
import TimelineList from "lib/components/Timeline/TimelineList/TimelineList";
import TimelineItem from "lib/components/Timeline/TimelineItem/TimelineItem";
import styles from "./Event.module.scss";
import raisisLogo from "resources/img/InBusiness/Raisis_Lockup.png";
import { useUser } from "hooks/useUser";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const Event = () => {
  const { id } = useParams();
  const navigate = useHistory();
  const [event, setEvent] = React.useState([]);
  const { permissionsData } = useUser();

  const getEvent = () => {
    EventsAPI.publicGetById(id).then((res) => setEvent(res.data));
  };
  React.useEffect(() => {
    getEvent();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Eveniment </title>
      </Helmet>
      <div className={styles.mainContainer}>
        <TimelineList>
          <TimelineItem
            id={id}
            title={event.title}
            dateTime={event.date}
            time={event.startTime}
            location={event.location}
            description={event.description}
            countyId={event.county?.name}
            participants={event.participants && event.participants}
            getEvent={getEvent}
          />
        </TimelineList>
        <div className={styles.logoContainer}>
          <img
            src={raisisLogo}
            alt="Raisis Logo"
            className={styles.logoImage}
          />
        </div>
      </div>
    </>
  );
};

export default Event;
