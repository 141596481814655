import React, { useEffect, useState } from "react";
import styles from "./Transactions.module.scss";
import { Helmet } from "react-helmet";
import { PageLayout, CustomTable } from "lib";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import AddIcon from "@mui/icons-material/Add";
import AddTransaction from "./AddTransaction";
import { EventsAPI } from "api_inbusiness";
import { Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Search from "lib/components/Search/Search";

const Transactions = () => {
  const [open, setOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");

  const downloadTickets = (purchaseId) => {
    EventsAPI.getTicketFiles(purchaseId).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "application/zip" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.zip");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  const labels = [
    {
      id: "eventId",
      label: "Eveniment",
      minWidth: 10,
    },
    {
      id: "company",
      label: "Companie",
      minWidth: 10,
    },
    {
      id: "fullName",
      label: "Nume",
      minWidth: 10,
    },
    {
      id: "email",
      label: "Email",
      minWidth: 10,
    },
    {
      id: "phone",
      label: "Număr de telefon",
      minWidth: 10,
    },
    {
      id: "type",
      label: "Tip Achiziție",
      minWidth: 10,
    },
    {
      id: "quantity",
      label: "Cantitate",
      minWidth: 10,
    },
    {
      id: "source",
      label: "Consilier",
      minWidth: 10,
    },
    {
      id: "download",
      label: "Descarcă",
      minWidth: 10,
    },
  ];

  const cellModifier = (row, column, value) => {
    if (column.id === "eventId") {
      return <Typography variant="tableContent">{row.event.title}</Typography>;
    }
    if (column.id === "download") {
      return (
        <DownloadIcon
          style={{ cursor: "pointer" }}
          onClick={() => downloadTickets(row.tickets[0].purchaseId)}
        />
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };
  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Tranzactii</title>
      </Helmet>
      <PageLayout
        title="Tranzacții"
        actionArea={
          <>
            {" "}
            <PrimaryButton
              startIcon={<AddIcon />}
              color="primary"
              onClick={() => setOpen(true)}
            >
              Adaugă Tranzacție
            </PrimaryButton>
            <Search
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              search={search}
              setSearch={setSearch}
              nameToFind={"Caută tranzacție"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setLoading={setLoading}
              setterFunction={setTransactions}
            />
          </>
        }
      >
        <CustomTable
          labels={labels}
          loading={loading}
          setLoading={setLoading}
          tableData={transactions}
          cellModifier={cellModifier}
          getterFunction={EventsAPI.getAllPurchases}
          setterFunction={setTransactions}
          withPagination={true}
          triggerRefetch={triggerRefetch}
          triggerSearch={triggerSearch}
          search={search}
          isSearch={isSearch}
          searchFunction={EventsAPI.searchTransactions}
        />

        <AddTransaction
          open={open}
          setOpen={setOpen}
          setTriggerRefetch={setTriggerRefetch}
        />
      </PageLayout>
    </>
  );
};

export default Transactions;
