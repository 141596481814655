import React, { useCallback, useEffect, useState } from "react";
import { PageLayout } from "lib";
import { useUser } from "hooks/useUser";
import { Helmet } from "react-helmet";
import Subscription from "./Subscription";
import styles from "./BecomeMember.module.scss";
import visaLogo from "../../resources/visa-and-mastercard-logo-26-1024x256.png";
import { SubscriptionsAPI } from "api_inbusiness";
import { toast } from "react-toastify";

import { getMembershipStatus } from "utils/functions";

const BecomeMember = () => {
  const { user } = useUser();
  const [availablePlans, setAvailablePlans] = useState([]);

  const getAvailablePlans = useCallback(() => {
    SubscriptionsAPI.get().then((res) => {
      if (res.ok) {
        setAvailablePlans(res.data.content);
      } else {
        toast.error("A intervenit o eroare la preluarea abonamentelor!");
      }
    });
  }, []);

  useEffect(() => {
    getAvailablePlans();
  }, [getAvailablePlans]);

  const benefits = (availablePlan) => {
    return [
      {
        id: 1,
        text1: `Acces la ${availablePlan === "MONTHLY" ? "2" : "toate"} ${
          availablePlan === "MONTHLY" ? "intalniri" : "intalnirile"
        }  InBusiness ${availablePlan === "MONTHLY" ? "lunare" : ""} `,
        text2:
          "dezbateri de video-uri de business, TED-uri, podcast-uri, articole tematice de afaceri etc",
        text3:
          "sesiuni de mastermind, mai exact posibilitatea de a obtine raspunsuri diversificate si utile din partea grupului de antreprenori si manageri InBusiness, la probleme/nevoi legate de afacerea ta",
      },
      {
        id: 2,
        text1: "Acces gratuit inclusiv la intalnirile cu invitati speciali",
        text2: " pentru non membri va exista o taxa de participare",
      },
      {
        id: 3,
        text1:
          "Networking de calitate si posiblitatea de a genera parteneriate, dar si deal-uri noi pentru business-ul tau",
      },
      {
        id: 4,
        text1: "Acces la documente educationale InBusiness",
        text2:
          "video-uri, cursuri, seminarii/workshop-uri, carti in format audio si video etc",
      },
      {
        id: 5,
        text1:
          "Posibilitatea de a-ti aduce aportul la dezvoltarea comunitatii de afaceri locale",
      },
    ];
  };

  return (
    <>
      <Helmet>
        <title>
          InBusiness CRM ::{" "}
          {getMembershipStatus(user.membership, user.subscription)
            ? "Membership"
            : "Devino membru"}
        </title>
      </Helmet>
      <PageLayout
        title={
          getMembershipStatus(user.membership, user.subscription)
            ? "Membership"
            : "Devino membru"
        }
      >
        {getMembershipStatus(user.membership, user.subscription) ? (
          <div className={styles.mainContainer}>
            <Subscription
              data={{}}
              benefits={benefits(user.subscription?.plan?.recurrence)}
            />
          </div>
        ) : (
          <div className={styles.mainContainer}>
            {availablePlans.map((plan) => (
              <Subscription
                key={plan.id}
                data={plan}
                benefits={benefits(plan.recurrence)}
              />
            ))}
          </div>
        )}

        <div className={styles.paymentsContainer}>
          <img src={visaLogo} alt="visamastercard logo" />
          <div></div>
        </div>
      </PageLayout>
    </>
  );
};

export default BecomeMember;
