import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { LoadingSpinner, PageLayout } from "lib";
import { Helmet } from "react-helmet";
import Search from "lib/components/Search/Search";
import { PrimaryButton } from "lib/components/Buttons/buttons";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import DisplayEvents from "./DisplayEvents";
import FilterEvents from "./FilterEvents";
import AddEvent from "./AddEvent";

import { useUser } from "hooks/useUser";

import { EventsAPI } from "api_inbusiness";

const Events = () => {
  const [events, setEvents] = useState([]);
  const { permissionsData } = useUser();
  const eventsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const [loading, setLoading] = useState(true);

  const [openFilter, setOpenFilter] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({ locationId: "" });

  const [isSearch, setIsSearch] = useState(false);

  const [isFilter, setIsFilter] = useState(false);

  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);

  const [count, setCount] = useState(0);

  const getAllEvents = useCallback(() => {
    EventsAPI.get(eventsPerPage, currentPage, 1).then((res) => {
      if (res.ok) {
        setEvents((prev) => [...prev, ...res.data.content]);
        setCount(res.data.count);
        setLoading(false);
      } else {
        setEvents([]);
        setCount(0);
        setLoading(false);
      }
    });
  }, [currentPage]);

  const resetFilter = () => {
    setIsFilter(false);
    setFilterData({ locationId: "" });
  };

  const resetSearch = () => {
    setIsSearch(false);
    setSearch("");
  };

  const handleMoreEvents = () => {
    setLoading(true);
    setCurrentPage((prev) => prev + 1);
    setLoading(false);
  };

  const searchEvent = () => {
    EventsAPI.searchEvent(search).then((res) => {
      setEvents(res.data);
      setLoading(false);
    });
  };

  const getDefaultEvents = () => {
    if (currentPage > 0) {
      setEvents([]);
      setCurrentPage(0);
    } else {
      EventsAPI.get(eventsPerPage, 0, 1).then((res) => {
        if (res.ok) {
          setEvents(res.data.content);
          setCount(res.data.count);
          setCurrentPage(0);
          setLoading(false);
        } else {
          setEvents([]);
          setCount(0);
          setCurrentPage(0);
          setLoading(false);
        }
      });
    }
  };

  const getInitialEvents = () => {
    EventsAPI.get(eventsPerPage, 0, 1).then((res) => {
      setEvents(res.data.content);
      setCount(res.data.count);
      setCurrentPage(0);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAllEvents();
  }, [currentPage]);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Evenimente</title>
      </Helmet>
      <PageLayout
        title="Evenimente"
        actionArea={
          <>
            <Search
              isFilter={isFilter}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              search={search}
              setSearch={setSearch}
              nameToFind={"Caută eveniment"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setLoading={setLoading}
              setterFunction={setEvents}
              setIsFilter={setIsFilter}
              resetFilter={resetFilter}
              defaultGet={getDefaultEvents}
              searchGet={searchEvent}
            />

            {permissionsData.seeAdminButtons && (
              <PrimaryButton
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => setOpenAdd(true)}
              >
                ADAUGĂ EVENIMENT
              </PrimaryButton>
            )}
          </>
        }
      >
        {loading ? (
          <LoadingSpinner margin="5rem auto" loading={loading} />
        ) : (
          <DisplayEvents
            events={events}
            getAllEvents={getInitialEvents}
            handleMoreEvents={handleMoreEvents}
            search={search}
            hasMore={!isFilter && !isSearch && count > events.length}
          />
        )}
      </PageLayout>

      <FilterEvents
        open={openFilter}
        setOpen={setOpenFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        triggerFilter={triggerFilter}
        setTriggerFilter={setTriggerFilter}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        setLoading={setLoading}
        setData={setEvents}
        resetSearch={resetSearch}
        resetFilter={resetFilter}
      />

      <AddEvent
        open={openAdd}
        setOpen={setOpenAdd}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        getAllEvents={getInitialEvents}
      />
    </>
  );
};

export default Events;
