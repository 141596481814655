import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";

import AddUser from "./AddUser";

import { PrimaryButton } from "lib/components/Buttons/buttons";

import FilterListIcon from "@mui/icons-material/FilterList";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Search from "lib/components/Search/Search";
import { useUser } from "hooks/useUser";

import { PageLayout } from "lib";

import { Helmet } from "react-helmet";

import { UsersAPI } from "api_inbusiness";
import styles from "./Users.module.scss";

import LoadingSpinner from "lib/components/Spinners/LoadingSpinner";
import UsersMemo from "./UsersMemo";

const Users = () => {
  const { permissionsData } = useUser();
  const navigate = useHistory();
  const usersPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [count, setCount] = useState(0);

  const resetSearch = () => {
    setIsSearch(false);
    setSearch("");
  };

  const resetFilter = () => {
    setIsFilter(false);
    setFilterData({});
  };

  const getDefaultUsers = () => {
    if (currentPage > 0) {
      setUsers([]);
      setCurrentPage(0);
    } else {
      UsersAPI.get(usersPerPage, 0, 1).then((res) => {
        setUsers(res.data.content);
        setCount(res.data.count);
        setCurrentPage(0);
        setLoading(false);
      });
    }
  };

  const searchForUser = () => {
    UsersAPI.searchUsers(search).then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  };

  const getUsers = useCallback(() => {
    UsersAPI.get(usersPerPage, currentPage, 1).then((res) => {
      setUsers((prev) => [...prev, ...res.data.content]);
      setCount(res.data.count);
      setLoading(false);
    });
  }, [currentPage]);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleMoreUsers = () => {
    setLoading(true);
    setCurrentPage((prev) => prev + 1);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {"Membri"}</title>
      </Helmet>
      <PageLayout
        title={"Membri"}
        subTitle={"Administrează membri"}
        wide={true}
        actionArea={
          <>
            <Search
              isFilter={isFilter}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              search={search}
              setSearch={setSearch}
              nameToFind={"Caută membru"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setLoading={setLoading}
              setterFunction={setUsers}
              setIsFilter={setIsFilter}
              resetFilter={resetFilter}
              defaultGet={getDefaultUsers}
              searchGet={searchForUser}
            />
            {permissionsData.seeAdminButtons && (
              <>
                {/* <PrimaryButton startIcon={<FilterListIcon />} color="light" onClick={() => setOpenFilter(true)}>
                  {"Filtrează Membri"}
                </PrimaryButton> */}
                <PrimaryButton
                  startIcon={<VisibilityIcon />}
                  color="primary"
                  onClick={() => navigate.push("/users/admin/view")}
                >
                  {"Vezi membri ca admin"}
                </PrimaryButton>
              </>
            )}
          </>
        }
      >
        <div className={styles.userCards}>
          {loading ? (
            <div className={styles.loadingWrapper}>
              <LoadingSpinner loading={loading} margin="1rem auto" />
            </div>
          ) : (
            <UsersMemo users={users} />
          )}
        </div>

        {!isFilter && !isSearch && count > users.length && (
          <div className={styles.loadMoreButton}>
            <PrimaryButton onClick={handleMoreUsers}>
              Încarcă mai mult
            </PrimaryButton>
          </div>
        )}
      </PageLayout>
      <AddUser
        open={open}
        setOpen={setOpen}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
      />
      {/* <FilterUsers
        open={openFilter}
        setOpen={setOpenFilter}
        filterData={filterData}
        setFilterData={setFilterData}
        triggerFilter={triggerFilter}
        setTriggerFilter={setTriggerFilter}
        triggerRefetch={triggerRefetch}
        setTriggerRefetch={setTriggerRefetch}
        setIsFilter={setIsFilter}
        setLoading={setLoading}
        setData={setUsers}
        resetSearch={resetSearch}
        resetFilter={resetFilter}
        isFilter={isFilter}
      /> */}
    </>
  );
};

export default Users;
