import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomDialog } from "lib";
import { MenuItem, Typography } from "@mui/material";
import { UtilityButton } from "lib/components/Buttons/buttons";
import { Avatar } from "@mui/material";

import { FileUploadWrapper } from "lib";
import { uploadSingleFile } from "utils/functions";
import { toast } from "react-toastify";

import DefaultUserPicture from "resources/img/InBusiness/defaultUser.png";

import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { emailRegex, phoneRegex } from "utils/regex";
import { TextField, Select, DatePicker } from "lib";
import { UsersAPI, CountiesAPI } from "api_inbusiness";

import styles from "../Users/Users.module.scss";
import inputStyles from "styles/inputStyles.module.scss";

const AddUser = ({ open, setOpen, triggerRefetch, setTriggerRefetch }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [fileBlob, setFileBlob] = useState(null);
  //TO DO: Change this after to an empty array
  const [locations, setLocations] = useState([]);
  //TO DO: Change this after to an empty array
  const statusList = [
    {
      id: "activeMember",
      name: "Membru",
    },
    {
      id: "leader",
      name: "Lider Club",
    },
    {
      id: "manager",
      name: "Manager",
    },
  ];
  const [loading, setLoading] = useState(false);

  const getLocations = async () => {
    CountiesAPI.get()
      .then((res) => {
        if (res.ok) {
          setLocations(res.data.content);
        } else {
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getLocations();
  }, []);

  const INITIAL_FORM_STATE = {
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    idCounties: "",
    type: "",
    companyDetails: "",
    description: "",
    //TO DO: Delete this later
    password: "string",
    birthday: new Date(),
    emailNotification: false,
  };

  const handleFileUpload = async (e) => {
    await uploadSingleFile(
      e,
      ({ message, blob }) => {
        if (message) {
          toast.error(t(message));
          return;
        }
        setFileBlob(blob);
      },
      "image"
    );
  };

  const FORM_VALIDATION = yup.object().shape({
    firstName: yup
      .string()
      // .typeError(t("The first name is not valid!"))
      .typeError("Prenumele nu este valid!")
      .trim()
      // .min(1, t("The first name must contain at least 1 characters!"))
      // .required(t("The first name is mandatory!")),
      .min(1, "Prenumele trebuie să conțină cel puțin un caracter!")
      .required("Prenumele este obligatoriu!"),
    lastName: yup
      .string()
      // .typeError(t("This last name is not valid!"))
      .typeError("Acest nume de familie nu este valabil!")
      .trim()
      // .min(1, t("The last name must contain at least 1 characters!"))
      // .required(t("The last name is mandatory!")),
      .min(1, "Numele de familie trebuie să conțină cel puțin un caracter!")
      .required("Numele de familie este obligatoriu!"),
    phone: yup
      .string()
      .trim()
      // .matches(phoneRegex, t("The phone number you introduced is not a valid one!"))
      // .required(t("Phone number is mandatory!")),
      .matches(phoneRegex, "Trebuie sa introduceti un numar de telefon valid!")
      .required("Numărul de telefon este obligatoriu!"),
    email: yup
      .string()
      .trim()
      // .matches(emailRegex, t("You must enter a valid email address!"))
      // .required(t("Email address is mandatory!")),
      .matches(emailRegex, "Trebuie să introduceți un format de email valid!")
      .required("Adresa de email este obligatorie!"),
    idCounties: yup
      .string()
      .trim()
      // .required(t("The club location is mandatory!")),
      .required("Locația clubului este obligatorie!"),
    type: yup.string().trim().required("Statusul este obligatoriu!"),
    birthday: yup.date().required("Data de naștere este obligatorie!"),
    emailNotification: yup
      .boolean()
      .required(
        "Este obligatoriu să specificați dacă doriți sau nu să primiți notificari prin email!"
      ),
    companyDetails: yup
      .string()
      .trim()
      .required("Detaliile companiei sunt obligatorii!"),
    description: yup.string().trim().required("Descrierea este obligatorie!"),
  });

  const onSubmitFunc = (values) => {
    setLoading(true);

    UsersAPI.create(values).then((res) => {
      if (res.ok === true) {
        if (fileBlob) {
          UsersAPI.addPicture(res.data.id, fileBlob).then((res) => {
            if (res.ok === true) {
              toast.success(
                t("Profile picture has been uploaded successfully!")
              );
            } else {
              toast.error(
                t(
                  "Something went wrong! Profile picture could not be uploaded successfully!"
                )
              );
            }
          });
        }

        setTriggerRefetch(!triggerRefetch);
        setOpen(false);
        setLoading(false);
        setFileBlob(null);
        toast.success(t("User has been created successfully!"));
      } else {
        if (
          res.error.response.data.errors.message === "The email is already use!"
        ) {
          setLoading(false);
          toast.error(
            t(
              "The email is already use! User could not been created successfully!"
            )
          );
        } else {
          setLoading(false);
          toast.error(t("Something went wrong! User could not been created!"));
        }
      }
    });
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Adaugă membru"}
      buttonClose={"Înapoi"}
      buttonFinish={"Finalizează"}
      onClickButtonFinish={handleSubmit}
      width="100%"
      maxWidth="720px"
      onClickButtonClose={() => {
        setFileBlob(null);
        setOpen(false);
        setLoading(false);
      }}
      buttonFinishLoading={loading}
      buttonCloseDisabled={loading}
    >
      <Formik
        innerRef={formRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        {(props) => {
          return (
            <Form>
              <div className={styles.addUserWrapper}>
                <div className={styles.formColumn}>
                  <TextField name="lastName" label={"Nume*"} size="medium" />
                  <TextField
                    name="firstName"
                    label={"Prenume*"}
                    size="medium"
                  />
                  <TextField name="email" label="Email*" size="medium" />
                  <TextField
                    name="phone"
                    label="Număr de telefon*"
                    size="medium"
                  />
                  <Select name="idCounties" label={"Județ*"} size="medium">
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.id} sx>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select name="type" label={"Status*"} size="medium">
                    {statusList.map((status) => {
                      return (
                        <MenuItem key={status.id} value={status.id} sx>
                          {status.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <DatePicker name="birthday" label="Ziua de naștere" />
                </div>

                <div className={styles.formColumn}>
                  <div
                    className={styles.imageWrapper}
                    style={{ marginBottom: "16px" }}
                  >
                    <Typography className={styles.textImage}>
                      {t("Adaugă o imagine de profil")}
                    </Typography>
                    <div className={styles.imageContainer}>
                      <Avatar
                        src={
                          fileBlob
                            ? URL.createObjectURL(fileBlob)
                            : DefaultUserPicture
                        }
                        className={styles.avatar}
                      />
                      <FileUploadWrapper onUpload={handleFileUpload}>
                        <UtilityButton
                          variant="contained"
                          type="button"
                          height="41px"
                        >
                          {fileBlob ? t("CHANGE PHOTO") : t("UPLOAD")}
                          <input
                            name="image"
                            hidden
                            accept="image/*"
                            type="file"
                          />
                        </UtilityButton>
                      </FileUploadWrapper>
                    </div>
                  </div>
                  <div
                    aria-labelledby="checkbox-group"
                    className={inputStyles.checkboxGroupWrapper}
                    onBlur={props.handleBlur}
                    style={{ margin: "20px 0" }}
                  >
                    <div className={inputStyles.checkboxGroup}>
                      <label>
                        <Field type="checkbox" name="emailNotification" />
                        Doriți să primiți notificări prin email?
                      </label>
                    </div>
                    {props.errors.emailNotification &&
                    props.touched.emailNotification ? (
                      <div className={inputStyles.checkboxGroupError}>
                        {props.errors.emailNotification}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <TextField
                    name="companyDetails"
                    label={"Detalii Companie"}
                    size="medium"
                    multiline
                    rows={4}
                  />
                  <TextField
                    name="description"
                    label={"Scurta descriere"}
                    size="medium"
                    multiline
                    rows={4}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CustomDialog>
  );
};

export default AddUser;
