import React, { useEffect, useRef, useState } from "react";
import { CustomDialog, Select } from "lib";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";

import { EventsAPI } from "api_inbusiness";
import { MenuItem } from "@mui/material";

const DownloadTicketsModal = ({ open, setOpen }) => {
  const ticketsRef = useRef();
  const [futureCustomEvents, setCustomFutureEvents] = useState([]);

  const INITIAL_FORM_STATE_TICKETS = {
    eventId: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    eventId: yup.string().required("Evenimentul trebuie ales!"),
  });

  const getFutureCustomEvents = async () => {
    EventsAPI.getFutureCustomEvents().then((res) => {
      if (res.ok === true) setCustomFutureEvents(res.data);
      else {
        toast.error("A intervenit o eroare la preluarea evenimentelor!");
      }
    });
  };

  useEffect(() => {
    getFutureCustomEvents();
  }, [open]);

  const handleSubmit = () => {
    if (ticketsRef.current) {
      ticketsRef.current.handleSubmit();
    }
  };

  const onSubmitFunc = (values) => {
    EventsAPI.downloadListOfTickets(values.eventId).then((res) => {
      if (res.ok === true) {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Listă bilete - ${new Date().toLocaleDateString()} .xlsx`;

        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setOpen(false);
        toast.success("Biletele au fost descărcate cu succes!");
      } else {
        toast.error("A intervenit o eroare la descărcarea biletelor");
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Descarcă listă bilete"}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={handleSubmit}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        innerRef={ticketsRef}
        initialValues={{
          ...INITIAL_FORM_STATE_TICKETS,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        <Form>
          <Select name="eventId" size="medium" label="Eveniment special">
            {futureCustomEvents.map((event) => {
              return (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              );
            })}
          </Select>
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default DownloadTicketsModal;
