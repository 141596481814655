import React, { useState, useRef, useEffect } from "react";
import { CustomDialog, DatePicker, TextField } from "lib";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import styles from "./AddMembershipModal.module.scss";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ParticipantsAPI, PaymentsAPI, PeriodsAPI } from "api_inbusiness";
import { toast } from "react-toastify";
import LoadingSpinner from "lib/components/Spinners/LoadingSpinner";

const Row = ({
  memberships,
  setMemberships,
  getUserMemberships = () => {},
}) => {
  const rowMembershipRef = useRef();

  const INITIAL_FORM_STATE_ROW = {
    amount: memberships.amount > -1 ? memberships.amount : null,
    startDate: memberships.start,
    endDate: memberships.end,
    notes: memberships.notes,
  };

  const FORM_VALIDATION_ROW = yup.object().shape({
    amount: yup
      .string()
      .typeError("Suma nu este validă")
      .trim()
      .required("Suma plătită este obligatorie"),
    startDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de început este obligatorie"),
    endDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de sfârșit este obligatorie"),
  });

  const updatePeriod = (data) => {
    const objForEdit = { ...data };
    objForEdit.amount = Number(objForEdit.amount);

    PeriodsAPI.update(memberships.id, objForEdit).then((res) => {
      if (res.ok === true) {
        toast.success("Perioadă modificată cu succes");
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  const deleteMembership = () => {
    PeriodsAPI.delete(memberships.id).then((res) => {
      if (res.ok === true) {
        toast.success("Perioada a fost ștearsă cu succes");
        getUserMemberships();
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  return (
    <Formik
      innerRef={rowMembershipRef}
      initialValues={{
        ...INITIAL_FORM_STATE_ROW,
      }}
      validationSchema={FORM_VALIDATION_ROW}
      onSubmit={(values) => {
        alert("APASAT");
      }}
    >
      {(props) => {
        return (
          <Form>
            <div className={styles.mainWrapperRow}>
              <div className={styles.dot}></div>
              <div className={styles.membershipWrapper}>
                <TextField name="amount" label={"Sumă*"} size="medium" />

                <div className={styles.editButtons}>
                  <div
                    className={styles.editPaymentButton}
                    onClick={() => updatePeriod(props.values)}
                  >
                    <ModeEditIcon />
                  </div>
                  <div
                    className={styles.deletePaymentButton}
                    onClick={() => deleteMembership()}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
              <div className={styles.datesContainer}>
                <DatePicker name="startDate" label="Data început" />
                <DatePicker name="endDate" label="Data sfârșit" />
              </div>
              <div>
                <TextField
                  name="notes"
                  label={"Observații"}
                  size="medium"
                  multiline
                  rows={2}
                />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const AddMembershipModal = ({ open, setOpen, rowDetails }) => {
  const membershipRef = useRef();
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserMemberships = () => {
    setLoading(true);
    PaymentsAPI.getByUserId(rowDetails.id).then((res) => {
      if (res.ok === true) {
        setMemberships(res.data);
        setLoading(false);
      } else {
        toast.error("A intervenit o eroare!");
        setMemberships([]);
        setLoading(false);
      }
    });
  };

  const addUserMembership = (data) => {
    const modelObject = { ...data };
    // modelObject.userId = rowDetails.id;
    modelObject.amount = Number(modelObject.amount);

    PeriodsAPI.addManualMembership(rowDetails.id, modelObject).then((res) => {
      if (res.ok === true) {
        toast.success("Perioada adaugata cu succes");
        getUserMemberships();
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  useEffect(() => {
    if (open) {
      getUserMemberships();
    }
  }, [open]);

  const INITIAL_FORM_STATE = {
    amount: "",
    startDate: new Date(),
    endDate: new Date(),
    notes: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    amount: yup
      .string()
      .typeError("Suma nu este validă")
      .trim()
      .required("Suma plătită este obligatorie"),
    startDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de început este obligatorie"),
    endDate: yup
      .date()
      .typeError("Alegeți o dată validă!")
      .required("Data de sfârșit este obligatorie"),
  });

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={`Adaugă perioadă - ${rowDetails.firstName} ${rowDetails.lastName}`}
      buttonClose={"Renunță"}
      width="650px"
      maxWidth="850px"
    >
      <Formik
        innerRef={membershipRef}
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {(props) => {
          return (
            <div>
              <Form>
                <div className={styles.mainWrapper}>
                  <div className={styles.membershipWrapper}>
                    <TextField name="amount" label={"Sumă*"} size="medium" />

                    <div
                      className={styles.addPaymentButton}
                      onClick={() =>
                        // setMemberships([...memberships, props.values])
                        addUserMembership(props.values)
                      }
                    >
                      <AddIcon />
                    </div>
                  </div>
                  <div className={styles.datesContainer}>
                    <DatePicker name="startDate" label="Data început" />
                    <DatePicker name="endDate" label="Data sfârșit" />
                  </div>
                  <div>
                    <TextField
                      name="notes"
                      label={"Observații"}
                      size="medium"
                      multiline
                      rows={2}
                    />
                  </div>
                </div>
              </Form>
              {loading ? (
                <div className={styles.LoadingSpinner}>
                  <LoadingSpinner loading={loading} margin="1rem auto" />{" "}
                </div>
              ) : //daca primim array, inseamna ca utilizatorul are subscriptii, in caz contrar, afisam ca nu are (aici vine obiect, din aceasta cauza verificam sa fie array)
              Array.isArray(memberships) ? (
                memberships.map((membr) => (
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Row
                      key={membr.id}
                      memberships={membr}
                      setMemberships={setMemberships}
                      getUserMemberships={getUserMemberships}
                    />
                  </div>
                ))
              ) : (
                <div className={styles.noPayments}> Nu există subscripții</div>
              )}
            </div>
          );
        }}
      </Formik>
    </CustomDialog>
  );
};

export default AddMembershipModal;
