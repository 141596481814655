import { CustomTable, PageLayout } from "lib";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { MySubscriptionsAPI } from "api_inbusiness";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { toast } from "react-toastify";

const statusDictionary = {
  MANUAL: "Manual",
  PENDING: "În așteptare",
  PAID: "Plătit",
  CANCELED: "Anulată",
};

const labels = [
  {
    id: "index",
    label: "Nr. Crt.",
    minWidth: 10,
  },
  {
    id: "date",
    label: "Data de început",
    minWidth: 10,
  },
  {
    id: "end",
    label: "Data de sfârșit",
    minWidth: 10,
  },
  {
    id: "amount",
    label: "Suma plătită",
    minWidth: 10,
  },
  {
    id: "notes",
    label: "Observații",
    minWidth: 10,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 10,
  },
  {
    id: "actions",
    label: "Acțiuni",
    minWidth: 10,
  },
];

const downloadSubscriptionInvoice = (invoiceId, userName) => {
  MySubscriptionsAPI.getPaymentInvoice(invoiceId).then((res) => {
    if (res.ok) {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "file/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Factura ${userName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      toast.success("Factura a fost descărcată cu succes!");
    }
  });
};

const cellModifier = (row, column, value) => {
  if (column.id === "date") {
    return <Typography>{dayjs(row.date).format("DD/MM/YYYY")}</Typography>;
  }

  if (column.id === "end") {
    return <Typography>{dayjs(row.end).format("DD/MM/YYYY")}</Typography>;
  }
  if (column.id === "amount") {
    return <Typography>{row.amount} lei</Typography>;
  }
  if (column.id === "status") {
    return <Typography>{statusDictionary[row.status]}</Typography>;
  }

  if (column.id === "actions") {
    const userName = row.user.firstName + row.user.lastName + "Factura";
    return row.invoice !== null ? (
      <DownloadIcon
        style={{ cursor: "pointer" }}
        onClick={() => downloadSubscriptionInvoice(row.invoice, userName)}
      />
    ) : (
      <div></div>
    );
  }
  return <Typography variant="tableContent">{value}</Typography>;
};
const UserPayments = () => {
  const [myPayments, setMyPayments] = useState([]);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: Abonamentele mele</title>
      </Helmet>
      <PageLayout title="Abonamentele mele">
        <CustomTable
          labels={labels}
          loading={loading}
          setLoading={setLoading}
          cellModifier={cellModifier}
          tableData={myPayments}
          withPagination={true}
          setterFunction={setMyPayments}
          triggerRefetch={triggerRefetch}
          getterFunction={MySubscriptionsAPI.get}
        />
      </PageLayout>
    </>
  );
};

export default UserPayments;
