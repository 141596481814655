import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { CustomTable, PageLayout, Tooltip } from "lib";
import { useParams, useHistory } from "react-router-dom";
import Search from "lib/components/Search/Search";
import {
  PrimaryButton,
  EditButton,
  RemoveButton,
} from "lib/components/Buttons/buttons";
import AddIcon from "@mui/icons-material/Add";

import { Typography } from "@mui/material";

import AddEducationResource from "./AddEducationResource";
import { EducationAPI } from "api_inbusiness";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styles from "./Education.module.scss";
import ConfirmModal from "lib/components/Modals/ConfirmModal";
import { toast } from "react-toastify";
import { useUser } from "hooks/useUser";
import WrapperTableCards from "./WrapperTableCards";
import useWindowDimensions from "hooks/useWindowDimensions";

const IndividualEducation = () => {
  const { id } = useParams();
  const { vw } = useWindowDimensions();
  const { permissionsData } = useUser();
  const navigate = useHistory();
  const [open, setOpen] = useState(false);
  const [individualCategory, setIndividualCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowState, setRowState] = useState(null);
  const [search, setSearch] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const labels = [
    {
      id: "name",
      label: "Nume",
      minWidth: 10,
    },
    // {
    //   id: "link",
    //   label: "Link",
    //   minWidth: 10,
    // },
    // {
    //   id: "youtube",
    //   label: "Cod Youtube",
    //   minWidth: 10,
    // },
    {
      id: "actions",
      label: "Actiuni",
      minWidth: 10,
    },
  ];

  const getResources = () => {
    EducationAPI.getByCategoryId(id).then((res) =>
      setIndividualCategory(res.data.content)
    );
  };

  const cellModifier = (row, column, value) => {
    if (column.id === "actions") {
      return (
        <div className={styles.actionAreaIndividualEducation}>
          {permissionsData.seeAdminButtons && (
            <>
              <EditButton
                color="#1860D2"
                onClick={() => {
                  setRowState(row);
                  setOpen(true);
                }}
              />
              <RemoveButton
                onClick={() => {
                  setRowState(row);
                  setOpenDelete(true);
                }}
              />
            </>
          )}

          <Tooltip position="left" textTip={"Vezi mai mult"} followCursor>
            <ArrowForwardIosIcon
              sx={{ color: "#808080", cursor: "pointer" }}
              onClick={() =>
                navigate.push({
                  pathname: `/education/video/${row.id}`,
                  state: row,
                })
              }
            />
          </Tooltip>
        </div>
      );
    }
    return <Typography variant="tableContent">{value}</Typography>;
  };

  const resetFilter = () => {
    setIsFilter(false);
  };

  const headers = {
    name: "Nume",
    actions: "Actiuni",
  };

  return (
    <>
      <Helmet>
        <title>InBusiness CRM :: {"Educatie"}</title>
      </Helmet>
      <PageLayout
        backArrow={true}
        title={"Educatie"}
        subTitle={"Manageriaza resursele"}
        actionArea={
          <React.Fragment>
            <Search
              search={search}
              setSearch={setSearch}
              isSearch={isSearch}
              setIsSearch={setIsSearch}
              nameToFind={"Cauta resursă"}
              triggerRefetch={triggerRefetch}
              setTriggerRefetch={setTriggerRefetch}
              triggerSearch={triggerSearch}
              setTriggerSearch={setTriggerSearch}
              setterFunction={setIndividualCategory}
              setLoading={setLoading}
              setIsFilter={setIsFilter}
              resetFilter={resetFilter}
            />

            {permissionsData.seeAdminButtons && (
              <PrimaryButton
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => {
                  setRowState(null);
                  setOpen(true);
                }}
              >
                {"Adauga resursă"}
              </PrimaryButton>
            )}
          </React.Fragment>
        }
      >
        <div style={{ display: vw > 1080 ? "block" : "none " }}>
          <CustomTable
            labels={labels}
            tableData={individualCategory}
            cellModifier={cellModifier}
            loading={loading}
            setLoading={setLoading}
            getterFunction={EducationAPI.getByCategoryId}
            additionalId={id}
            setterFunction={setIndividualCategory}
            withPagination={true}
            triggerRefetch={triggerRefetch}
            searchFunction={EducationAPI.searchEducationResource}
            search={search}
            isSearch={isSearch}
            triggerSearch={triggerSearch}
          />
        </div>
        <div style={{ display: vw > 1080 ? "none" : "block " }}>
          <div className={styles.wrapperTableCards}>
            <WrapperTableCards
              columnModifier={cellModifier}
              cardHeaders={headers}
              data={individualCategory}
            />
          </div>
        </div>
        <AddEducationResource
          open={open}
          setOpen={setOpen}
          rowState={rowState}
          getResources={getResources}
          id={id}
          triggerRefetch={triggerRefetch}
          setTriggerRefetch={setTriggerRefetch}
        />

        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          text="Sunteți sigur că doriți să ștergeti acest material?"
          onClickButtonYes={() =>
            EducationAPI.deleteResource(rowState.id).then((res) => {
              if (res.ok === true) {
                getResources();
                setOpenDelete(false);
                toast.success("Resursa a fost ștearsă cu succes!");
              } else {
                toast.error("A intervenit o eroare!");
              }
            })
          }
        />
      </PageLayout>
    </>
  );
};

export default IndividualEducation;
