import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./NewsItem.module.scss";
import DOMPurify from "dompurify";
import DefaultUserPicture from "resources/img/InBusiness/defaultUser.png";

import { Avatar } from "@mui/material";
import {
  EditButton,
  RemoveButton,
  UtilityButton,
} from "lib/components/Buttons/buttons";
import ConfirmModal from "lib/components/Modals/ConfirmModal";
import { NewsAPI, UsersAPI } from "api_inbusiness";
import { getUserType } from "utils/functions";
import { useUser } from "hooks/useUser";
import { toast } from "react-toastify";

const NewsItem = ({ news, getNews, handleAddNewsEdit }) => {
  const { permissionsData } = useUser();
  const [profilePic, setProfilePic] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { data: imgUrl } = await UsersAPI.getNewUserPicture(
        news.user.avatarId
      );
      setProfilePic(imgUrl);
    })();
  }, []);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleDeleteNews = async (id) => {
    try {
      const res = await NewsAPI.delete(id);
      if (res.ok) {
        getNews();
        toast.success("Ștergerea a fost efectuată cu succes!");
      }
    } catch (error) {
      toast.error("A intervenit o eroare!");
    }
  };

  const iframeHeight = useCallback(() => {
    if (!iframeRef.current) return;

    let height = `${iframeRef.current.contentWindow.document.body.scrollHeight}`;
    height = Number(height) + 40;

    return height;
  }, [iframeRef]);

  const handleCalculateIframeHeight = useCallback(() => {
    if (showMore) {
      const height = iframeHeight();

      if (height <= 200) return "200px";

      return `${height}px`;
    } else {
      return "100px";
    }
  }, [showMore, iframeHeight]);

  return (
    <React.Fragment>
      <div className={styles.newsContainer}>
        <div className={styles.userSection}>
          <div className={styles.infoSection}>
            <div className={styles.avatarBox}>
              <Avatar
                src={profilePic ? profilePic : DefaultUserPicture}
                className={styles.avatar}
              />
            </div>
            <p className={styles.paragraphInfo}>
              {news.user?.firstName} {news.user?.lastName} -{" "}
              {getUserType(news.user?.type)}
            </p>
          </div>
          {permissionsData.seeAdminButtons && (
            <div className={styles.actionsSection}>
              <EditButton
                color="#1860D2"
                onClick={() => handleAddNewsEdit(news)}
              />
              <RemoveButton
                color="#1860D2"
                onClick={() => setOpenConfirm(true)}
              />
            </div>
          )}
        </div>
        <div className={styles.newsSection}>
          <h4 className={styles.title}>{news.title}</h4>
          <div className={styles.description}>
            <iframe
              ref={iframeRef}
              style={{
                height: handleCalculateIframeHeight(),
              }}
              title={news.title}
              srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                news.description
              )}`}
            />
          </div>
          {iframeHeight() > 200 && (
            <div className={styles.button}>
              <UtilityButton onClick={toggleShowMore}>
                {showMore ? "Vezi mai puțin" : "Vezi mai mult"}...
              </UtilityButton>
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        text={"Sunteți sigur că doriți să ștergeți această știre?"}
        buttonYes={"DA"}
        buttonNo={"NU"}
        onClickButtonYes={() => {
          handleDeleteNews(news.id);
          setOpenConfirm(false);
        }}
        open={openConfirm}
        setOpen={setOpenConfirm}
      />
    </React.Fragment>
  );
};

export default NewsItem;
