import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";

import axios from "../api_inbusiness/axios";

const initialState = {
  user: {},
  permissions: [],
  accessToken: undefined,
  setAccessToken: () => {},
};

const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [user, setUser] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [loadingUser, setLoadingUser] = useState(true);
  const [permissionsData, setPermissionsData] = useState({
    seeSection: false,
    seeAdminButtons: false,
    participateEvent: false,
  });

  const me = async () => {
    try {
      const response = await axios.get("/users/me");
      checkPermission(response.data.type);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  const getUpdatedUser = async () => {
    const refreshUser = await me();

    setUser(refreshUser);
  };

  const can = useCallback(
    (permissionId) => {
      return 1;
    },
    [permissions]
  );

  const checkPermission = (type) => {
    switch (type) {
      case "MEMBER":
        permissionsData.seeSection = true;
        permissionsData.seeAdminButtons = false;
        permissionsData.becomeMember = false;
        permissionsData.participateEvent = true;

        break;
      case "EX_MEMBER":
        permissionsData.seeSection = false;
        permissionsData.seeAdminButtons = false;
        permissionsData.becomeMember = true;
        permissionsData.participateEvent = false;
        break;
      case "GUEST":
        permissionsData.seeSection = false;
        permissionsData.seeAdminButtons = false;
        permissionsData.becomeMember = true;
        permissionsData.participateEvent = true;
        break;
      case "LEADER":
        permissionsData.seeSection = true;
        permissionsData.seeAdminButtons = true;
        permissionsData.becomeMember = false;
        permissionsData.participateEvent = true;
        break;
      case "MANAGER":
        permissionsData.seeSection = true;
        permissionsData.seeAdminButtons = true;
        permissionsData.becomeMember = false;
        permissionsData.participateEvent = true;
        break;
      default:
        permissionsData.seeSection = false;
        permissionsData.seeAdminButtons = false;
        permissionsData.becomeMember = false;
        permissionsData.participateEvent = false;
        break;
    }
  };

  const handleAccessTokenChange = async () => {
    if (!user.id && accessToken) {
      localStorage.setItem("accessToken", accessToken);
      axios.defaults.headers.common.Authorization = "Bearer " + accessToken;

      setLoadingUser(true);
      const user = await me();

      /**
       * [FIX]
       * We have to explicitly check if user has a role (only for older users)
       * There may be some users that do not have a roleId
       */
      // if (user.roleId) {
      //   const { data } = await RolesAPI.getById(user.roleId);
      //   setPermissions(data);
      // }

      setUser(user);
    } else if (!accessToken) {
      /**
       * Log out
       * Reset important values
       */
      localStorage.removeItem("accessToken");
      setUser({});
      setPermissions([]);
    }

    setLoadingUser(false);
  };

  useEffect(() => {
    handleAccessTokenChange();
  }, [accessToken]);

  return (
    <UserContext.Provider
      value={{
        user,
        loadingUser,
        accessToken,
        setAccessToken,
        can,
        getUpdatedUser,
        permissionsData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
