import axios from "./axios";

const Events = {
  /**
   * Fetches all Events's
   *
   * @return {array} Array of Events's
   */
  async get(perPage, currentPage, pagesToLoad = 1) {
    try {
      const res = await axios.get("/events", {
        params: { perPage, currentPage, pagesToLoad },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Fetches one Events by it's ID
   *
   * @param {string} id ID of the Events
   * @return {object} Events with the corresponding ID
   */
  async getById(eventsId) {
    try {
      const res = await axios.get("/events/" + eventsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetches one public  Event by it's ID
   *
   * @param {string} id ID of the Events
   * @return {object} Events with the corresponding ID
   */
  async publicGetById(eventsId) {
    try {
      const res = await axios.get("/events/public/" + eventsId);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one Events
   *
   * @param {object} events Object with the creation data
   * @return {object} Newly created Events
   */
  async create(events) {
    let reqBody = { ...events };
    try {
      const res = await axios.post("/events", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Creates one transaction per event
   *
   * @param {object} events Object with the creation data
   *
   */
  async purchaseTicket(data) {
    let reqBody = { ...data };
    try {
      const res = await axios.post(
        `/events/purchase_tickets?eventId=${reqBody.eventId}&quantity=${reqBody.quantity}&value=${reqBody.value}&fullName=${reqBody.fullName}&email=${reqBody.email}&phone=${reqBody.phone}&company=${reqBody.company}`
      );
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetch all the purchases
   *
  
   */
  async getAllPurchases(perPage, currentPage, pagesToLoad = 1) {
    try {
      const res = await axios.get("/events/purchases", {
        params: { perPage, currentPage, pagesToLoad },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetch all the tickets
   *
  
   */
  async getAllTickets(perPage, currentPage, pagesToLoad = 1) {
    try {
      const res = await axios.get("/events/tickets", {
        params: { perPage, currentPage, pagesToLoad },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Scans ticket
   *
  
   */
  async scanTicket(ticketId, eventId) {
    try {
      const res = await axios.post(
        `/events/scan_ticket?ticketId=${ticketId}&eventId=${eventId}`
      );
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      // console.table(error, "api carnat");
      throw error;
    }
  },

  /**
   * Scans ticket
   *
  
   */
  async manualScanTicket(ticketId) {
    try {
      const res = await axios.patch(
        `/events/manual/scan_ticket?ticketId=${ticketId}`
      );
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      // console.table(error, "api carnat");
      throw error;
    }
  },

  /**
   * Fetch 1 ticket by id
   *
  
   */
  async getTicket(id) {
    try {
      const res = await axios.get(`/events/ticket/${id}`);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Update ticked by ID
   *
   * @param {string} eventsId ID of the ticket to be updated

   */

  async updateTicket(id, ticket) {
    const obj = { ...ticket };

    try {
      const res = await axios.patch("/events/ticket/" + id, ticket);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: error,
      };
    }
  },

  /**
   * Get ticket files
   *
  
   */
  async getTicketFiles(purchaseId) {
    try {
      const res = await axios.get(
        `/events/ticket_files?purchaseId=${purchaseId}`,
        { responseType: "arraybuffer" }
      );
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Send one email
   *
   * @param {object} events Object with the email data
   * @return {object} Newly created email
   */
  async sendEmail(details) {
    let reqBody = { ...details };
    try {
      const res = await axios.post("/emails", reqBody);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Update Event by ID
   *
   * @param {string} eventsId ID of the Events to be updated
   * @return {string} ID of the updated Events
   */

  async update(id, event) {
    try {
      const res = await axios.patch("/events/" + id, event);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: error,
      };
    }
  },

  /**
   * Deletes Events by ID
   *
   * @param {string} eventsId ID of the Events to be deleted
   * @return {string} ID of the deleted Events
   */
  async delete(eventsId) {
    try {
      await axios.delete("/events/" + eventsId);
      return {
        ok: true,
        data: {
          id: eventsId,
        },
      };
    } catch (error) {
      return {
        ok: false,
        data: {
          id: eventsId,
        },
      };
    }
  },
  /**
   * Fetches events for each user
   *
   * @return {array} Array of Events's
   */
  async getEventsByUserId(userId) {
    try {
      const res = await axios.get("/events/events/byUserId", {
        params: {
          userId,
        },
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: [],
      };
    }
  },

  /**
   * Add user to event presence
   *
   * @param {object} events Object with the creation data
   * @return {object} Newly created presence object
   */
  async createUserPresence(presence) {
    let reqBody = { ...presence };

    try {
      const res = await axios.post(
        `/events/confirmPresence?userId=${reqBody.userId}&eventId=${reqBody.eventId}`
      );
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetches all events that contain the the specified string
   *
   * @param {string} text used to find events
   * @return {object} Array of events
   */
  async searchEvent(text) {
    try {
      const res = await axios.get(`/events/findString/text?text=${text}`);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Fetches future custom events
   *
   *
   * @return {object} Future custom events
   */
  async getFutureCustomEvents() {
    try {
      const res = await axios.get("/events/future_custom_events");
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Searches all tickets that contain the the specified string
   *
   * @param {string} text used to find ticket
   * @return {object} Array of tickets
   */
  async searchTicket(text) {
    try {
      const res = await axios.get(`events/tickets/text?text=${text}`);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  /**
   * Searches all transactions that contain the the specified string
   *
   * @param {string} text used to find transaction
   * @return {object} Array of transactions
   */
  async searchTransactions(text) {
    try {
      const res = await axios.get(`events/purchases/text?text=${text}`);
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },

  async downloadListOfTickets(eventId) {
    try {
      const res = await axios.get(`reports?eventId=${eventId}`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      });
      return { ok: true, data: res.data };
    } catch (error) {
      return {
        ok: false,
        data: {},
      };
    }
  },
};

export default Events;
