import React from "react";
import UserCard from "./UserCard/UserCard";
import styles from "./Users.module.scss";

const UsersMemo = React.memo(({ users }) => {
  return (
    <div className={styles.userCards}>
      {users.map((user) => (
        <UserCard user={user} key={user.id} />
      ))}
    </div>
  );
});

export default UsersMemo;
