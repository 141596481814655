import React, { useEffect, useRef, useState } from "react";
import { CustomDialog, Select, TextField, DatePicker, TimePicker } from "lib";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import { emailRegex, phoneRegex } from "utils/regex";
import { EventsAPI } from "api_inbusiness";
import { MenuItem } from "@mui/material";
import styles from "./Transactions.module.scss";

const AddTransaction = ({
  open,
  setOpen = () => {},
  setTriggerRefetch = () => {},
}) => {
  const transactionRef = useRef();
  const [futureCustomEvents, setCustomFutureEvents] = useState([]);

  const getFutureCustomEvents = async () => {
    EventsAPI.getFutureCustomEvents().then((res) =>
      setCustomFutureEvents(res.data)
    );
  };

  useEffect(() => {
    getFutureCustomEvents();
  }, []);

  const INITIAL_FORM_STATE_TRANSACTIONS = {
    company: "",
    phone: "",
    email: "",
    fullName: "",
    value: "",
    quantity: "",
    eventId: "",
    type: "",
  };

  const FORM_VALIDATION = yup.object().shape({
    company: yup.string().trim().required("Numele companiei este obligatoriu"),
    phone: yup
      .string()
      .trim()
      .matches(phoneRegex, "Introduceți un număr de telefon valid!")
      .required("Numărul de telefon este obligatoriu!"),
    email: yup
      .string()
      .trim()
      .matches(emailRegex, "Introduceți o adresă de email validă!")
      .required("Adresa de email este obligatorie!"),
    fullName: yup.string().trim().required("Numele complet este obligatoriu!"),
    value: yup.number().required("Această valoare este obligatorie"),
    quantity: yup.number().required("Această valoare este obligatorie!"),
    eventId: yup.string().required("Evenimentul trebuie ales!"),
    type: yup.string().required("Tipul achiziției este obligatoriu!"),
  });

  const handleSubmit = () => {
    if (transactionRef.current) {
      transactionRef.current.handleSubmit();
    }
  };

  const onSubmitFunc = (values) => {
    EventsAPI.purchaseTicket(values).then((res) => {
      if (res.ok === true) {
        toast.success("Tranzacția a fost adăugată cu succes!");
        setOpen(false);
        setTriggerRefetch((prev) => !prev);
      } else {
        toast.error("A intervenit o eroare!");
      }
    });
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={"Adaugă tranzacție "}
      buttonClose="ÎNAPOI"
      buttonFinish="FINALIZEAZĂ"
      onClickButtonFinish={handleSubmit}
      width="580px"
      onClickButtonClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        innerRef={transactionRef}
        initialValues={{
          ...INITIAL_FORM_STATE_TRANSACTIONS,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          onSubmitFunc(values);
        }}
      >
        <Form className={styles.mainContainer}>
          <Select name="eventId" size="medium" label="Eveniment special">
            {futureCustomEvents.map((event) => {
              return (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              );
            })}
          </Select>
          <TextField name="company" label="Companie" size="medium" />
          <TextField name="phone" label="Număr de telefon" size="medium" />
          <TextField name="email" label="Adresă de email" size="medium" />
          <TextField name="fullName" label="Nume Complet" size="medium" />
          <TextField name="value" label="Valoare totală" size="medium" />
          <TextField name="quantity" label="Cantitate" size="medium" />
          <TextField name="type" label="Tip Achiziție" size="medium" />
        </Form>
      </Formik>
    </CustomDialog>
  );
};

export default AddTransaction;
