import React, { useState } from "react";
import { PageLayout } from "lib";

import { Typography } from "@mui/material";
import Search from "lib/components/Search/Search";

const ComponentsOverview = () => {
  const [suggestionSearch, setSuggestionSearch] = useState("");
  const suggestionList = [
    { id: 1, name: "Arcadie" },
    { id: 2, name: "Codrin" },
    { id: 3, name: "Alin" },
    { id: 4, name: "Razvan" },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <PageLayout
      title="Pagina de testare & vizualizare componente"
      subTitle="Fiecare componenta trebuie pusa aici cu un exemplu de incardare in aplicatie"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "64px" }}>
        {/* 
                -----------------------------------------------------------------------------------------------
                Suggestion list example
                We have a list of people and we can filter them by typing in the input
                Upon clicking a person it's name is selected as the input value
                And the whole person object is being console logged
                -----------------------------------------------------------------------------------------------
             */}
        <div>
          <Typography variant="h6" sx={{ mb: 2.5 }}>
            Suggestion popup on typing example
          </Typography>
        </div>
        {/* 
                -----------------------------------------------------------------------------------------------
             */}

        {/* 
                -----------------------------------------------------------------------------------------------
                Search component example
                -----------------------------------------------------------------------------------------------
             */}
        <div>
          <Typography variant="h6" sx={{ mb: 2.5 }}>
            Search component
          </Typography>
          <Search
            search={searchTerm}
            setSearch={setSearchTerm}
            nameToFind="Placeholder"
          />
        </div>
        {/* 
                -----------------------------------------------------------------------------------------------
             */}
      </div>
    </PageLayout>
  );
};

export default ComponentsOverview;
