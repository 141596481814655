import React, { useCallback, useEffect, useState } from "react";
import styles from "./Company.module.scss";
import { Typography } from "@mui/material";
import { CompaniesAPI } from "api_inbusiness";
import { EditButton, RemoveButton } from "lib/components/Buttons/buttons";
import ConfirmModal from "lib/components/Modals/ConfirmModal";

import DefaultCompanyPicture from "resources/img/InBusiness/defaultImage.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WebIcon from "@mui/icons-material/Web";
import DescriptionIcon from "@mui/icons-material/Description";
import BadgeIcon from "@mui/icons-material/Badge";
import StarIcon from "@mui/icons-material/Star";
import { toast } from "react-toastify";

const Company = ({
  company = null,
  setOpenAddCompany = () => {},
  setDataForEdit = () => {},
  withButtons = true,
  getUpdatedUser = () => {},
}) => {
  const [companyImage, setCompanyImage] = useState(null);
  const [open, setOpen] = useState(false);

  // const getCompanyImage = useCallback(() => {
  //   CompaniesAPI.getNewLogoCompanies(company.logoId).then((res) => {
  //     if (res.ok) {

  //       setCompanyImage(res.data);
  //     }
  //   });
  // }, [company]);

  React.useEffect(() => {
    (async () => {
      const logoId = company.logoId;

      if (logoId) {
        const { data: imgUrl } = await CompaniesAPI.getNewLogoCompanies(logoId);
        setCompanyImage(imgUrl);
      }
    })();
  }, [company]);

  // useEffect(() => {
  //   if (!company.logo) return;

  //   getCompanyImage();
  // }, [getCompanyImage]);

  const handleDelete = async () => {
    try {
      const res = await CompaniesAPI.delete(company.id);
      if (res.ok) {
        toast.success("Compania a fost ștearsă");
        getUpdatedUser();
      } else {
        toast.error("Compania nu a putut fi ștearsă");
      }
    } catch (error) {
      console.error(error);
      toast.error("Compania nu a putut fi ștearsă");
    } finally {
      setOpen(false);
    }
  };

  const changeFavoriteCompany = () => {
    CompaniesAPI.update(company.id, {
      isFavorite: !company.isFavorite,
      isDefault: !company.isDefault,
    }).then((res) => {
      if (res.ok) {
        toast.success("Succes");
        getUpdatedUser();
      } else {
        toast.error("A intervenit o eroare");
      }
    });
  };

  return (
    <>
      <div className={styles.companyWrapper}>
        <div className={styles.imageBox}>
          <img
            className={styles.imageContent}
            src={companyImage ? companyImage : DefaultCompanyPicture}
            alt="Company"
          />
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoRow}>
            <BadgeIcon />
            <Typography variant="profileLabel">{company.name}</Typography>
          </div>

          {company.phone && (
            <div className={styles.infoRow}>
              <PhoneAndroidIcon />
              <Typography variant="profileLabel">{company.phone}</Typography>
            </div>
          )}

          <div className={styles.infoRow}>
            <WebIcon />
            <a
              href={`https://${company.site}`}
              target="_black"
              className={styles.site}
            >
              {company.site}
            </a>
          </div>

          {company.description && (
            <div className={styles.infoRow}>
              <DescriptionIcon />
              <Typography variant="profileLabel" className={styles.description}>
                {company.description}
              </Typography>
            </div>
          )}
        </div>

        {withButtons && (
          <div className={styles.companyButtons}>
            <div>
              <StarIcon
                sx={{
                  color: company.isFavorite ? "yellowgreen" : "grey",
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={changeFavoriteCompany}
              />
            </div>
            <div className={styles.buttonsWrapper}>
              <EditButton
                color="blue"
                onClick={() => {
                  setDataForEdit({ ...company, companyImage });
                  setOpenAddCompany(true);
                }}
              />
              <RemoveButton color="red" onClick={() => setOpen(true)} />
            </div>
          </div>
        )}
      </div>

      <ConfirmModal
        text={"Sunteți sigur că doriți să ștergeți acesta companie?"}
        buttonYes={"DA"}
        buttonNo={"NU"}
        onClickButtonYes={handleDelete}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default Company;
